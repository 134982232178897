// type declarations
import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorLevel } from '~constants/creatorLevel';

export const SHOW_CREATOR_UPDATE_LEVEL_DIALOG = 'poms/creatorUpdateLevelDialog/SHOW_DIALOG';
export const HIDE_CREATOR_UPDATE_LEVEL_DIALOG = 'poms/creatorUpdateLevelDialog/HIDE_DIALOG';
export const UPDATE_CREATOR_LEVEL = 'poms/creatorUpdateLevelDialog/UPDATE_CREATOR_LEVEL';

export type CreatorUpdateLevelDialogState = {
    open: boolean;
};

// actions
export const showCreatorUpdateLevelDialog = createAction(SHOW_CREATOR_UPDATE_LEVEL_DIALOG);
export const hideCreatorUpdateLevelDialog = createAction(HIDE_CREATOR_UPDATE_LEVEL_DIALOG);
export const updateCreatorLevel = createAction<{creatorId: string; levelId: CreatorLevel}>(UPDATE_CREATOR_LEVEL);

// reducer
const defaultState: CreatorUpdateLevelDialogState = {
    open: false,
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(showCreatorUpdateLevelDialog, (state) => {
            state.open = true;
        })
        .addCase(hideCreatorUpdateLevelDialog, () => defaultState);
});
