import { all } from 'redux-saga/effects';

import watchBrandVideoViewAssignment from './brandVideoViewAssignment';
import watchCalculatePayoutDate from './calculatePayoutDate';
import watchChangePayoutCycleRunStatus from './changePayoutCycleRunStatus';
import watchChangePayoutStatus from './changePayoutStatus';
import watchCompensationOverview from './compensationOverview';
import watchCreatePayoutCorrection from './createPayoutCorrection';
import watchCreateUpdateCompensation from './createUpdateCompensation';
import watchDashboardPayoutSummary from './dashboardPayoutSummary';
import watchDeleteCompensation from './deleteCompensation';
import watchFeatureToggles from './featureToggles';
import watchFetchAdministrativeActions from './fetchAdministrativeActions';
import watchFetchBillableViewsDashboardDetails from './fetchBillableViewsDashboardDetails';
import watchFetchBrandVideos from './fetchBrandVideos';
import watchFetchCollectedBeautyPointsDashboardDetails from './fetchCollectedBeautyPointsDashboardDetails';
import watchFetchCollectedCashDashboardDetails from './fetchCollectedCashDashboardDetails';
import watchFetchCompensableViewsDashboardDetails from './fetchCompensableViewsDashboardDetails';
import watchFetchCreatorBehaviourBeautyPoints from './fetchCreatorBehaviourBeautyPoints';
import watchFetchCreatorChanges from './fetchCreatorChanges';
import watchFetchCreatorCoreInformation from './fetchCreatorCoreDetails';
import watchFetchCreatorDashboardAggregations from './fetchCreatorDashboardAggregations';
import watchFetchCreatorDashboardDetails from './fetchCreatorDashboardDetails';
import watchFetchCreatorEstimatedPayout from './fetchCreatorEstimatedPayout';
import watchFetchCreatorPayoutCorrections from './fetchCreatorPayoutCorrections';
import watchFetchCreatorPayoutOverview from './fetchCreatorPayoutOverview';
import watchFetchCreators from './fetchCreators';
import watchFetchCreatorsForPayoutCycleRun from './fetchCreatorsForPayoutCycleRun';
import watchFetchCreatorsLevelChangeOverview from './fetchCreatorsLevelChangeOverview';
import watchFetchCreatorVideoEvents from './fetchCreatorVideoEvents';
import watchFetchCreatorVideosOverview from './fetchCreatorVideosOverview';
import watchFetchCreditNotesWithProcessingError from './fetchCreditNotesWithProcessingError';
import watchFetchDashboardAggregations from './fetchDashboardAggregations';
import watchFetchEstimatedPayoutCycleRuns from './fetchEstimatedPayoutCycleRuns';
import watchFetchPaidOutBeautyPointsDashboardDetails from './fetchPaidOutBeautyPointsDashboardDetails';
import watchFetchPaidOutCashDashboardDetails from './fetchPaidOutCashDashboardDetails';
import watchFetchPayoutCycleRunDetails from './fetchPayoutCycleRunDetails';
import watchFetchPayoutCycleRuns from './fetchPayoutCycleRuns';
import watchFetchPayoutCycleRunsProcessStepsCalculated from './fetchPayoutCycleRunsProcessStepsCalculated';
import watchFetchPayoutCycleRunsProcessStepsMarkedForCalculation from './fetchPayoutCycleRunsProcessStepsMarkedForCalculation';
import watchFetchPayoutCycleRunsProcessStepsPayoutsCreated from './fetchPayoutCycleRunsProcessStepsPayoutsCreated';
import watchFetchPayoutCycleRunsProcessStepsPublished from './fetchPayoutCycleRunsProcessStepsPublished';
import watchFetchPayoutCycleRunsProcessStepsRecalculatable from './fetchPayoutCycleRunsProcessStepsRecalculatable';
import watchFetchPayoutCycles from './fetchPayoutCycles';
import watchFetchScepCreatorEvents from './fetchScepCreatorEvents';
import watchFetchVideosDashboardDetails from './fetchVideosDashboardDetails';
import watchGeneratePayoutRunForPayoutCycle from './generatePayoutRunForPayoutCycle';
import watchGeneratePayoutsForPayoutCycleRun from './generatePayoutsForPayoutCycleRun';
import watchHideBrandVideo from './hideBrandVideo';
import watchInitializeTestData from './initializeTestData';
import watchLogin from './login';
import watchSubmitVideoEvent from './prefillVideoEvent';
import watchPublishCalculatedPayoutCycleRun from './publishCalculatedPayoutCycleRun';
import watchRecalculateCreatorLevels from './recalculateCreatorLevels';
import watchRecalculateDashboardKPI from './recalculateDashboardKPI';
import watchRecalculatePayoutCycleRun from './recalculatePayoutCycleRun';
import watchRemoveAllTestCreatorsAndVideos from './removeAllTestCreatorsAndVideos';
import watchReversePublishedPayoutCycleRun from './reversePublishedPayoutCycleRun';
import watchSendTestMails from './sendTestMails';
import watchShowBrandVideo from './showBrandVideo';
import watchShowCreditNoteDocumentDraft from './showCreditNoteDocumentDraft';
import watchStartTestPayout from './startTestPayout';
import watchUpdateCreatorLevel from './updateCreatorLevel';

export default function * rootSaga (): Generator<any, any, unknown> {
    yield all([
        watchBrandVideoViewAssignment(),
        watchCalculatePayoutDate(),
        watchChangePayoutCycleRunStatus(),
        watchChangePayoutStatus(),
        watchCompensationOverview(),
        watchCreateUpdateCompensation(),
        watchDashboardPayoutSummary(),
        watchDeleteCompensation(),
        watchFeatureToggles(),
        watchFetchAdministrativeActions(),
        watchFetchBillableViewsDashboardDetails(),
        watchFetchBrandVideos(),
        watchFetchCollectedBeautyPointsDashboardDetails(),
        watchFetchCollectedCashDashboardDetails(),
        watchFetchCompensableViewsDashboardDetails(),
        watchCreatePayoutCorrection(),
        watchFetchCreatorBehaviourBeautyPoints(),
        watchFetchCreatorChanges(),
        watchFetchCreatorCoreInformation(),
        watchFetchCreatorDashboardAggregations(),
        watchFetchCreatorDashboardDetails(),
        watchFetchCreatorEstimatedPayout(),
        watchFetchCreatorPayoutCorrections(),
        watchFetchCreatorPayoutOverview(),
        watchFetchCreators(),
        watchFetchCreatorsForPayoutCycleRun(),
        watchFetchCreatorsLevelChangeOverview(),
        watchFetchCreatorVideoEvents(),
        watchFetchCreatorVideosOverview(),
        watchFetchCreditNotesWithProcessingError(),
        watchFetchDashboardAggregations(),
        watchFetchEstimatedPayoutCycleRuns(),
        watchFetchPaidOutBeautyPointsDashboardDetails(),
        watchFetchPaidOutCashDashboardDetails(),
        watchFetchPayoutCycleRunDetails(),
        watchFetchPayoutCycleRuns(),
        watchFetchPayoutCycleRunsProcessStepsCalculated(),
        watchFetchPayoutCycleRunsProcessStepsMarkedForCalculation(),
        watchFetchPayoutCycleRunsProcessStepsPayoutsCreated(),
        watchFetchPayoutCycleRunsProcessStepsPublished(),
        watchFetchPayoutCycleRunsProcessStepsRecalculatable(),
        watchFetchPayoutCycles(),
        watchFetchScepCreatorEvents(),
        watchFetchVideosDashboardDetails(),
        watchGeneratePayoutRunForPayoutCycle(),
        watchGeneratePayoutsForPayoutCycleRun(),
        watchHideBrandVideo(),
        watchInitializeTestData(),
        watchLogin(),
        watchSubmitVideoEvent(),
        watchPublishCalculatedPayoutCycleRun(),
        watchRecalculateCreatorLevels(),
        watchRecalculateDashboardKPI(),
        watchRecalculatePayoutCycleRun(),
        watchRemoveAllTestCreatorsAndVideos(),
        watchReversePublishedPayoutCycleRun(),
        watchSendTestMails(),
        watchShowBrandVideo(),
        watchShowCreditNoteDocumentDraft(),
        watchStartTestPayout(),
        watchUpdateCreatorLevel(),
    ]);
}
