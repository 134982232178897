import { useSelector } from 'react-redux';

import { CreatorsState } from '~modules/creators/creators';
import { AppState } from '~modules/index';

const root = (state: AppState): CreatorsState => state.creators;

export const useCreators = (): CreatorsState => useSelector(root);

export default {
    root,
};
