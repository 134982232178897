import getConfig from 'next/config';
import Router from 'next/router';

import { Routes } from '~constants/routes';

export function getURLSearchParameter (parameterName: string): string | null {
    const parameters = new URLSearchParams(window.location.search);

    return parameters.get(parameterName);
}

export const getHostNameUrl = (): string => `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

export const navigateToHome = (): Promise<boolean> =>
    Router.push(Routes.ROOT);

export function getBusinessDocLink (): string {
    const { publicRuntimeConfig } = getConfig();
    const environment = publicRuntimeConfig.environment.toLowerCase();

    switch (environment) {
        case 'stg':
        case 'prd':
            return `https://poms.${environment === 'prd' ? 'p1' : 's1'}.services.svc.dglecom.net/docs/business.html`;
    }

    return '/docs/business.html';
}
