import { createSelector } from 'reselect';

import { CreatorFilterState } from '~modules/creatorFilter/creatorFilter';
import { AppState } from '~modules/index';

const root = (state: AppState): CreatorFilterState => state.creatorFilter;
const filter = createSelector(root, (root) => root.filter);
const sorting = createSelector(root, (root) => root.sort);
const page = createSelector(root, (root) => root.page);
const resultsPerPage = createSelector(root, (root) => root.resultsPerPage);

export default {
    root,
    filter,
    sorting,
    page,
    resultsPerPage,
};
