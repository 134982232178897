import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';
import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';

import { HttpStatus } from '~constants/http';

import { selectors as oidcSelectors } from '~modules/oidc';
import {
    FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_RECALCULATABLE,
    setPayoutCycleRunsProcessStepsRecalculatable,
    setPayoutCycleRunsProcessStepsRecalculatableError,
    setPayoutCycleRunsProcessStepsRecalculatableForbidden
} from '~modules/payoutCycleRunsProcessStepsRecalculatable';

export function * fetchPayoutCycleRunsProcessStepsRecalculatable (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<PayoutCycleRunProcessSteps> = yield call(api.fetchPayoutCycleRunsRecalculatable, accessToken);

        yield put(setPayoutCycleRunsProcessStepsRecalculatable(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPayoutCycleRunsProcessStepsRecalculatableForbidden());
        } else {
            yield put(setPayoutCycleRunsProcessStepsRecalculatableError());
        }
    }
}

export default function * watchFetchPayoutCycleRunsProcessStepsRecalculatable (): Generator<ForkEffect> {
    yield takeLatest(FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_RECALCULATABLE, fetchPayoutCycleRunsProcessStepsRecalculatable);
}
