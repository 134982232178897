import React from 'react';
import clsx from 'clsx';
import Head from 'next/head';

import { getFormattedEnvironment, message } from '~helper/_common';

import { Headline } from '~components/atoms/Headline';

import style from './FullscreenNotification.module.scss';

export type FullscreenNotificationProps = {
    children?: React.ReactNode;
    subMessage?: string;
    icon?: React.ReactElement;
}

export const FullscreenNotification: React.FC<FullscreenNotificationProps> = ({ icon, subMessage, children, ...otherProps }) => {
    return <div className={ clsx(style.root, 'dgl__fullscreen-notification') } data-test="notification" { ...otherProps }>
        <Head>
            <title data-test="notification.head.title">{ message('title', getFormattedEnvironment()) }</title>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        </Head>
        <div className={ style.root__wrapper }>
            <div className={ style.root__centered }>
                { icon }
                <div className={ style.root__centered__text } data-test="notification.content">
                    { children &&
                    (
                        (typeof children === 'string')
                            ? <Headline variant="h4">{ children }</Headline>
                            : children
                    )
                    }
                    {
                        subMessage &&
                        <div data-test="notification.subMessage" className={ style.root__centered__subMessage }>{ subMessage }</div>
                    }
                </div>
            </div>
        </div>
    </div>;
};
