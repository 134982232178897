import { createSelector } from 'reselect';

import { AppState } from '~modules/index';
import { PayoutCycleRunDetailsState } from '~modules/payoutCycleRunDetails/payoutCycleRunDetails';

const root = (state: AppState): PayoutCycleRunDetailsState => state.payoutCycleRunDetails;
const data = createSelector(root, (root) => root.data);

export default {
    root,
    data,
};
