import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/credit-notes';
import { CreditNote } from '~api/model/creditNote';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREDIT_NOTES_WITH_PROCESSING_ERROR,
    setCreditNotesWithProcessingError,
    setCreditNotesWithProcessingErrorError,
    setCreditNotesWithProcessingErrorForbidden
} from '~modules/creditNotesWithProcessingError';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreditNotesWithProcessingError (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<CreditNote[]> = yield call(api.fetchCreditNotesWithProcessingError, accessToken);

        yield put(setCreditNotesWithProcessingError(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreditNotesWithProcessingErrorForbidden());
        } else {
            yield put(setCreditNotesWithProcessingErrorError());
        }
    }
}

export default function * watchFetchCreditNotesWithProcessingError (): Generator<ForkEffect> {
    yield takeLatest(FETCH_CREDIT_NOTES_WITH_PROCESSING_ERROR, fetchCreditNotesWithProcessingError);
}
