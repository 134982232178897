import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import api from '~api/creators';
import { CreatorCoreInformation } from '~api/model/creator';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREATOR_CORE_INFORMATION,
    FetchCreatorCoreInformationAction, setCreatorCoreInformation,
    setCreatorCoreInformationError,
    setCreatorCoreInformationForbidden, setCreatorCoreInformationNotFound,
} from '~modules/creatorCoreInformation';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorCoreInformation (action: FetchCreatorCoreInformationAction): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const creatorId = action.payload;

    try {
        const { data }: AxiosResponse<CreatorCoreInformation> = yield call(api.fetchCreatorCoreInformation, creatorId, accessToken);

        yield put(setCreatorCoreInformation(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorCoreInformationForbidden());
        } else if (error.response?.status === HttpStatus.NotFound) {
            yield put(setCreatorCoreInformationNotFound());
        } else {
            yield put(setCreatorCoreInformationError());
        }
    }
}

export default function * watchFetchCreatorCoreInformation (): Generator<ForkEffect> {
    yield takeEvery(FETCH_CREATOR_CORE_INFORMATION, fetchCreatorCoreInformation);
}
