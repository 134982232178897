import { useSelector } from 'react-redux';

import { administrativeActionsState } from '~modules/administrativeActions/administrativeActions';
import { AppState } from '~modules/index';

const root = (state: AppState): administrativeActionsState => state.administrativeActionsState;

export const useAdministrativeActions = (): administrativeActionsState => useSelector(root);

export default {
    root,
};
