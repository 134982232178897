import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import api from '~api/creators';
import { CreatorPayoutCycleRun } from '~api/model/payout-cycle';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREATOR_ESTIMATED_PAYOUT,
    FetchCreatorEstimatedPayoutAction,
    setCreatorEstimatedPayout,
    setCreatorEstimatedPayoutError,
    setCreatorEstimatedPayoutForbidden,
} from '~modules/creatorEstimatedPayout';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorEstimatedPayout (action: FetchCreatorEstimatedPayoutAction): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const creatorId = action.payload;

    try {
        const { data }: AxiosResponse<CreatorPayoutCycleRun> = yield call(api.fetchEstimatedCreatorPayout, creatorId, accessToken);

        yield put(setCreatorEstimatedPayout(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorEstimatedPayoutForbidden());
        } else {
            yield put(setCreatorEstimatedPayoutError());
        }
    }
}

export default function * watchFetchCreatorEstimatedPayout (): Generator<ForkEffect> {
    yield takeEvery(FETCH_CREATOR_ESTIMATED_PAYOUT, fetchCreatorEstimatedPayout);
}
