import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { DashboardAggregationsState } from '~modules/dashboardAggregations/dashboardAggregations';
import { AppState } from '~modules/index';

const root = (state: AppState): DashboardAggregationsState => state.dashboardAggregations;
const data = createSelector(root, (root) => root.data);

export const useDashboardAggregations = ():DashboardAggregationsState => useSelector(root);

export default {
    root,
    data,
};
