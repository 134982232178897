import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/dashboard-aggregations';
import { ViewsDashboardTileDetails } from '~api/model/dashboardAggregations';

import { HttpStatus } from '~constants/http';

import { getRangeFromFilter } from '~helper/range-filter';

import {
    FETCH_COMPENSABLE_VIEWS_DASHBOARD_DETAILS,
    FetchCompensableViewsDashboardDetailsAction,
    setCompensableViewsDashboardDetails,
    setCompensableViewsDashboardDetailsError,
    setCompensableViewsDashboardDetailsForbidden,
} from '~modules/compensableViewsDashboardDetails';
import {
    selectors as dashboardDetailTileDateFilterSelectors,
    SET_DASHBOARD_DETAIL_TILE_DATE_FILTER,
    SetDashboardDetailTileDateFilterAction,
} from '~modules/dashboardDetailTileDateFilter';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCompensableViewsDashboardDetails (action: FetchCompensableViewsDashboardDetailsAction | SetDashboardDetailTileDateFilterAction): Generator<StrictEffect, void, any> {
    const { dataType, creatorId } = action.meta;

    if (action.type === SET_DASHBOARD_DETAIL_TILE_DATE_FILTER && dataType !== 'compensableViews') {
        return;
    }

    const accessToken = yield select(oidcSelectors.accessToken);
    const rangeFilter = yield select(dashboardDetailTileDateFilterSelectors.root);
    const range = getRangeFromFilter(rangeFilter);

    try {
        const { data }: AxiosResponse<ViewsDashboardTileDetails> = creatorId
            ? yield call(api.fetchCompensableViewsCreatorDashboardDetails, creatorId, range.from, range.to, accessToken)
            : yield call(api.fetchCompensableViewsDashboardDetails, range.from, range.to, accessToken);

        yield put(setCompensableViewsDashboardDetails(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCompensableViewsDashboardDetailsForbidden());
        } else {
            yield put(setCompensableViewsDashboardDetailsError());
        }
    }
}

export default function * watchFetchCompensableViewsDashboardDetails (): Generator<ForkEffect> {
    yield takeLatest(FETCH_COMPENSABLE_VIEWS_DASHBOARD_DETAILS, fetchCompensableViewsDashboardDetails);
    yield takeLatest(SET_DASHBOARD_DETAIL_TILE_DATE_FILTER, fetchCompensableViewsDashboardDetails);
}
