import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';
import { PayoutCycleRunsProcessStepPayoutsCreatedState } from '~modules/payoutCycleRunsProcessStepsPayoutsCreated/payoutCycleRunsProcessStepsPayoutsCreated';

const root = (state: AppState): PayoutCycleRunsProcessStepPayoutsCreatedState => state.payoutCycleRunsProcessStepsPayoutsCreated;

export const usePayoutCycleRunsProcessStepsPayoutsCreated = (): PayoutCycleRunsProcessStepPayoutsCreatedState => useSelector(root);

export default {
    root,
};
