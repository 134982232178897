import axios, { AxiosPromise } from 'axios';

import { addAuthorizationHeader, endpoint } from '~api/_helper';
import { ScepCreatorEvents } from '~api/model/administration';
import { AdministrativeActions } from '~api/model/administrativeActions';
import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';
import { VideoEvent } from '~api/model/videoEvent';

import { Endpoints } from '~constants/endpoints';
import { NetworkTimeouts } from '~constants/networkTimeouts';

const calculatePayoutDates = (accessToken: string): AxiosPromise<string> => {
    return axios.post(endpoint(Endpoints.CALCULATE_PAYOUT_DATE), {}, addAuthorizationHeader(accessToken));
};

const initTestData = (accessToken: string): AxiosPromise<string> => {
    return axios.post(endpoint(Endpoints.INIT_TEST_DATA), {}, addAuthorizationHeader(accessToken));
};

const generatePayoutsForPayoutCycleRun = (cycleId: string, accessToken: string): AxiosPromise<string> => {
    return axios.post(
        endpoint(Endpoints.GENERATE_PAYOUTS_FOR_PAYOUT_CYCLE_RUN, cycleId),
        {},
        addAuthorizationHeader(accessToken),
    );
};

const generatePayoutRunForPayoutCycle = (cycleId: string, accessToken: string): AxiosPromise<string> => {
    return axios.post(
        endpoint(Endpoints.GENERATE_PAYOUT_RUN_FOR_PAYOUT_CYCLE, cycleId),
        {},
        addAuthorizationHeader(accessToken),
    );
};

const recalculateCreatorLevels = (accessToken: string): AxiosPromise<string> => {
    return axios.post(endpoint(Endpoints.RECALCULATE_CREATOR_LEVELS), {}, addAuthorizationHeader(accessToken));
};

const recalculateDashboardKPI = (date: Date, accessToken: string): AxiosPromise<string> => {
    return axios.post(
        endpoint(Endpoints.RECALCULATE_DASHBOARD_KPI),
        { date },
        addAuthorizationHeader(accessToken, { timeout: NetworkTimeouts.LONG_RUNNING }),
    );
};

const startTestPayout = (accessToken: string): AxiosPromise<string> => {
    return axios.post(endpoint(Endpoints.START_TEST_PAYOUT), {}, addAuthorizationHeader(accessToken));
};

const publishCalculatedPayoutCycleRun = (cycleId: string, accessToken: string): AxiosPromise<string> => {
    return axios.post(
        endpoint(Endpoints.PUBLISH_CALCULATED_PAYOUT_CYCLE_RUN, cycleId),
        {},
        addAuthorizationHeader(accessToken),
    );
};

const reversePublishedPayoutCycleRun = (cycleId: string, accessToken: string): AxiosPromise<string> => {
    return axios.post(
        endpoint(Endpoints.REVERSE_PUBLISHED_PAYOUT_CYCLE_RUN, cycleId),
        {},
        addAuthorizationHeader(accessToken),
    );
};

const recalculatePayoutCycleRun = (cycleId: string, accessToken: string): AxiosPromise<string> => {
    return axios.post(
        endpoint(Endpoints.RECALCULATE_PAYOUT_CYCLE_RUN, cycleId),
        {},
        addAuthorizationHeader(accessToken),
    );
};

const sendTestMails = (accessToken: string): AxiosPromise<string> => {
    return axios.post(endpoint(Endpoints.SEND_TEST_MAILS), {}, addAuthorizationHeader(accessToken));
};

const fetchAdministrativeActions = (accessToken: string): AxiosPromise<AdministrativeActions> => {
    return axios.get(endpoint(Endpoints.ADMINISTRATIVE_ACTIONS), addAuthorizationHeader(accessToken));
};
const fetchScepEvents = (accessToken: string, cdcId?: string, date?: string): AxiosPromise<ScepCreatorEvents> => {
    const searchParams = new URLSearchParams();

    if (cdcId) {
        searchParams.append('cdcid', cdcId);
    }
    if (date) {
        searchParams.append('date', date);
    }

    return axios.get(
        `${endpoint(Endpoints.SCEP_LOOKUP)}?${searchParams.toString()}`,
        addAuthorizationHeader(accessToken),
    );
};
const fetchPayoutCycleRunsCalculated = (accessToken: string): AxiosPromise<PayoutCycleRunProcessSteps> => {
    return axios.get(Endpoints.PAYOUT_CYCLE_RUNS_CALCULATED, addAuthorizationHeader(accessToken));
};

const fetchPayoutCycleRunsMarkedForCalculation = (accessToken: string): AxiosPromise<PayoutCycleRunProcessSteps> => {
    return axios.get(Endpoints.PAYOUT_CYCLE_RUNS_MARKED_FOR_CALCULATION, addAuthorizationHeader(accessToken));
};

const fetchPayoutCycleRunsPayoutsCreated = (accessToken: string): AxiosPromise<PayoutCycleRunProcessSteps> => {
    return axios.get(Endpoints.PAYOUT_CYCLE_RUNS_PAYOUTS_CREATED, addAuthorizationHeader(accessToken));
};

const fetchPayoutCycleRunsPublished = (accessToken: string): AxiosPromise<PayoutCycleRunProcessSteps> => {
    return axios.get(Endpoints.PAYOUT_CYCLE_RUNS_PUBLISHED, addAuthorizationHeader(accessToken));
};

const fetchPayoutCycleRunsRecalculatable = (accessToken: string): AxiosPromise<PayoutCycleRunProcessSteps> => {
    return axios.get(Endpoints.PAYOUT_CYCLE_RUNS_RECALCULATABLE, addAuthorizationHeader(accessToken));
};

const fetchCreatorVideoEvents = (creatorId: string, accessToken: string): AxiosPromise<VideoEvent[]> => {
    return axios.get(endpoint(Endpoints.CREATOR_VIDEO_EVENTS, creatorId), addAuthorizationHeader(accessToken));
};

const prefillVideoEvent = (videoEvent: VideoEvent, accessToken: string): AxiosPromise<string> => {
    return axios.post(endpoint(Endpoints.TEST_VIDEO_EVENTS), videoEvent, addAuthorizationHeader(accessToken));
};

const removeAllTestCreatorsAndVideos = (accessToken: string): AxiosPromise<string> => {
    return axios.delete(Endpoints.TEST_VIDEO_EVENTS, addAuthorizationHeader(accessToken));
};

export default {
    fetchAdministrativeActions,
    fetchScepEvents,
    calculatePayoutDate: calculatePayoutDates,
    initTestData,
    generatePayoutsForPayoutCycleRun,
    generatePayoutRunForPayoutCycle,
    recalculateCreatorLevels,
    recalculateDashboardKPI,
    sendTestMails,
    startTestPayout,
    recalculatePayoutCycleRun,
    publishCalculatedPayoutCycleRun,
    reversePublishedPayoutCycleRun,
    fetchPayoutCycleRunsCalculated,
    fetchPayoutCycleRunsPayoutsCreated,
    fetchPayoutCycleRunsMarkedForCalculation,
    fetchPayoutCycleRunsPublished,
    fetchPayoutCycleRunsRecalculatable,
    fetchCreatorVideoEvents,
    prefillVideoEvent,
    removeAllTestCreatorsAndVideos,
};
