import { combineReducers } from 'redux';

import creatorUpdateLevelDialog from '~modules/creatorUpdateLevelDialog/creatorUpdateLevelDialog';
import creditNotesWithProcessingError from '~modules/creditNotesWithProcessingError';

import activeButton from './activeButton';
import administrativeActionsState from './administrativeActions';
import billableViewsDashboardDetails from './billableViewsDashboardDetails';
import brandVideoCreatorSelectionDialog from './brandVideoCreatorSelectionDialog';
import brandVideos from './brandVideos';
import brandVideosFilter from './brandVideosFilter';
import brandVideoViewAssignmentDialog from './brandVideoViewAssignmentDialog';
import collectedBeautyPointsDashboardDetails from './collectedBeautyPointsDashboardDetails';
import collectedCashDashboardDetails from './collectedCashDashboardDetails';
import compensableViewsDashboardDetails from './compensableViewsDashboardDetails';
import compensationOverview from './compensationOverview';
import createPayoutCorrectionDialog from './createPayoutCorrectionDialog';
import creatorBehaviourBeautyPoints from './creatorBehaviourBeautyPoints';
import creatorChanges from './creatorChanges';
import creatorCoreInformation from './creatorCoreInformation';
import creatorDashboardAggregations from './creatorDashboardAggregations';
import creatorDashboardDetails from './creatorDashboardDetails';
import creatorEstimatedPayout from './creatorEstimatedPayout';
import creatorFilter from './creatorFilter';
import creatorPayoutCorrections from './creatorPayoutCorrections';
import creatorPayoutOverview from './creatorPayoutOverview';
import creators from './creators';
import creatorsLevelChangeOverview from './creatorsLevelChangeOverview';
import creatorVideoEvents from './creatorVideoEvents';
import creatorVideosOverview from './creatorVideosOverview';
import dashboardAggregations from './dashboardAggregations';
import dashboardDetailTileDateFilter from './dashboardDetailTileDateFilter';
import dashboardPayoutSummary from './dashboardPayoutSummary';
import deleteCompensationDialog from './deleteCompensationDialog';
import editCompensationDialog from './editCompensationDialog';
import estimatedPayoutCycleRuns from './estimatedPayoutCycleRuns';
import featureToggles from './featureToggles';
import messages from './messages';
import oidc from './oidc';
import paidOutBeautyPointsDashboardDetails from './paidOutBeautyPointsDashboardDetails';
import paidOutCashDashboardDetails from './paidOutCashDashboardDetails';
import payoutCycleRunCreators from './payoutCycleRunCreators';
import payoutCycleRunDetails from './payoutCycleRunDetails';
import payoutCycleRuns from './payoutCycleRuns';
import payoutCycleRunsProcessStepsCalculated from './payoutCycleRunsProcessStepsCalculated';
import payoutCycleRunsProcessStepsMarkedForCalculation from './payoutCycleRunsProcessStepsMarkedForCalculation';
import payoutCycleRunsProcessStepsPayoutsCreated from './payoutCycleRunsProcessStepsPayoutsCreated';
import payoutCycleRunsProcessStepsPublished from './payoutCycleRunsProcessStepsPublished';
import payoutCycleRunsProcessStepsRecalculatable from './payoutCycleRunsProcessStepsRecalculatable';
import payoutCycles from './payoutCycles';
import payoutStatusChangeConfirmDialog from './payoutStatusChangeConfirmDialog';
import scepCreatorEvents from './scepCreatorEvents';
import setPayoutCycleRunOnHoldOrResumeDialog from './setPayoutCycleRunOnHoldOrResumeDialog';
import videosDashboardDetails from './videosDashboardDetails';

const rootReducer = combineReducers({
    activeButton,
    administrativeActionsState,
    billableViewsDashboardDetails,
    brandVideoCreatorSelectionDialog,
    brandVideos,
    brandVideosFilter,
    brandVideoViewAssignmentDialog,
    createPayoutCorrectionDialog,
    creatorUpdateLevelDialog,
    collectedBeautyPointsDashboardDetails,
    collectedCashDashboardDetails,
    compensableViewsDashboardDetails,
    compensationOverview,
    creatorBehaviourBeautyPoints,
    creatorChanges,
    creatorCoreInformation,
    creatorsLevelChangeOverview,
    creatorPayoutOverview,
    creatorDashboardAggregations,
    creatorVideosOverview,
    creatorDashboardDetails,
    creatorEstimatedPayout,
    creatorFilter,
    creatorPayoutCorrections,
    creators,
    creatorVideoEvents,
    creditNotesWithProcessingError,
    dashboardAggregations,
    dashboardDetailTileDateFilter,
    dashboardPayoutSummary,
    deleteCompensationDialog,
    editCompensationDialog,
    estimatedPayoutCycleRuns,
    featureToggles,
    messages,
    oidc,
    paidOutBeautyPointsDashboardDetails,
    paidOutCashDashboardDetails,
    payoutCycleRunCreators,
    payoutCycleRunDetails,
    payoutCycleRuns,
    payoutCycleRunsProcessStepsCalculated,
    payoutCycleRunsProcessStepsMarkedForCalculation,
    payoutCycleRunsProcessStepsPublished,
    payoutCycleRunsProcessStepsPayoutsCreated,
    payoutCycleRunsProcessStepsRecalculatable,
    payoutCycles,
    payoutStatusChangeConfirmDialog,
    scepCreatorEvents,
    setPayoutCycleRunOnHoldOrResumeDialog,
    videosDashboardDetails,
});

export default rootReducer;

export type AppState = ReturnType<typeof rootReducer>;
