import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/compensation';
import { CompensationOverview } from '~api/model/compensation';

import { HttpStatus } from '~constants/http';

import {
    FETCH_COMPENSATION_OVERVIEW,
    setCompensationOverview,
    setCompensationOverviewError,
    setCompensationOverviewForbidden,
} from '~modules/compensationOverview';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCompensationOverview (): Generator<StrictEffect, void, any> {
    try {
        const accessToken = yield select(oidcSelectors.accessToken);

        const { data }: AxiosResponse<CompensationOverview> = yield call(api.fetchCompensation, accessToken);

        yield put(setCompensationOverview(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCompensationOverviewForbidden());
        } else {
            yield put(setCompensationOverviewError());
        }
    }
}

export default function * watchCompensationOverview (): Generator<ForkEffect> {
    yield takeLatest(FETCH_COMPENSATION_OVERVIEW, fetchCompensationOverview);
}
