import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { AppState } from '~modules/index';

import { SetPayoutCycleRunOnHoldDialogState } from './setPayoutCycleRunOnHoldOrResumeDialog';

const root = (state: AppState): SetPayoutCycleRunOnHoldDialogState => state.setPayoutCycleRunOnHoldOrResumeDialog;
const payoutCycleRunToSetOnHoldOrResume = createSelector(root, state => state.payoutCycleRunToSetOnHoldOrResume);

export const useSetPayoutCycleRunOnHoldOrResumeDialog = (): SetPayoutCycleRunOnHoldDialogState => useSelector(root);

export default {
    root,
    payoutCycleRunToSetOnHoldOrResume
};
