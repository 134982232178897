import axios, { AxiosPromise } from 'axios';
import getConfig from 'next/config';

import { Endpoints } from '~constants/endpoints';

import { FeatureToggleResponse } from './model/unleash';

const fetchFeatureToggles = (): AxiosPromise<FeatureToggleResponse> => {
    const { unleashToken } = getConfig().publicRuntimeConfig;

    return axios.get(Endpoints.FEATURE_TOGGLES, {
        headers: {
            Accept: 'application/json',
            Authorization: unleashToken,
        },
    });
};

export default {
    fetchFeatureToggles,
};
