import axios, { AxiosPromise } from 'axios';

import { addAuthorizationHeader, endpoint } from '~api/_helper';
import {
    BeautyPointsDashboardTileDetails,
    CashDashboardTileDetails,
    CreatorDashboardAggregations,
    CreatorsDashboardTileDetails,
    DashboardAggregations,
    DashboardPayoutSummary,
    VideosDashboardTileDetails,
    ViewsDashboardTileDetails,
} from '~api/model/dashboardAggregations';

import { Endpoints } from '~constants/endpoints';

const fetchDashboardAggregations = (accessToken: string): AxiosPromise<DashboardAggregations> => {
    return axios.get(Endpoints.DASHBOARD_AGGREGATIONS, addAuthorizationHeader(accessToken));
};

const fetchCreatorsDashboardDetails = (from: string, to: string, accessToken: string): AxiosPromise<CreatorsDashboardTileDetails> => {
    return axios.get(Endpoints.CREATORS_DASHBOARD_DETAILS, addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchCollectedBeautyPointsDashboardDetails = (from: string, to: string, accessToken: string): AxiosPromise<BeautyPointsDashboardTileDetails> => {
    return axios.get(Endpoints.COLLECTED_BEAUTY_POINTS_DASHBOARD_DETAILS, addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchCollectedCashDashboardDetails = (from: string, to: string, accessToken: string): AxiosPromise<CashDashboardTileDetails> => {
    return axios.get(Endpoints.COLLECTED_CASH_DASHBOARD_DETAILS, addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchPaidOutBeautyPointsDashboardDetails = (from: string, to: string, accessToken: string): AxiosPromise<BeautyPointsDashboardTileDetails> => {
    return axios.get(Endpoints.PAID_OUT_BEAUTY_POINTS_DASHBOARD_DETAILS, addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchPaidOutCashDashboardDetails = (from: string, to: string, accessToken: string): AxiosPromise<CashDashboardTileDetails> => {
    return axios.get(Endpoints.PAID_OUT_CASH_DASHBOARD_DETAILS, addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchBillableViewsDashboardDetails = (from: string, to: string, accessToken: string): AxiosPromise<ViewsDashboardTileDetails> => {
    return axios.get(Endpoints.BILLABLE_VIEWS_DASHBOARD_DETAILS, addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchCompensableViewsDashboardDetails = (from: string, to: string, accessToken: string): AxiosPromise<ViewsDashboardTileDetails> => {
    return axios.get(Endpoints.COMPENSABLE_VIEWS_DASHBOARD_DETAILS, addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchVideosDashboardDetails = (from: string, to: string, accessToken: string): AxiosPromise<VideosDashboardTileDetails> => {
    return axios.get(Endpoints.VIDEOS_DASHBOARD_DETAILS, addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchCreatorDashboardAggregations = (creatorId: string, accessToken: string): AxiosPromise<CreatorDashboardAggregations> => {
    return axios.get(endpoint(Endpoints.CREATOR_DASHBOARD_AGGREGATIONS, creatorId), addAuthorizationHeader(accessToken));
};

const fetchVideosCreatorDashboardDetails = (creatorId: string, from: string, to: string, accessToken: string): AxiosPromise<VideosDashboardTileDetails> => {
    return axios.get(endpoint(Endpoints.VIDEOS_CREATOR_DASHBOARD_DETAILS, creatorId), addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchBillableViewsCreatorDashboardDetails = (creatorId: string, from: string, to: string, accessToken: string): AxiosPromise<ViewsDashboardTileDetails> => {
    return axios.get(endpoint(Endpoints.BILLABLE_VIEWS_CREATOR_DASHBOARD_DETAILS, creatorId), addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchCompensableViewsCreatorDashboardDetails = (creatorId: string, from: string, to: string, accessToken: string): AxiosPromise<ViewsDashboardTileDetails> => {
    return axios.get(endpoint(Endpoints.COMPENSABLE_VIEWS_CREATOR_DASHBOARD_DETAILS, creatorId), addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchCollectedBeautyPointsCreatorDashboardDetails = (creatorId: string, from: string, to: string, accessToken: string): AxiosPromise<BeautyPointsDashboardTileDetails> => {
    return axios.get(endpoint(Endpoints.COLLECTED_BEAUTY_POINTS_CREATOR_DASHBOARD_DETAILS, creatorId), addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchCollectedCashCreatorDashboardDetails = (creatorId: string, from: string, to: string, accessToken: string): AxiosPromise<CashDashboardTileDetails> => {
    return axios.get(endpoint(Endpoints.COLLECTED_CASH_CREATOR_DASHBOARD_DETAILS, creatorId), addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchPaidOutBeautyPointsCreatorDashboardDetails = (creatorId: string, from: string, to: string, accessToken: string): AxiosPromise<BeautyPointsDashboardTileDetails> => {
    return axios.get(endpoint(Endpoints.PAID_OUT_BEAUTY_POINTS_CREATOR_DASHBOARD_DETAILS, creatorId), addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchPaidOutCashCreatorDashboardDetails = (creatorId: string, from: string, to: string, accessToken: string): AxiosPromise<CashDashboardTileDetails> => {
    return axios.get(endpoint(Endpoints.PAID_OUT_CASH_CREATOR_DASHBOARD_DETAILS, creatorId), addAuthorizationHeader(accessToken, { params: { from, to } }));
};

const fetchPayoutSummary = (accessToken: string): AxiosPromise<DashboardPayoutSummary> => {
    return axios.get(endpoint(Endpoints.DASHBOARD_PAYOUT_SUMMARY), addAuthorizationHeader(accessToken));
};

export default {
    fetchCreatorsDashboardDetails,
    fetchVideosDashboardDetails,
    fetchBillableViewsDashboardDetails,
    fetchCompensableViewsDashboardDetails,
    fetchCollectedBeautyPointsDashboardDetails,
    fetchCollectedCashDashboardDetails,
    fetchPaidOutBeautyPointsDashboardDetails,
    fetchPaidOutCashDashboardDetails,
    fetchDashboardAggregations,
    fetchCreatorDashboardAggregations,
    fetchVideosCreatorDashboardDetails,
    fetchBillableViewsCreatorDashboardDetails,
    fetchCompensableViewsCreatorDashboardDetails,
    fetchCollectedBeautyPointsCreatorDashboardDetails,
    fetchCollectedCashCreatorDashboardDetails,
    fetchPaidOutBeautyPointsCreatorDashboardDetails,
    fetchPaidOutCashCreatorDashboardDetails,
    fetchPayoutSummary,
};
