import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CreatorDashboardDetailsState } from './creatorDashboardDetails';

const root = (state: AppState): CreatorDashboardDetailsState => state.creatorDashboardDetails;

export const useCreatorDashboardDetails = (): CreatorDashboardDetailsState => useSelector(root);

export default {
    root,
};
