import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CreatorBehaviourBeautyPointsState } from './creatorBehaviourBeautyPoints';

const root = (state: AppState): CreatorBehaviourBeautyPointsState => state.creatorBehaviourBeautyPoints;

export const useCreatorBehaviourBeautyPoints = (): CreatorBehaviourBeautyPointsState => useSelector(root);

export default {
    root,
};
