import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { resetMessages, setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export const SEND_TEST_MAILS_ACTION = 'poms/test-mail/SEND';

export const sendTestMails = createAction(SEND_TEST_MAILS_ACTION);

export function * sendTestMailsSaga (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(resetMessages('administrationTestData'));

        yield call(api.sendTestMails, accessToken);

        yield put(setMessageAtPosition(message('administration.testData.sendTestMails.success'), 'administrationTestData', 'success'));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('administration.testData.sendTestMails.forbidden'), 'administrationTestData', 'error'));
        } else {
            yield put(setMessageAtPosition(message('administration.testData.sendTestMails.error'), 'administrationTestData', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchSendTestMails (): Generator<ForkEffect> {
    yield takeLatest(SEND_TEST_MAILS_ACTION, sendTestMailsSaga);
}
