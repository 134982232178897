import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { PayoutCycleRunDetails } from '~api/model/payout-cycle';
import api from '~api/payout-cycle';

import { HttpStatus } from '~constants/http';

import { selectors as oidcSelectors } from '~modules/oidc';
import {
    FETCH_PAYOUT_CYCLE_RUN_DETAILS,
    FetchPayoutCycleRunDetails,
    setPayoutCycleRunDetails,
    setPayoutCycleRunDetailsError,
    setPayoutCycleRunDetailsForbidden,
    setPayoutCycleRunDetailsNotFound,
} from '~modules/payoutCycleRunDetails';

export function * fetchPayoutCycleRunDetails (action: FetchPayoutCycleRunDetails): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const payoutCycleRunId = action.payload;

    try {
        const { data }: AxiosResponse<PayoutCycleRunDetails> = yield call(api.fetchPayoutCycleRunDetails, payoutCycleRunId, accessToken);

        yield put(setPayoutCycleRunDetails(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPayoutCycleRunDetailsForbidden());
        } else if (error.response?.status === HttpStatus.NotFound) {
            yield put(setPayoutCycleRunDetailsNotFound());
        } else {
            yield put(setPayoutCycleRunDetailsError());
        }
    }
}

export default function * watchFetchPayoutCycleRunDetails (): Generator<ForkEffect> {
    yield takeEvery(FETCH_PAYOUT_CYCLE_RUN_DETAILS, fetchPayoutCycleRunDetails);
}
