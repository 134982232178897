import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';
import { PayoutCycleRunsProcessStepMarkedForCalculationState } from '~modules/payoutCycleRunsProcessStepsMarkedForCalculation/payoutCycleRunsProcessStepsMarkedForCalculation';

const root = (state: AppState): PayoutCycleRunsProcessStepMarkedForCalculationState => state.payoutCycleRunsProcessStepsMarkedForCalculation;

export const usePayoutCycleRunsProcessStepsMarkedForCalculation = (): PayoutCycleRunsProcessStepMarkedForCalculationState => useSelector(root);

export default {
    root,
};
