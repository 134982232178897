import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import api from '~api/creators';
import { CreatorChanges } from '~api/model/creator';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREATOR_CHANGES,
    FetchCreatorChanges,
    setCreatorChanges,
    setCreatorChangesError,
    setCreatorChangesForbidden,
} from '~modules/creatorChanges';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorChanges (action: FetchCreatorChanges): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const creatorId = action.payload;

    try {
        const { data }: AxiosResponse<CreatorChanges> = yield call(api.fetchCreatorChanges, creatorId, accessToken);

        yield put(setCreatorChanges(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorChangesForbidden());
        } else {
            yield put(setCreatorChangesError());
        }
    }
}

export default function * watchFetchCreatorChanges (): Generator<ForkEffect> {
    yield takeEvery(FETCH_CREATOR_CHANGES, fetchCreatorChanges);
}
