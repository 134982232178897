// type declaration
import { createAction, createReducer } from '@reduxjs/toolkit';

import { Creator } from '~api/model/creator';

export const FETCH_CREATORS = 'poms/creators/FETCH';
export const SET_CREATORS = 'poms/creators/SET';
export const SET_CURRENT_TAB = 'poms/creators/SET_CURRENT_TAB';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorsTab = 'OVERVIEW' | 'PAYOUT';

export type CreatorsState = {
    creators: Creator[] | null;
    fetchState: FetchState;
    currentTab: CreatorsTab;
};

// actions
export const fetchCreators = createAction(FETCH_CREATORS);

export const setCreators = createAction(
    SET_CREATORS,
    (creators: Creator[] | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            creators,
            fetchState,
        },
    }));

export const setCreatorsForbidden = (): ReturnType<typeof setCreators> =>
    setCreators(null, 'FORBIDDEN');

export const setCreatorsError = (): ReturnType<typeof setCreators> =>
    setCreators(null, 'ERROR');

export const setCurrentTab = createAction<CreatorsTab>(SET_CURRENT_TAB);

// reducer
const defaultState: CreatorsState = {
    creators: null,
    fetchState: 'INIT',
    currentTab: 'OVERVIEW',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchCreators, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCreators, (state, { payload }) => ({
            ...state,
            ...payload,
        }))
        .addCase(setCurrentTab, (state, { payload }) => ({
            ...state,
            currentTab: payload,
        }));
});
