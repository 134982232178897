import { createAction, createReducer } from '@reduxjs/toolkit';

import { CompensationOverview } from '~api/model/compensation';

// type declaration
export const FETCH_COMPENSATION_OVERVIEW = 'poms/compensationOverview/FETCH_COMPENSATION_OVERVIEW';
export const SET_COMPENSATION_OVERVIEW = 'poms/compensationOverview/SET_COMPENSATION_OVERVIEW';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'NOT_FOUND' | 'ERROR' | 'FORBIDDEN';

export type CompensationOverviewState = {
    compensationOverview: CompensationOverview | null;
    fetchState: FetchState;
};

// actions
export const fetchCompensationOverview = createAction(FETCH_COMPENSATION_OVERVIEW);

export const setCompensationOverview = createAction(
    SET_COMPENSATION_OVERVIEW,
    (compensationOverview: CompensationOverview | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            compensationOverview,
            fetchState,
        }
    }));

export type SetCompensationOverviewAction = ReturnType<typeof setCompensationOverview>;

export const setCompensationOverviewForbidden = (): ReturnType<typeof setCompensationOverview> =>
    setCompensationOverview(null, 'FORBIDDEN');

export const setCompensationOverviewError = (): ReturnType<typeof setCompensationOverview> =>
    setCompensationOverview(null, 'ERROR');

// reducer
const defaultState: CompensationOverviewState = {
    compensationOverview: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCompensationOverview, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCompensationOverview, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
