import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorPayoutCorrection } from '~api/model/payout-correction';

export const FETCH_CREATOR_PAYOUT_CORRECTIONS = 'poms/creatorPayoutCorrections/FETCH';
export const SET_CREATOR_PAYOUT_CORRECTIONS = 'poms/creatorPayoutCorrections/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorPayoutCorrectionsState = {
    corrections: CreatorPayoutCorrection[] | null;
    fetchState: FetchState;
};

export const fetchCreatorPayoutCorrections = createAction<string>(FETCH_CREATOR_PAYOUT_CORRECTIONS);

export const setCreatorPayoutCorrections = createAction(
    SET_CREATOR_PAYOUT_CORRECTIONS,
    (corrections: CreatorPayoutCorrection[] | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            corrections,
            fetchState
        }
    })
);

export type FetchCreatorPayoutCorrections = ReturnType<typeof fetchCreatorPayoutCorrections>;

export type SetCreatorPayoutCorrections = ReturnType<typeof setCreatorPayoutCorrections>;

export const setCreatorPayoutCorrectionsForbidden = (): SetCreatorPayoutCorrections =>
    setCreatorPayoutCorrections(null, 'FORBIDDEN');

export const setCreatorPayoutCorrectionsError = (): SetCreatorPayoutCorrections =>
    setCreatorPayoutCorrections(null, 'ERROR');

export const resetCreatorPayoutCorrections = (): SetCreatorPayoutCorrections =>
    setCreatorPayoutCorrections(null, 'INIT');

const defaultState: CreatorPayoutCorrectionsState = {
    corrections: null,
    fetchState: 'INIT'
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreatorPayoutCorrections, (state) => ({
            ...state,
            fetchState: 'FETCHING'
        }))
        .addCase(setCreatorPayoutCorrections, (state, { payload }) => ({
            ...state,
            ...payload
        }));
});
