import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorCoreInformation } from '~api/model/creator';

// type declaration
export const FETCH_CREATOR_CORE_INFORMATION = 'poms/creatorCoreInformation/FETCH';
export const SET_CREATOR_CORE_INFORMATION = 'poms/creatorCoreInformation/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'NOT_FOUND' | 'ERROR' | 'FORBIDDEN';

export type CreatorCoreInformationState = {
    creator: CreatorCoreInformation | null;
    fetchState: FetchState;
};

// actions
export const fetchCreatorCoreInformation = createAction(
    FETCH_CREATOR_CORE_INFORMATION,
    (creatorId: string) => ({
        payload: creatorId,
    }));

export const setCreatorCoreInformation = createAction(
    SET_CREATOR_CORE_INFORMATION,
    (creator: CreatorCoreInformation | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            creator,
            fetchState,
        }
    }));

export type FetchCreatorCoreInformationAction = ReturnType<typeof fetchCreatorCoreInformation>;

export type SetCreatorCoreInformationAction = ReturnType<typeof setCreatorCoreInformation>;

export const setCreatorCoreInformationForbidden = (): SetCreatorCoreInformationAction =>
    setCreatorCoreInformation(null, 'FORBIDDEN');

export const setCreatorCoreInformationError = (): SetCreatorCoreInformationAction =>
    setCreatorCoreInformation(null, 'ERROR');

export const setCreatorCoreInformationNotFound = (): SetCreatorCoreInformationAction =>
    setCreatorCoreInformation(null, 'NOT_FOUND');

export const resetCreatorCoreInformation = (): SetCreatorCoreInformationAction =>
    setCreatorCoreInformation(null, 'INIT');

// reducer
const defaultState: CreatorCoreInformationState = {
    creator: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreatorCoreInformation, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCreatorCoreInformation, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
