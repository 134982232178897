import { createAction, createReducer } from '@reduxjs/toolkit';

import { Compensation } from '~api/model/compensation';

// type declarations
export const SET_COMPENSATION_TO_DELETE = 'poms/deleteCompensationDialog/SET_COMPENSATION_TO_DELETE';
export const DELETE_COMPENSATION = 'poms/deleteCompensationDialog/DELETE_COMPENSATION';

export type DeleteCompensationDialogState = {
    compensationToDelete: Compensation | null;
}

// actions
export const setCompensationToDelete = createAction<Compensation | null>(SET_COMPENSATION_TO_DELETE);
export const deleteCompensation = createAction<Compensation>(DELETE_COMPENSATION);

// reducer
const defaultState: DeleteCompensationDialogState = {
    compensationToDelete: null,
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setCompensationToDelete, (state, { payload }) => ({
            ...state,
            compensationToDelete: payload
        }));
});
