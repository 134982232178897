import axios, { AxiosPromise } from 'axios';

import { CreatorFilterOptions } from '~api/model/creator';

import { Endpoints } from '~constants/endpoints';

import { PayoutCycleOverview, PayoutCycleRunCreatorOverview, PayoutCycleRunDetails, PayoutCycleRunsOverview } from './model/payout-cycle';
import { addAuthorizationHeader, endpoint } from './_helper';

const fetchPayoutCycles = (accessToken: string): AxiosPromise<PayoutCycleOverview> => {
    return axios.get(Endpoints.PAYOUT_CYCLES, addAuthorizationHeader(accessToken));
};

const fetchPayoutCycleRunsOverview = (payoutCycleId: string, accessToken: string): AxiosPromise<PayoutCycleRunsOverview> => {
    return axios.get(endpoint(Endpoints.PAYOUT_CYCLE_RUNS_OVERVIEW, payoutCycleId), addAuthorizationHeader(accessToken));
};

const fetchPayoutCycleRunEstimation = (payoutCycleId: string, accessToken: string): AxiosPromise<PayoutCycleRunsOverview> => {
    return axios.get(endpoint(Endpoints.PAYOUT_CYCLE_RUN_ESTIMATION, payoutCycleId), addAuthorizationHeader(accessToken));
};

const fetchPayoutCycleRunDetails = (payoutCycleRunId: string, accessToken: string): AxiosPromise<PayoutCycleRunDetails> => {
    return axios.get(endpoint(Endpoints.PAYOUT_CYCLE_RUN_DETAILS, payoutCycleRunId), addAuthorizationHeader(accessToken));
};

const fetchCreatorsOfPayoutCycleRun = (payoutCycleRunId: string, creatorFilterOptions: CreatorFilterOptions, accessToken: string): AxiosPromise<PayoutCycleRunCreatorOverview> => {
    return axios.get(endpoint(Endpoints.CREATOR_OF_PAYOUT_CYCLE, payoutCycleRunId), addAuthorizationHeader(accessToken, { params: creatorFilterOptions }));
};

const setPayoutOnHold = (payoutCycleRunId: string, creatorId: string, accessToken: string): AxiosPromise<string> => {
    return axios.patch(endpoint(Endpoints.SET_PAYOUT_ON_HOLD, payoutCycleRunId, creatorId), {}, addAuthorizationHeader(accessToken));
};

const stopPayout = (payoutCycleRunId: string, creatorId: string, accessToken: string): AxiosPromise<string> => {
    return axios.patch(endpoint(Endpoints.STOP_PAYOUT, payoutCycleRunId, creatorId), {}, addAuthorizationHeader(accessToken));
};

const resumePayout = (payoutCycleRunId: string, creatorId: string, accessToken: string): AxiosPromise<string> => {
    return axios.patch(endpoint(Endpoints.RESUME_PAYOUT, payoutCycleRunId, creatorId), {}, addAuthorizationHeader(accessToken));
};

const setPayoutCycleRunOnHold = (payoutCycleRunId: string, accessToken: string): AxiosPromise<string> => {
    return axios.patch(endpoint(Endpoints.SET_PAYOUT_CYCLE_RUN_ON_HOLD, payoutCycleRunId), {}, addAuthorizationHeader(accessToken));
};

const resumePayoutCycleRun = (payoutCycleRunId: string, accessToken: string): AxiosPromise<string> => {
    return axios.patch(endpoint(Endpoints.RESUME_PAYOUT_CYCLE_RUN, payoutCycleRunId), {}, addAuthorizationHeader(accessToken));
};

const showCreditNoteDocumentDraft = (payoutCycleRunId: string, creatorId: string, accessToken: string): AxiosPromise<Blob> => {
    return axios.get(endpoint(Endpoints.SHOW_CREDIT_NOTE_DOCUMENTATION_DRAFT, payoutCycleRunId, creatorId), addAuthorizationHeader(accessToken, { responseType: 'blob' }));
};

export default {
    fetchPayoutCycles,
    fetchPayoutCycleRunsOverview,
    fetchPayoutCycleRunEstimation,
    fetchPayoutCycleRunDetails,
    fetchCreatorsOfPayoutCycleRun,
    showCreditNoteDocumentDraft,
    setPayoutOnHold,
    stopPayout,
    resumePayout,
    setPayoutCycleRunOnHold,
    resumePayoutCycleRun,
};
