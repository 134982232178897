import { useSelector } from 'react-redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorDashboardAggregations } from '~api/model/dashboardAggregations';

import selectors from './selectors';

export const FETCH_CREATOR_DASHBOARD_AGGREGATIONS = 'poms/creatorDashboardAggregations/FETCH';
export const SET_CREATOR_DASHBOARD_AGGREGATIONS = 'poms/creatorDashboardAggregations/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorDashboardAggregationsState = {
    data: CreatorDashboardAggregations | null;
    fetchState: FetchState;
}

export type CreatorDashboardAggregationsAction = ReturnType<typeof setCreatorDashboardAggregations>;

export type FetchCreatorDashboardAggregationsAction = ReturnType<typeof fetchCreatorDashboardAggregations>;

// implementation
export const defaultState: CreatorDashboardAggregationsState = {
    data: null,
    fetchState: 'INIT'
};

// actions
export const fetchCreatorDashboardAggregations = createAction<string>(FETCH_CREATOR_DASHBOARD_AGGREGATIONS);

export const setCreatorDashboardAggregations = createAction(
    SET_CREATOR_DASHBOARD_AGGREGATIONS,
    (dashboardAggregations: CreatorDashboardAggregations | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            data: dashboardAggregations,
            fetchState,
        }
    })
);

export const setCreatorDashboardAggregationsForbidden = (): CreatorDashboardAggregationsAction => setCreatorDashboardAggregations(null, 'FORBIDDEN');

export const setCreatorDashboardAggregationsError = (): CreatorDashboardAggregationsAction => setCreatorDashboardAggregations(null, 'ERROR');

export const resetCreatorDashboardAggregations = (): CreatorDashboardAggregationsAction => setCreatorDashboardAggregations(null, 'INIT');

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setCreatorDashboardAggregations, (state, { payload }) => ({
            ...state,
            ...payload,
        }))
        .addCase(fetchCreatorDashboardAggregations, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }));
});

export const useCreatorDashboardAggregations = (): CreatorDashboardAggregationsState => useSelector(selectors.root);
export const useCreatorDashboardAggregationsData = (): CreatorDashboardAggregations | null => useSelector(selectors.data);
