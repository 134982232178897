import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';
import { fetchPayoutCycleRunsProcessStepsCalculated } from '~modules/payoutCycleRunsProcessStepsCalculated';
import { fetchPayoutCycleRunsProcessStepsPayoutsCreated } from '~modules/payoutCycleRunsProcessStepsPayoutsCreated';

export const GENERATE_PAYOUT_RUNS_FOR_PAYOUT_CYCLE_RUN = 'poms/payout-cycle-run/generate-payout-runs';

export const generatePayoutRunForPayoutCycle = createAction<string>(GENERATE_PAYOUT_RUNS_FOR_PAYOUT_CYCLE_RUN);

export function * generatePayoutRunForPayoutCycleSaga (action: ReturnType<typeof generatePayoutRunForPayoutCycle>): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield call(api.generatePayoutRunForPayoutCycle, action.payload, accessToken);
        yield put(setMessageAtPosition(message('administration.payout.overview.payoutsCreated.success'), 'administrationPayoutProcessOverviewTop', 'success'));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('administration.payout.overview.payoutsCreated.forbidden'), 'administrationPayoutProcessOverviewTop', 'error'));
        } else {
            yield put(setMessageAtPosition(message('administration.payout.overview.payoutsCreated.error'), 'administrationPayoutProcessOverviewTop', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchPayoutCycleRunsProcessStepsPayoutsCreated());
        yield put(fetchPayoutCycleRunsProcessStepsCalculated());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchGeneratePayoutRunForPayoutCycle (): Generator<ForkEffect> {
    yield takeLatest(GENERATE_PAYOUT_RUNS_FOR_PAYOUT_CYCLE_RUN, generatePayoutRunForPayoutCycleSaga);
}
