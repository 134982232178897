import { UserState } from 'redux-oidc';
import { createSelector } from 'reselect';

import { AppState } from '~modules/index';

const root = (state: AppState): UserState => state.oidc || {};

const accessToken = createSelector(root, (root) => root.user?.access_token || '');

export default {
    root,
    accessToken,
};
