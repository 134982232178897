import { createAction, createReducer } from '@reduxjs/toolkit';

import { BeautyPointsDashboardTileDetails } from '~api/model/dashboardAggregations';

// type declaration
export const FETCH_COLLECTED_BEAUTY_POINTS_DASHBOARD_DETAILS = 'poms/collectedBeautyPointsDashboardDetails/FETCH';
export const SET_COLLECTED_BEAUTY_POINTS_DASHBOARD_DETAILS = 'poms/collectedBeautyPointsDashboardDetails/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CollectedBeautyPointsDashboardDetailsState = {
    dashboardDetails: BeautyPointsDashboardTileDetails | null;
    fetchState: FetchState;
};

// actions
export const fetchCollectedBeautyPointsDashboardDetails = createAction(
    FETCH_COLLECTED_BEAUTY_POINTS_DASHBOARD_DETAILS,
    (creatorId?: string | null) => ({
        payload: null,
        meta: {
            dataType: 'collectedBeautyPoints',
            creatorId,
        }
    }));

export const setCollectedBeautyPointsDashboardDetails = createAction(
    SET_COLLECTED_BEAUTY_POINTS_DASHBOARD_DETAILS,
    (dashboardDetails: BeautyPointsDashboardTileDetails | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            dashboardDetails,
            fetchState,
        }
    }));

export type FetchCollectedBeautyPointsDashboardDetailsAction = ReturnType<typeof fetchCollectedBeautyPointsDashboardDetails>;

export type SetCollectedBeautyPointsDashboardDetailsAction = ReturnType<typeof setCollectedBeautyPointsDashboardDetails>;

export const setCollectedBeautyPointsDashboardDetailsForbidden = (): SetCollectedBeautyPointsDashboardDetailsAction =>
    setCollectedBeautyPointsDashboardDetails(null, 'FORBIDDEN');

export const setCollectedBeautyPointsDashboardDetailsError = (): SetCollectedBeautyPointsDashboardDetailsAction =>
    setCollectedBeautyPointsDashboardDetails(null, 'ERROR');

export const resetCollectedBeautyPointsDashboardDetails = (): SetCollectedBeautyPointsDashboardDetailsAction =>
    setCollectedBeautyPointsDashboardDetails(null, 'INIT');

// reducer
const defaultState: CollectedBeautyPointsDashboardDetailsState = {
    dashboardDetails: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCollectedBeautyPointsDashboardDetails, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCollectedBeautyPointsDashboardDetails, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
