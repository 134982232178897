import { createSelector } from 'reselect';

import { AppState } from '~modules/index';

import { CreatorDashboardAggregationsState } from './creatorDashboardAggregations';

const root = (state: AppState): CreatorDashboardAggregationsState => state.creatorDashboardAggregations;
const data = createSelector(root, (root) => root.data);

export default {
    root,
    data,
};
