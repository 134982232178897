import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { BillableViewsDashboardDetailsState } from './billableViewsDashboardDetails';

const root = (state: AppState): BillableViewsDashboardDetailsState => state.billableViewsDashboardDetails;

export const useBillableViewsDashboardDetails = (): BillableViewsDashboardDetailsState => useSelector(root);

export default {
    root,
};
