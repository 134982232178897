import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorChanges } from '~api/model/creator';

export const FETCH_CREATOR_CHANGES = 'poms/creatorChanges/FETCH';
export const SET_CREATOR_CHANGES = 'poms/creatorChanges/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorChangesState = {
    changes: CreatorChanges;
    fetchState: FetchState;
};

export const fetchCreatorChanges = createAction<string>(FETCH_CREATOR_CHANGES);

export const setCreatorChanges = createAction(
    SET_CREATOR_CHANGES,
    (changes: CreatorChanges, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            changes,
            fetchState
        }
    })
);

export type FetchCreatorChanges = ReturnType<typeof fetchCreatorChanges>;

export type SetCreatorChanges = ReturnType<typeof setCreatorChanges>;

export const setCreatorChangesForbidden = (): SetCreatorChanges =>
    setCreatorChanges([], 'FORBIDDEN');

export const setCreatorChangesError = (): SetCreatorChanges =>
    setCreatorChanges([], 'ERROR');

export const resetCreatorChanges = (): SetCreatorChanges =>
    setCreatorChanges([], 'INIT');

const defaultState: CreatorChangesState = {
    changes: [],
    fetchState: 'INIT'
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreatorChanges, (state) => ({
            ...state,
            fetchState: 'FETCHING'
        }))
        .addCase(setCreatorChanges, (state, { payload }) => ({
            ...state,
            ...payload
        }));
});
