import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/dashboard-aggregations';
import { CreatorsDashboardTileDetails } from '~api/model/dashboardAggregations';

import { HttpStatus } from '~constants/http';

import { getRangeFromFilter } from '~helper/range-filter';

import {
    FETCH_CREATOR_DASHBOARD_DETAILS,
    FetchCreatorDashboardDetailsAction,
    setCreatorDashboardDetails,
    setCreatorDashboardDetailsError,
    setCreatorDashboardDetailsForbidden,
} from '~modules/creatorDashboardDetails';
import {
    selectors as dashboardDetailTileDateFilterSelectors,
    SET_DASHBOARD_DETAIL_TILE_DATE_FILTER,
    SetDashboardDetailTileDateFilterAction,
} from '~modules/dashboardDetailTileDateFilter';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorDashboardDetails (action: FetchCreatorDashboardDetailsAction | SetDashboardDetailTileDateFilterAction): Generator<StrictEffect, void, any> {
    if (action.type === SET_DASHBOARD_DETAIL_TILE_DATE_FILTER && action.meta.dataType !== 'creator') {
        return;
    }

    const accessToken = yield select(oidcSelectors.accessToken);
    const rangeFilter = yield select(dashboardDetailTileDateFilterSelectors.root);
    const range = getRangeFromFilter(rangeFilter);

    try {
        const { data }: AxiosResponse<CreatorsDashboardTileDetails> = yield call(api.fetchCreatorsDashboardDetails, range.from, range.to, accessToken);

        yield put(setCreatorDashboardDetails(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorDashboardDetailsForbidden());
        } else {
            yield put(setCreatorDashboardDetailsError());
        }
    }
}

export default function * watchFetchCreatorDashboardDetails (): Generator<ForkEffect> {
    yield takeLatest(FETCH_CREATOR_DASHBOARD_DETAILS, fetchCreatorDashboardDetails);
    yield takeLatest(SET_DASHBOARD_DETAIL_TILE_DATE_FILTER, fetchCreatorDashboardDetails);
}
