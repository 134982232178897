import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';
import { PayoutCycleRunsProcessStepRecalculatableState } from '~modules/payoutCycleRunsProcessStepsRecalculatable/payoutCycleRunsProcessStepsRecalculatable';

const root = (state: AppState): PayoutCycleRunsProcessStepRecalculatableState => state.payoutCycleRunsProcessStepsRecalculatable;

export const usePayoutCycleRunsProcessStepsRecalculatable = (): PayoutCycleRunsProcessStepRecalculatableState => useSelector(root);

export default {
    root,
};
