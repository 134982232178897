import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import api from '~api/creators';
import { CreatorCoreInformation } from '~api/model/creator';

import { HttpStatus } from '~constants/http';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchCreatorChanges } from '~modules/creatorChanges';
import { setCreatorCoreInformation, setCreatorCoreInformationError, setCreatorCoreInformationForbidden } from '~modules/creatorCoreInformation';
import { fetchCreatorsLevelChangeOverview } from '~modules/creatorsLevelChangeOverview';
import { hideCreatorUpdateLevelDialog, UPDATE_CREATOR_LEVEL, updateCreatorLevel } from '~modules/creatorUpdateLevelDialog/creatorUpdateLevelDialog';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * updateCreatorLevelSaga (action: ReturnType<typeof updateCreatorLevel>): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const creatorCoreInformation = action.payload;

    try {
        const { data }: AxiosResponse<CreatorCoreInformation> = yield call(api.updateCreatorLevel, creatorCoreInformation.creatorId, creatorCoreInformation.levelId, accessToken);

        yield put(setCreatorCoreInformation(data));
        yield put(hideCreatorUpdateLevelDialog());
        yield put(fetchCreatorChanges(creatorCoreInformation.creatorId));
        yield put(fetchCreatorsLevelChangeOverview());
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorCoreInformationForbidden());
            yield put(hideCreatorUpdateLevelDialog());
        } else {
            yield put(setCreatorCoreInformationError());
            yield put(hideCreatorUpdateLevelDialog());
        }
    } finally {
        yield put(resetAllActiveButtons());
    }
}

export default function * watchUpdateCreatorLevel (): Generator<ForkEffect> {
    yield takeEvery(UPDATE_CREATOR_LEVEL, updateCreatorLevelSaga);
}
