import { createAction, createReducer } from '@reduxjs/toolkit';

import { BeautyPointsDashboardTileDetails } from '~api/model/dashboardAggregations';

// type declaration
export const FETCH_PAID_OUT_BEAUTY_POINTS_DASHBOARD_DETAILS = 'poms/paidOutBeautyPointsDashboardDetails/FETCH';
export const SET_PAID_OUT_BEAUTY_POINTS_DASHBOARD_DETAILS = 'poms/paidOutBeautyPointsDashboardDetails/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type PaidOutBeautyPointsDashboardDetailsState = {
    dashboardDetails: BeautyPointsDashboardTileDetails | null;
    fetchState: FetchState;
};

// actions
export const fetchPaidOutBeautyPointsDashboardDetails = createAction(
    FETCH_PAID_OUT_BEAUTY_POINTS_DASHBOARD_DETAILS,
    (creatorId?: string | null) => ({
        payload: null,
        meta: {
            dataType: 'paidOutBeautyPoints',
            creatorId,
        }
    }));

export const setPaidOutBeautyPointsDashboardDetails = createAction(
    SET_PAID_OUT_BEAUTY_POINTS_DASHBOARD_DETAILS,
    (dashboardDetails: BeautyPointsDashboardTileDetails | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            dashboardDetails,
            fetchState,
        }
    }));

export type FetchPaidOutBeautyPointsDashboardDetailsAction = ReturnType<typeof fetchPaidOutBeautyPointsDashboardDetails>;

export type SetPaidOutBeautyPointsDashboardDetailsAction = ReturnType<typeof setPaidOutBeautyPointsDashboardDetails>;

export const setPaidOutBeautyPointsDashboardDetailsForbidden = (): SetPaidOutBeautyPointsDashboardDetailsAction =>
    setPaidOutBeautyPointsDashboardDetails(null, 'FORBIDDEN');

export const setPaidOutBeautyPointsDashboardDetailsError = (): SetPaidOutBeautyPointsDashboardDetailsAction =>
    setPaidOutBeautyPointsDashboardDetails(null, 'ERROR');

export const resetPaidOutBeautyPointsDashboardDetails = (): SetPaidOutBeautyPointsDashboardDetailsAction =>
    setPaidOutBeautyPointsDashboardDetails(null, 'INIT');

// reducer
const defaultState: PaidOutBeautyPointsDashboardDetailsState = {
    dashboardDetails: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchPaidOutBeautyPointsDashboardDetails, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setPaidOutBeautyPointsDashboardDetails, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
