import axios, { AxiosPromise } from 'axios';

import { addAuthorizationHeader, endpoint } from '~api/_helper';
import { CreatorPayoutCycleRun } from '~api/model/payout-cycle';

import { Endpoints } from '~constants/endpoints';

import {
    Creator,
    CreatorBehaviourBeautyPoints,
    CreatorChanges,
    CreatorCoreInformation,
    CreatorPayoutOverview,
    CreatorsLevelChangeOverview,
    CreatorVideosOverview,
} from './model/creator';

const fetchCreators = (accessToken: string): AxiosPromise<Creator[]> => {
    return axios.get(Endpoints.CREATORS, addAuthorizationHeader(accessToken));
};

const fetchCreatorCoreInformation = (creatorId: string, accessToken: string): AxiosPromise<CreatorCoreInformation> => {
    return axios.get(endpoint(Endpoints.CREATOR_CORE_INFORMATION, creatorId), addAuthorizationHeader(accessToken));
};

const fetchCreatorPayoutOverview = (creatorId: string, accessToken: string): AxiosPromise<CreatorPayoutOverview> => {
    return axios.get(endpoint(Endpoints.CREATOR_PAYOUT_OVERVIEW, creatorId), addAuthorizationHeader(accessToken));
};

const fetchEstimatedCreatorPayout = (creatorId: string, accessToken: string): AxiosPromise<CreatorPayoutCycleRun> => {
    return axios.get(endpoint(Endpoints.CREATOR_ESTIMATED_PAYOUT_INFORMATION, creatorId), addAuthorizationHeader(accessToken));
};

const fetchCreatorContentOverview = (creatorId: string, accessToken: string): AxiosPromise<CreatorVideosOverview> => {
    return axios.get(endpoint(Endpoints.CREATOR_CONTENT_OVERVIEW, creatorId), addAuthorizationHeader(accessToken));
};

const fetchCreatorBehaviourBeautyPoints = (creatorId: string, accessToken: string): AxiosPromise<CreatorBehaviourBeautyPoints> => {
    return axios.get(endpoint(Endpoints.CREATOR_BEHAVIOUR_BEAUTY_POINTS, creatorId), addAuthorizationHeader(accessToken));
};

const fetchCreatorChanges = (creatorId: string, accessToken: string): AxiosPromise<CreatorChanges> => {
    return axios.get(endpoint(Endpoints.CREATOR_CHANGES, creatorId), addAuthorizationHeader(accessToken));
};

const fetchCreatorsLevelChangeOverview = (accessToken: string): AxiosPromise<CreatorsLevelChangeOverview> => {
    return axios.get(endpoint(Endpoints.CREATORS_LEVEL_CHANGE_OVERVIEW), addAuthorizationHeader(accessToken));
};

const updateCreatorLevel = (creatorId: string, newLevelId: string, accessToken: string): AxiosPromise<CreatorCoreInformation> => {
    return axios.patch(endpoint(Endpoints.CREATOR_UPDATE_LEVEL, creatorId), { levelId: newLevelId }, addAuthorizationHeader(accessToken));
};

export default {
    fetchCreators,
    fetchCreatorCoreInformation,
    fetchCreatorPayoutOverview,
    fetchEstimatedCreatorPayout,
    fetchCreatorContentOverview,
    fetchCreatorBehaviourBeautyPoints,
    fetchCreatorChanges,
    fetchCreatorsLevelChangeOverview,
    updateCreatorLevel,
};
