import axios, { AxiosPromise } from 'axios';

import { addAuthorizationHeader, endpoint } from '~api/_helper';
import { CreditNote } from '~api/model/creditNote';

import { Endpoints } from '~constants/endpoints';

const fetchCreditNotesWithProcessingError = (accessToken: string): AxiosPromise<Array<CreditNote>> => {
    return axios.get(endpoint(Endpoints.CREDIT_NOTES_WITH_PROCESSING_ERROR), addAuthorizationHeader(accessToken));
};

export default {
    fetchCreditNotesWithProcessingError,
};
