import { createSelector } from 'reselect';

import { AppState } from '~modules/index';
import { PayoutCycleRunCreatorsState } from '~modules/payoutCycleRunCreators/payoutCycleRunCreators';

const root = (state: AppState): PayoutCycleRunCreatorsState => state.payoutCycleRunCreators;
const data = createSelector(root, (root) => root.data);

export default {
    root,
    data,
};
