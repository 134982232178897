import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import api from '~api/creators';
import { CreatorPayoutOverview } from '~api/model/creator';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREATOR_PAYOUT_OVERVIEW,
    FetchCreatorPayoutOverviewAction,
    setCreatorPayoutOverview,
    setCreatorPayoutOverviewError,
    setCreatorPayoutOverviewForbidden,
} from '~modules/creatorPayoutOverview';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorPayoutOverview (action: FetchCreatorPayoutOverviewAction): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const creatorId = action.payload;

    try {
        const { data }: AxiosResponse<CreatorPayoutOverview> = yield call(api.fetchCreatorPayoutOverview, creatorId, accessToken);

        yield put(setCreatorPayoutOverview(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorPayoutOverviewForbidden());
        } else {
            yield put(setCreatorPayoutOverviewError());
        }
    }
}

export default function * watchFetchCreatorPayoutOverview (): Generator<ForkEffect> {
    yield takeEvery(FETCH_CREATOR_PAYOUT_OVERVIEW, fetchCreatorPayoutOverview);
}
