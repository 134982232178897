import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';
import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';

import { HttpStatus } from '~constants/http';

import { selectors as oidcSelectors } from '~modules/oidc';
import {
    FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PUBLISHED,
    setPayoutCycleRunsProcessStepsPublished,
    setPayoutCycleRunsProcessStepsPublishedError,
    setPayoutCycleRunsProcessStepsPublishedForbidden
} from '~modules/payoutCycleRunsProcessStepsPublished';

export function * fetchPayoutCycleRunsProcessStepsPublished (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<PayoutCycleRunProcessSteps> = yield call(api.fetchPayoutCycleRunsPublished, accessToken);

        yield put(setPayoutCycleRunsProcessStepsPublished(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPayoutCycleRunsProcessStepsPublishedForbidden());
        } else {
            yield put(setPayoutCycleRunsProcessStepsPublishedError());
        }
    }
}

export default function * watchFetchPayoutCycleRunsProcessStepsPublished (): Generator<ForkEffect> {
    yield takeLatest(FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PUBLISHED, fetchPayoutCycleRunsProcessStepsPublished);
}
