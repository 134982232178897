import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';
import { VideoEvent } from '~api/model/videoEvent';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREATOR_VIDEO_EVENTS,
    fetchCreatorVideoEvents,
    setCreatorVideoEvents,
    setCreatorVideoEventsError,
    setCreatorVideoEventsForbidden,
} from '~modules/creatorVideoEvents';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorVideoEventsSaga (
    action: ReturnType<typeof fetchCreatorVideoEvents>,
): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const creatorId = action.payload;

    try {
        const { data }: AxiosResponse<VideoEvent[]> = yield call(api.fetchCreatorVideoEvents, creatorId, accessToken);

        yield put(setCreatorVideoEvents(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorVideoEventsForbidden());
        } else {
            yield put(setCreatorVideoEventsError());
        }
    }
}

export default function * watchFetchCreatorVideoEvents (): Generator<ForkEffect> {
    yield takeLatest(FETCH_CREATOR_VIDEO_EVENTS, fetchCreatorVideoEventsSaga);
}
