import { createAction, createReducer } from '@reduxjs/toolkit';

import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';

// type declaration
export const FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PUBLISHED = 'poms/payout-cycle-runs-process-steps-published/FETCH';
export const SET_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PUBLISHED = 'poms/payout-cycle-runs-process-steps-published/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type PayoutCycleRunsProcessStepPublishedState = {
    processSteps: PayoutCycleRunProcessSteps | null;
    fetchState: FetchState;
}

// actions
export const fetchPayoutCycleRunsProcessStepsPublished = createAction(FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PUBLISHED);

export const setPayoutCycleRunsProcessStepsPublished = createAction(
    SET_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PUBLISHED,
    (processSteps: PayoutCycleRunProcessSteps | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            processSteps,
            fetchState,
        },
    }),
);

export const setPayoutCycleRunsProcessStepsPublishedForbidden = (): ReturnType<typeof setPayoutCycleRunsProcessStepsPublished> =>
    setPayoutCycleRunsProcessStepsPublished(null, 'FORBIDDEN');

export const setPayoutCycleRunsProcessStepsPublishedError = (): ReturnType<typeof setPayoutCycleRunsProcessStepsPublished> =>
    setPayoutCycleRunsProcessStepsPublished(null, 'ERROR');

// reducer
const defaultState: PayoutCycleRunsProcessStepPublishedState = {
    processSteps: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchPayoutCycleRunsProcessStepsPublished, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setPayoutCycleRunsProcessStepsPublished, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
