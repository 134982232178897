import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/dashboard-aggregations';
import { DashboardPayoutSummary } from '~api/model/dashboardAggregations';

import { HttpStatus } from '~constants/http';

import { processAxiosError } from '~helper/_processAxiosError';

import { FETCH_DASHBOARD_PAYOUT_SUMMARY, setDashboardPayoutSummary, setFetchState } from '~modules/dashboardPayoutSummary';
import { Message, resetMessages, setMessages } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchDashboardPayoutSummary (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(resetMessages());

        const { data }: AxiosResponse<DashboardPayoutSummary> = yield call(api.fetchPayoutSummary, accessToken);

        yield put(setDashboardPayoutSummary(data));
    } catch (error: any) {
        const { messages } = processAxiosError(error);
        const payoutSummaryMessages: Message[] = messages.map((text) => ({
            text,
            severity: 'error',
            position: 'dashboardPayoutSummary',
        }));

        yield put(setMessages(payoutSummaryMessages));

        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setFetchState('FORBIDDEN'));
        } else {
            yield put(setFetchState('ERROR'));
        }
    }
}

export default function * watchDashboardPayoutSummary (): Generator<ForkEffect> {
    yield takeLatest(FETCH_DASHBOARD_PAYOUT_SUMMARY, fetchDashboardPayoutSummary);
}
