import { useSelector } from 'react-redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { AppState } from '~modules/index';

import selectors from './selectors';

// type declarations
export const SET_MESSAGES = 'poms/messages/SET';
export const RESET_MESSAGES = 'poms/messages/DELETE';

export type MessageSeverity = 'success' | 'info' | 'warning' | 'error';

export type AdministrationPosition =
    | 'administrationTestData'
    | 'administrationCreatorLevel'
    | 'administrationDashboardKPI'
    | 'administrationScepLookup'
    | 'administrationCalculatePayoutDate'
    | 'administrationPayoutProcessOverviewTop'
    | 'administrationPayoutProcessOverviewBottom'
    | 'administrationRecalculatePayoutRun'
    | 'administrationVideoEvents';
export type DialogPosition =
    | 'createPayoutCorrection' | 'editCompensationDialog'
    | 'deleteCompensationDialog'
    | 'brandVideoViewAssignmentDialog'
    | 'payoutStatusChangeConfirmDialog'
    | 'creatorUpdateLevelDialog';

export type MessagePosition =
    | DialogPosition
    | AdministrationPosition
    | 'dashboardPayoutSummary'
    | 'setPayoutCycleRunOnHoldOrResume';

export interface Message {
    position?: MessagePosition;
    text: string;
    severity: MessageSeverity;
}

// actions
export const setMessages = createAction<Message[]>(SET_MESSAGES);

export const setMessageAtPosition = createAction(
    SET_MESSAGES,
    (text: string, position: MessagePosition, severity: MessageSeverity = 'error') => ({
        payload: [
            {
                text,
                position,
                severity,
            },
        ],
    }),
);

export const setGlobalMessage = createAction(SET_MESSAGES, (text: string, severity: MessageSeverity = 'error') => ({
    payload: [
        {
            text,
            severity,
        },
    ],
}));

export const resetMessages = createAction<MessagePosition | undefined>(RESET_MESSAGES);

// reducer
const defaultState: Message[] = [];

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setMessages, (state, { payload }) => payload)
        .addCase(resetMessages, (state, { payload: position }) =>
            position ? state.filter((message) => message.position !== position) : [],
        );
});

export const useMessages = (): Message[] => useSelector(selectors.root);
export const useMessagesFor = (position: MessagePosition, severity?: MessageSeverity): Message[] =>
    useSelector((state: AppState) =>
        selectors
            .root(state)
            .filter((message): boolean => message.position === position)
            .filter((message): boolean => !severity || message.severity === severity),
    );
