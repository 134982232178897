import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons, setActiveButton } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { resetMessages, setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';
import { fetchPayoutCycleRunsProcessStepsMarkedForCalculation } from '~modules/payoutCycleRunsProcessStepsMarkedForCalculation';

export const CALCULATE_PAYOUT_DATE = 'poms/payout-cycle-run/calculate-dates';

export const calculatePayoutDate = createAction(CALCULATE_PAYOUT_DATE);

export function * calculatePayoutDateSaga (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(resetMessages('administrationCalculatePayoutDate'));
        yield put(setActiveButton('calculatePayoutDate'));

        yield call(api.calculatePayoutDate, accessToken);
        yield put(setMessageAtPosition(message('administration.payout.calculatePayoutDates.success'), 'administrationCalculatePayoutDate', 'success'));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('administration.payout.calculatePayoutDates.forbidden'), 'administrationCalculatePayoutDate', 'error'));
        } else {
            yield put(setMessageAtPosition(message('administration.payout.calculatePayoutDates.error'), 'administrationCalculatePayoutDate', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchPayoutCycleRunsProcessStepsMarkedForCalculation());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchCalculatePayoutDate (): Generator<ForkEffect> {
    yield takeLatest(CALCULATE_PAYOUT_DATE, calculatePayoutDateSaga);
}
