import { createAction, createReducer } from '@reduxjs/toolkit';

import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';

// type declaration
export const FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_MARKED_FOR_CALCULATION = 'poms/payout-cycle-runs-process-steps-marked-for-calculation/FETCH';
export const SET_PAYOUT_CYCLE_RUN_PROCESS_STEPS_MARKED_FOR_CALCULATION = 'poms/payout-cycle-runs-process-steps-steps-marked-for-calculation/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type PayoutCycleRunsProcessStepMarkedForCalculationState = {
    processSteps: PayoutCycleRunProcessSteps | null;
    fetchState: FetchState;
}

// actions
export const fetchPayoutCycleRunsProcessStepsMarkedForCalculation = createAction(FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_MARKED_FOR_CALCULATION);

export const setPayoutCycleRunsProcessStepsMarkedForCalculation = createAction(
    SET_PAYOUT_CYCLE_RUN_PROCESS_STEPS_MARKED_FOR_CALCULATION,
    (processSteps: PayoutCycleRunProcessSteps | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            processSteps,
            fetchState,
        },
    }),
);

export const setPayoutCycleRunsProcessStepsMarkedForCalculationForbidden = (): ReturnType<typeof setPayoutCycleRunsProcessStepsMarkedForCalculation> =>
    setPayoutCycleRunsProcessStepsMarkedForCalculation(null, 'FORBIDDEN');

export const setPayoutCycleRunsProcessStepsMarkedForCalculationError = (): ReturnType<typeof setPayoutCycleRunsProcessStepsMarkedForCalculation> =>
    setPayoutCycleRunsProcessStepsMarkedForCalculation(null, 'ERROR');

// reducer
const defaultState: PayoutCycleRunsProcessStepMarkedForCalculationState = {
    processSteps: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchPayoutCycleRunsProcessStepsMarkedForCalculation, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setPayoutCycleRunsProcessStepsMarkedForCalculation, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
