import { createAction, createReducer } from '@reduxjs/toolkit';

import { PayoutCycleRunDetails } from '~api/model/payout-cycle';

// type declarations
export const SET_PAYOUT_CYCLE_RUN_TO_SET_ON_HOLD_OR_RESUME = 'poms/payoutCycleRun/TO_BE_ON_HOLD_OR_RESUME';
export const PAYOUT_CYCLE_RUN_ON_HOLD_OR_RESUME = 'poms/payoutCycleRun/ON_HOLD_OR_RESUME';

export type SetPayoutCycleRunOnHoldDialogState = {
    payoutCycleRunToSetOnHoldOrResume: PayoutCycleRunDetails | null;
}

// actions
export const setPayoutCycleRunToBeOnHoldOrResume = createAction<PayoutCycleRunDetails | null>(SET_PAYOUT_CYCLE_RUN_TO_SET_ON_HOLD_OR_RESUME);
export const setPayoutCycleRunOnHoldOrResume = createAction<PayoutCycleRunDetails>(PAYOUT_CYCLE_RUN_ON_HOLD_OR_RESUME);

// reducer
const defaultState: SetPayoutCycleRunOnHoldDialogState = {
    payoutCycleRunToSetOnHoldOrResume: null,
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setPayoutCycleRunToBeOnHoldOrResume, (state, { payload }) => ({
            ...state,
            payoutCycleRunToSetOnHoldOrResume: payload
        }));
});
