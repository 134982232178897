import InfoIcon from '@mui/icons-material/Info';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';

import style from './Headline.module.scss';

export type HeadlineProps = {
    children: React.ReactNode;
    className?: string;
    'data-test'?: string;
    loading?: boolean;
    tooltip?: string;
    variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const Headline: React.FC<HeadlineProps> = ({
    children,
    className,
    'data-test': dataTest = 'headline',
    loading,
    tooltip,
    variant,
}) => {
    return (
        <Typography variant={ variant } className={ clsx(style.root, className) } data-test={ dataTest }>
            <div className={ loading ? style.fullWithText : undefined } data-test={ `${dataTest}.text` }>
                { loading ? <Skeleton variant="text" data-test={ `${dataTest}.loading` } /> : children }
            </div>
            { !loading && tooltip &&
            <Tooltip title={ tooltip } data-test={ `${dataTest}.tooltip` }>
                <InfoIcon className={ style.tooltipIcon } data-test={ `${dataTest}.tooltip.infoIcon` } />
            </Tooltip>
            }
        </Typography>
    );
};
