import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { PayoutCycleRun } from '~api/model/payout-cycle';
import api from '~api/payout-cycle';

import { HttpStatus } from '~constants/http';

import {
    FETCH_ESTIMATED_PAYOUT_CYCLE_RUNS,
    FetchEstimatedPayoutCycleRuns,
    setEstimatedPayoutCycleRuns,
    setEstimatedPayoutCycleRunsError,
    setEstimatedPayoutCycleRunsForbidden,
} from '~modules/estimatedPayoutCycleRuns';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchEstimatedPayoutCycleRuns (action: FetchEstimatedPayoutCycleRuns): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const payoutCycleId = action.meta.payoutCycleId;

    try {
        const { data }: AxiosResponse<PayoutCycleRun> = yield call(api.fetchPayoutCycleRunEstimation, payoutCycleId, accessToken);

        yield put(setEstimatedPayoutCycleRuns(payoutCycleId, data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setEstimatedPayoutCycleRunsForbidden(payoutCycleId));
        } else {
            yield put(setEstimatedPayoutCycleRunsError(payoutCycleId));
        }
    }
}

export default function * watchFetchEstimatedPayoutCycleRuns (): Generator<ForkEffect> {
    yield takeEvery(FETCH_ESTIMATED_PAYOUT_CYCLE_RUNS, fetchEstimatedPayoutCycleRuns);
}
