import { useSelector } from 'react-redux';

import { DashboardPayoutSummaryState } from '~modules/dashboardPayoutSummary/dashboardPayoutSummary';
import { AppState } from '~modules/index';

const root = (state: AppState): DashboardPayoutSummaryState => state.dashboardPayoutSummary;

export const useDashboardPayoutSummary = (): DashboardPayoutSummaryState =>
    useSelector(root);

export default {
    root,
};
