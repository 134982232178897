import { createAction, createReducer } from '@reduxjs/toolkit';

// type declaration
export const SET_ACTIVE_BUTTON = 'douglas/dgl-poms-ui/activeButton/SET';
export const RESET_ACTIVE_BUTTON = 'douglas/dgl-poms-ui/activeButton/RESET';

export type ActiveButtonName =
    | 'editCompensationDialog'
    | 'creatorUpdateLevelDialog'
    | 'creatorUpdatePayoutMethodDialog'
    | 'deleteCompensationDialog'
    | 'recalculatePayout'
    | 'setPayoutOnHoldOrResume'
    | 'setPayoutCycleRunOnHoldOrResume'
    | 'initTestData'
    | 'startTestPayout'
    | 'recalculateCreatorLevels'
    | 'recalculateDashboardKPI'
    | 'sendTestMails'
    | 'brandVideoViewAssignmentDialog'
    | 'calculatePayoutDate'
    | 'prefillVideoEvent'
    | 'removeAllTestCreatorsAndVideos'
    | 'createPayoutCorrection';

export type ActiveButtonStateType = {
    name: ActiveButtonName | null;
};

// actions
export const setActiveButton = createAction<ActiveButtonName>(SET_ACTIVE_BUTTON);

export const resetAllActiveButtons = createAction(RESET_ACTIVE_BUTTON);

// reducer
const defaultState: ActiveButtonStateType = {
    name: null,
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setActiveButton, (state, { payload }) => ({
            ...state,
            name: payload,
        }))
        .addCase(resetAllActiveButtons, (state) => ({
            ...state,
            name: null,
        }));
});
