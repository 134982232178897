import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import api from '~api/brand-video';
import { BrandVideo } from '~api/model/brand-video';

import { HttpStatus } from '~constants/http';

import { setBrandVideosError, setBrandVideosForbidden, SHOW_BRAND_VIDEO, ShowBrandVideo, updateBrandVideo } from '~modules/brandVideos';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * showBrandVideoSaga (action: ShowBrandVideo): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const brandVideo = action.payload;

    try {
        const { data }: AxiosResponse<BrandVideo> = yield call(api.showBrandVideo, brandVideo, accessToken);

        yield put(updateBrandVideo(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setBrandVideosForbidden());
        } else {
            yield put(setBrandVideosError());
        }
    }
}

export default function * watchShowBrandVideo (): Generator<ForkEffect> {
    yield takeEvery(SHOW_BRAND_VIDEO, showBrandVideoSaga);
}
