import { createAction, createReducer } from '@reduxjs/toolkit';

import { CashDashboardTileDetails } from '~api/model/dashboardAggregations';

// type declaration
export const FETCH_PAID_OUT_CASH_DASHBOARD_DETAILS = 'poms/paidOutCashDashboardDetails/FETCH';
export const SET_PAID_OUT_CASH_DASHBOARD_DETAILS = 'poms/paidOutCashDashboardDetails/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type PaidOutCashDashboardDetailsState = {
    dashboardDetails: CashDashboardTileDetails | null;
    fetchState: FetchState;
};

// actions
export const fetchPaidOutCashDashboardDetails = createAction(
    FETCH_PAID_OUT_CASH_DASHBOARD_DETAILS,
    (creatorId?: string | null) => ({
        payload: null,
        meta: {
            dataType: 'paidOutCash',
            creatorId,
        }
    }));

export const setPaidOutCashDashboardDetails = createAction(
    SET_PAID_OUT_CASH_DASHBOARD_DETAILS,
    (dashboardDetails: CashDashboardTileDetails | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            dashboardDetails,
            fetchState,
        }
    }));

export type FetchPaidOutCashDashboardDetailsAction = ReturnType<typeof fetchPaidOutCashDashboardDetails>;

export type SetPaidOutCashDashboardDetailsAction = ReturnType<typeof setPaidOutCashDashboardDetails>;

export const setPaidOutCashDashboardDetailsForbidden = (): SetPaidOutCashDashboardDetailsAction =>
    setPaidOutCashDashboardDetails(null, 'FORBIDDEN');

export const setPaidOutCashDashboardDetailsError = (): SetPaidOutCashDashboardDetailsAction =>
    setPaidOutCashDashboardDetails(null, 'ERROR');

export const resetPaidOutCashDashboardDetails = (): SetPaidOutCashDashboardDetailsAction =>
    setPaidOutCashDashboardDetails(null, 'INIT');

// reducer
const defaultState: PaidOutCashDashboardDetailsState = {
    dashboardDetails: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchPaidOutCashDashboardDetails, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setPaidOutCashDashboardDetails, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
