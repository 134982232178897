import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/creators';
import { Creator } from '~api/model/creator';

import { HttpStatus } from '~constants/http';

import { FETCH_CREATORS, setCreators, setCreatorsError, setCreatorsForbidden } from '~modules/creators';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreators (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<Creator[]> = yield call(api.fetchCreators, accessToken);

        yield put(setCreators(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorsForbidden());
        } else {
            yield put(setCreatorsError());
        }
    }
}

export default function * watchFetchCreators (): Generator<ForkEffect> {
    yield takeLatest(FETCH_CREATORS, fetchCreators);
}
