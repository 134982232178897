import { useSelector } from 'react-redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import { DashboardAggregations } from '~api/model/dashboardAggregations';

import selectors from './selectors';

export const FETCH_DASHBOARD_AGGREGATIONS = 'poms/dashboardAggregations/FETCH';
export const SET_DASHBOARD_AGGREGATIONS = 'poms/dashboardAggregations/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type DashboardAggregationsState = {
    data: DashboardAggregations | null;
    fetchState: FetchState;
}

export type DashboardAggregationsAction = ReturnType<typeof setDashboardAggregations>;

// implementation
export const defaultState: DashboardAggregationsState = {
    data: null,
    fetchState: 'INIT'
};

// actions
export const fetchDashboardAggregations = createAction(FETCH_DASHBOARD_AGGREGATIONS);

export const setDashboardAggregations = createAction(
    SET_DASHBOARD_AGGREGATIONS,
    (dashboardAggregations: DashboardAggregations | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            data: dashboardAggregations,
            fetchState,
        }
    })
);

export const setDashboardAggregationsForbidden = (): ReturnType<typeof setDashboardAggregations> => setDashboardAggregations(null, 'FORBIDDEN');

export const setDashboardAggregationsError = (): ReturnType<typeof setDashboardAggregations> => setDashboardAggregations(null, 'ERROR');

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setDashboardAggregations, (state, { payload }) => ({
            ...state,
            ...payload,
        }))
        .addCase(fetchDashboardAggregations, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }));
});

export const useDashboardAggregations = (): DashboardAggregationsState => useSelector(selectors.root);
export const useDashboardAggregationsData = (): DashboardAggregations | null => useSelector(selectors.data);
