import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/dashboard-aggregations';
import { VideosDashboardTileDetails } from '~api/model/dashboardAggregations';

import { HttpStatus } from '~constants/http';

import { getRangeFromFilter } from '~helper/range-filter';

import {
    selectors as dashboardDetailTileDateFilterSelectors,
    SET_DASHBOARD_DETAIL_TILE_DATE_FILTER,
    SetDashboardDetailTileDateFilterAction,
} from '~modules/dashboardDetailTileDateFilter';
import { selectors as oidcSelectors } from '~modules/oidc';
import {
    FETCH_VIDEOS_DASHBOARD_DETAILS,
    FetchVideosDashboardDetailsAction,
    setVideosDashboardDetails,
    setVideosDashboardDetailsError,
    setVideosDashboardDetailsForbidden,
} from '~modules/videosDashboardDetails';

export function * fetchVideosDashboardDetails (action: FetchVideosDashboardDetailsAction | SetDashboardDetailTileDateFilterAction): Generator<StrictEffect, void, any> {
    const { dataType, creatorId } = action.meta;

    if (action.type === SET_DASHBOARD_DETAIL_TILE_DATE_FILTER && dataType !== 'videos') {
        return;
    }

    const accessToken = yield select(oidcSelectors.accessToken);
    const rangeFilter = yield select(dashboardDetailTileDateFilterSelectors.root);
    const range = getRangeFromFilter(rangeFilter);

    try {
        const { data }: AxiosResponse<VideosDashboardTileDetails> = creatorId
            ? yield call(api.fetchVideosCreatorDashboardDetails, creatorId, range.from, range.to, accessToken)
            : yield call(api.fetchVideosDashboardDetails, range.from, range.to, accessToken);

        yield put(setVideosDashboardDetails(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setVideosDashboardDetailsForbidden());
        } else {
            yield put(setVideosDashboardDetailsError());
        }
    }
}

export default function * watchFetchVideoDashboardDetails (): Generator<ForkEffect> {
    yield takeLatest(FETCH_VIDEOS_DASHBOARD_DETAILS, fetchVideosDashboardDetails);
    yield takeLatest(SET_DASHBOARD_DETAIL_TILE_DATE_FILTER, fetchVideosDashboardDetails);
}
