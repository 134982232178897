import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorPayoutCycleRun } from '~api/model/payout-cycle';

// type declaration
export const FETCH_CREATOR_ESTIMATED_PAYOUT = 'poms/creatorEstimatedPayout/FETCH';
export const SET_CREATOR_ESTIMATED_PAYOUT = 'poms/creatorEstimatedPayout/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorEstimatedPayoutState = {
    estimation: CreatorPayoutCycleRun | null;
    fetchState: FetchState;
};

// actions
export const fetchCreatorEstimatedPayout = createAction<string>(FETCH_CREATOR_ESTIMATED_PAYOUT);

export const setCreatorEstimatedPayout = createAction(
    SET_CREATOR_ESTIMATED_PAYOUT,
    (estimation: CreatorPayoutCycleRun | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            estimation,
            fetchState,
        }
    }));

export type FetchCreatorEstimatedPayoutAction = ReturnType<typeof fetchCreatorEstimatedPayout>;

export type SetCreatorEstimatedPayoutAction = ReturnType<typeof setCreatorEstimatedPayout>;

export const setCreatorEstimatedPayoutForbidden = (): SetCreatorEstimatedPayoutAction =>
    setCreatorEstimatedPayout(null, 'FORBIDDEN');

export const setCreatorEstimatedPayoutError = (): SetCreatorEstimatedPayoutAction =>
    setCreatorEstimatedPayout(null, 'ERROR');

export const resetCreatorEstimatedPayout = (): SetCreatorEstimatedPayoutAction =>
    setCreatorEstimatedPayout(null, 'INIT');

// reducer
const defaultState: CreatorEstimatedPayoutState = {
    estimation: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreatorEstimatedPayout, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCreatorEstimatedPayout, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
