export enum Routes {
    ROOT = '/',
    OIDC_AUTH = '/auth',
    OIDC_LOGGED_OUT = '/loggedOut',
    LOGOUT = '/logout',
    OIDC_RENEW = '/authRenew',
    ADMINISTRATION = '/administration',
    COMPENSATION = '/compensation',
    BRAND_VIDEOS = '/brandVideos',
    PAYOUT_CYCLES_OVERVIEW = '/payoutCycles',
    PAYOUT_CYCLES_RUN_DETAILS = '/payoutCycles/run/',
    CREATORS = '/creators',
    CREATOR_PROFILE = '/creators/',
}
