import { takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect } from 'redux-saga/effects';

import api from '~api/administration';
import { AdministrativeActions } from '~api/model/administrativeActions';

import { HttpStatus } from '~constants/http';

import { FETCH_ADMINISTRATIVE_ACTIONS, setAdministrativeActions, setAdministrativeActionsError, setAdministrativeActionsForbidden, } from '~modules/administrativeActions';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchAdministrativeActions (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<AdministrativeActions> = yield call(api.fetchAdministrativeActions, accessToken);

        yield put(setAdministrativeActions(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setAdministrativeActionsForbidden());
        } else {
            yield put(setAdministrativeActionsError());
        }
    }
}

export default function * watchFetchAdministrativeActions (): Generator<ForkEffect> {
    yield takeLatest(FETCH_ADMINISTRATIVE_ACTIONS, fetchAdministrativeActions);
}
