import axios, { AxiosPromise } from 'axios';

import { addAuthorizationHeader, endpoint } from '~api/_helper';
import { BrandVideo } from '~api/model/brand-video';

import { Endpoints } from '~constants/endpoints';

const fetchBrandVideos = (accessToken: string): AxiosPromise<Array<BrandVideo>> => {
    return axios.get(Endpoints.BRAND_VIDEOS_OVERVIEW, addAuthorizationHeader(accessToken));
};

const assignBrandVideoViews = (brandVideo: BrandVideo, accessToken: string): AxiosPromise<void> => {
    return axios.post(
        endpoint(Endpoints.ASSIGN_BRAND_VIDEO_VIEWS, brandVideo.id),
        brandVideo.assignedCreators,
        addAuthorizationHeader(accessToken)
    );
};

const hideBrandVideo = (brandVideo: BrandVideo, accessToken: string): AxiosPromise<BrandVideo> => {
    return axios.post(
        endpoint(Endpoints.HIDE_BRAND_VIDEO, brandVideo.id),
        {},
        addAuthorizationHeader(accessToken)
    );
};

const showBrandVideo = (brandVideo: BrandVideo, accessToken: string): AxiosPromise<BrandVideo> => {
    return axios.post(
        endpoint(Endpoints.SHOW_BRAND_VIDEO, brandVideo.id),
        {},
        addAuthorizationHeader(accessToken)
    );
};

export default {
    fetchBrandVideos,
    assignBrandVideoViews,
    hideBrandVideo,
    showBrandVideo,
};
