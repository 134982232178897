import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';
import { fetchPayoutCycleRunsProcessStepsCalculated } from '~modules/payoutCycleRunsProcessStepsCalculated';
import { fetchPayoutCycleRunsProcessStepsPublished } from '~modules/payoutCycleRunsProcessStepsPublished';

export const PUBLISH_CALCULATED_PAYOUT_CYCLE_RUN = 'poms/payout-cycle-run/start';

export const publishCalculatedPayoutCycleRun = createAction<string>(PUBLISH_CALCULATED_PAYOUT_CYCLE_RUN);

export function * publishCalculatedPayoutCycleRunSaga (action: ReturnType<typeof publishCalculatedPayoutCycleRun>): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield call(api.publishCalculatedPayoutCycleRun, action.payload, accessToken);
        yield put(setMessageAtPosition(message('administration.payout.overview.calculated.success'), 'administrationPayoutProcessOverviewBottom', 'success'));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('administration.payout.overview.calculated.forbidden'), 'administrationPayoutProcessOverviewBottom', 'error'));
        } else {
            yield put(setMessageAtPosition(message('administration.payout.overview.calculated.error'), 'administrationPayoutProcessOverviewBottom', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchPayoutCycleRunsProcessStepsCalculated());
        yield put(fetchPayoutCycleRunsProcessStepsPublished());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchPublishCalculatedPayoutCycleRun (): Generator<ForkEffect> {
    yield takeLatest(PUBLISH_CALCULATED_PAYOUT_CYCLE_RUN, publishCalculatedPayoutCycleRunSaga);
}
