import type {} from '@mui/lab/themeAugmentation';
import { Components, createTheme } from '@mui/material';

import colors from './colors.module.scss';

const components: Components = {
    MuiButton: {
        styleOverrides: {
            containedPrimary: {
                color: colors.white,
            },
            endIcon: {
                marginLeft: '0.3em',
            },
        },
    },
    MuiTypography: {
        styleOverrides: {
            h1: {
                fontSize: '3rem',
                padding: '0.5em 0',
            },
            h2: {
                fontSize: '2.5rem',
                padding: '0.5em 0',
            },
            h3: {
                fontSize: '2rem',
                padding: '0.5em 0',
            },
            h4: {
                fontSize: '1.5rem',
                padding: '0.5em 0',
            },
            h5: {
                fontSize: '1.25rem',
                padding: '0.5em 0',
            },
            h6: {
                fontSize: '1rem',
            },
        },
    },
    MuiSvgIcon: {
        styleOverrides: {
            fontSizeLarge: {
                fontSize: '4rem',
            },
        },
    },
    MuiAppBar: {
        styleOverrides: {
            colorPrimary: {
                backgroundColor: colors.brandBright,
            },
        },
    },
    MuiFilledInput: {
        styleOverrides: {
            root: {
                backgroundColor: colors.white,
                '&$focused': {
                    backgroundColor: colors.white,
                },
                '&:hover': {
                    backgroundColor: colors.white,
                },
            },
            underline: {
                '&:after': {
                    borderBottom: `3px solid ${colors.brandDark} !important`,
                },
                '&:before': {
                    borderBottom: `3px solid ${colors.brandBright} !important`,
                },
            },
        },
    },
    MuiTimeline: {
        styleOverrides: {
            root: {
                margin: 0,
                padding: 0,
            },
        },
    },
    MuiTimelineItem: {
        styleOverrides: {
            root: {
                '&:before': {
                    display: 'none',
                },
            },
        },
    },
    MuiTimelineDot: {
        styleOverrides: {
            root: {
                boxShadow: 'none',
                marginBottom: 0,
                marginTop: 0,
            },
        },
    },
    MuiTableRow: {
        styleOverrides: {
            root: {
                '&:hover': {
                    backgroundColor: colors.brandMedium,
                },
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            head: {
                fontWeight: 600,
            },
            body: {
                cursor: 'pointer',
            },
        },
    },
    MuiTimelineConnector: {
        styleOverrides: {
            root: {
                width: '0.4em',
                backgroundColor: colors.highlightRoseBright,
            },
        },
    },
    MuiListItem: {
        styleOverrides: {
            root: {
                '&.Mui-selected': {
                    backgroundColor: colors.highlightRoseBright,
                },
                '&.Mui-selected:hover': {
                    backgroundColor: colors.grayLight,
                },
            },
        },
    },
    // MuiPickersDay: {
    //     styleOverrides: {
    //         root: {
    //             '&.Mui-selected': {
    //                 backgroundColor: `${colors.brandMedium} !important`,
    //             },
    //             '&.Mui-selected:hover': {
    //                 backgroundColor: `${colors.grayLight} !important`,
    //             },
    //         },
    //     },
    // },
};

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: colors.brandDark,
        },
        secondary: {
            main: colors.highlightPink,
        },
        error: {
            main: colors.feedbackError,
        },
        warning: {
            main: colors.feedbackWarning,
        },
        info: {
            main: colors.feedbackInfo,
        },
        success: {
            main: colors.feedbackSuccess,
        },
        background: {
            default: colors.white,
        },
    },
    components,
});

export default theme;
