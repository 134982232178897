import { useSelector } from 'react-redux';
import { Action } from 'redux';

import { PayoutCycleRunDetails } from '~api/model/payout-cycle';

import selectors from './selectors';

// type declaration
export const FETCH_PAYOUT_CYCLE_RUN_DETAILS = 'poms/payout-cycle-run-details/FETCH';
export const SET_PAYOUT_CYCLE_RUN_DETAILS = 'poms/payout-cycle-run-details/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'NOT_FOUND' | 'ERROR' | 'FORBIDDEN';

export type PayoutCycleRunDetailsState = {
    data: PayoutCycleRunDetails | null;
    fetchState: FetchState;
}

export interface FetchPayoutCycleRunDetails extends Action<typeof FETCH_PAYOUT_CYCLE_RUN_DETAILS> {
    payload: string;
}

export interface SetPayoutCycleRunDetails extends Action<typeof SET_PAYOUT_CYCLE_RUN_DETAILS> {
    payload: PayoutCycleRunDetailsState;
}

export type PayoutCycleRunDetailsAction = FetchPayoutCycleRunDetails | SetPayoutCycleRunDetails;

// implementation
const defaultState: PayoutCycleRunDetailsState = {
    data: null,
    fetchState: 'INIT'
};

export default function payoutCycleRunDetails (state: PayoutCycleRunDetailsState = defaultState, action: PayoutCycleRunDetailsAction): PayoutCycleRunDetailsState {
    switch (action.type) {
        case FETCH_PAYOUT_CYCLE_RUN_DETAILS:
            return {
                ...state,
                fetchState: 'FETCHING'
            };
        case SET_PAYOUT_CYCLE_RUN_DETAILS:
            return action.payload;
        default:
            return state;
    }
}

// actions
export const fetchPayoutCycleRunDetails = (payoutCycleRunId: string): FetchPayoutCycleRunDetails => ({
    type: FETCH_PAYOUT_CYCLE_RUN_DETAILS,
    payload: payoutCycleRunId,
});

export const setPayoutCycleRunDetails = (payoutCycleRunDetails: PayoutCycleRunDetails): SetPayoutCycleRunDetails => ({
    type: SET_PAYOUT_CYCLE_RUN_DETAILS,
    payload: {
        data: payoutCycleRunDetails,
        fetchState: 'SUCCESS',
    },
});

export const setPayoutCycleRunDetailsForbidden = (): SetPayoutCycleRunDetails => ({
    type: SET_PAYOUT_CYCLE_RUN_DETAILS,
    payload: {
        data: null,
        fetchState: 'FORBIDDEN',
    },
});

export const setPayoutCycleRunDetailsError = (): SetPayoutCycleRunDetails => ({
    type: SET_PAYOUT_CYCLE_RUN_DETAILS,
    payload: {
        data: null,
        fetchState: 'ERROR',
    },
});

export const setPayoutCycleRunDetailsNotFound = (): SetPayoutCycleRunDetails => ({
    type: SET_PAYOUT_CYCLE_RUN_DETAILS,
    payload: {
        data: null,
        fetchState: 'NOT_FOUND',
    },
});

// selectors
export const usePayoutCycleRunDetails = (): PayoutCycleRunDetailsState => useSelector(selectors.root);
