import { useSelector } from 'react-redux';
import { Action } from 'redux';

import { PayoutCycleRunCreatorOverview } from '~api/model/payout-cycle';

import selectors from './selectors';

// type declaration
export const FETCH_PAYOUT_CYCLE_RUN_CREATORS = 'poms/payout-cycle-run-creators/FETCH';
export const SET_PAYOUT_CYCLE_RUN_CREATORS = 'poms/payout-cycle-run-creators/SET';
export const RESET_PAYOUT_CYCLE_RUN_CREATORS = 'poms/payout-cycle-run-creators/RESET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type PayoutCycleRunCreatorsState = {
    data: PayoutCycleRunCreatorOverview | null;
    fetchState: FetchState;
}

export interface FetchPayoutCycleRunCreators extends Action<typeof FETCH_PAYOUT_CYCLE_RUN_CREATORS> {
    payload: string;
}

export interface SetPayoutCycleRunCreators extends Action<typeof SET_PAYOUT_CYCLE_RUN_CREATORS> {
    payload: PayoutCycleRunCreatorsState;
}

export type ResetPayoutCycleRunCreators = Action<typeof RESET_PAYOUT_CYCLE_RUN_CREATORS>;

export type PayoutCycleRunCreatorsAction = FetchPayoutCycleRunCreators | SetPayoutCycleRunCreators | ResetPayoutCycleRunCreators;

// implementation
const defaultState: PayoutCycleRunCreatorsState = {
    data: null,
    fetchState: 'INIT'
};

export default function payoutCycleRunCreators (state: PayoutCycleRunCreatorsState = defaultState, action: PayoutCycleRunCreatorsAction): PayoutCycleRunCreatorsState {
    switch (action.type) {
        case FETCH_PAYOUT_CYCLE_RUN_CREATORS:
            return {
                ...state,
                fetchState: 'FETCHING'
            };
        case SET_PAYOUT_CYCLE_RUN_CREATORS:
            return action.payload;
        case RESET_PAYOUT_CYCLE_RUN_CREATORS:
            return defaultState;
        default:
            return state;
    }
}

// actions
export const fetchPayoutCycleRunCreators = (payoutCycleRunId: string): FetchPayoutCycleRunCreators => ({
    type: FETCH_PAYOUT_CYCLE_RUN_CREATORS,
    payload: payoutCycleRunId,
});

export const setPayoutCycleRunCreators = (payoutCycleRunCreators: PayoutCycleRunCreatorOverview): SetPayoutCycleRunCreators => ({
    type: SET_PAYOUT_CYCLE_RUN_CREATORS,
    payload: {
        data: payoutCycleRunCreators,
        fetchState: 'SUCCESS',
    },
});

export const setPayoutCycleRunCreatorsForbidden = (): SetPayoutCycleRunCreators => ({
    type: SET_PAYOUT_CYCLE_RUN_CREATORS,
    payload: {
        data: null,
        fetchState: 'FORBIDDEN',
    },
});

export const setPayoutCycleRunCreatorsError = (): SetPayoutCycleRunCreators => ({
    type: SET_PAYOUT_CYCLE_RUN_CREATORS,
    payload: {
        data: null,
        fetchState: 'ERROR',
    },
});

export const resetPayoutCycleForCreator = (): ResetPayoutCycleRunCreators => ({
    type: RESET_PAYOUT_CYCLE_RUN_CREATORS,
});

// selectors
export const usePayoutCycleRunCreators = (): PayoutCycleRunCreatorsState => useSelector(selectors.root);
export const usePayoutCycleRunCreatorsData = (): PayoutCycleRunCreatorOverview | null => useSelector(selectors.data);
