import { useSelector } from 'react-redux';
import { Action } from 'redux';

import { FeatureToggleName, FeatureToggleResponse } from '~api/model/unleash';

import { AppState } from '~modules/index';

// type declaration
export const FETCH_FEATURE_TOGGLES = 'poms/featureToggles/FETCH_FEATURE_TOGGLES';
export const SET_FEATURE_TOGGLES = 'poms/featureToggles/SET_FEATURE_TOGGLES';

export type FeatureTogglesState = {
    enabledFeatures: FeatureToggleName[];
};

export type FetchFeatureTogglesAction = Action<typeof FETCH_FEATURE_TOGGLES>;

export interface SetFeatureTogglesAction extends Action<typeof SET_FEATURE_TOGGLES> {
    payload: FeatureToggleResponse;
}

// implementation
const defaultState: FeatureTogglesState = {
    enabledFeatures: [],
};

export default function featureToggles (state: FeatureTogglesState = defaultState, action: SetFeatureTogglesAction): FeatureTogglesState {
    switch (action.type) {
        case SET_FEATURE_TOGGLES: {
            const enabledFeatures = action.payload.features
                .filter((feature) => feature.enabled)
                .map((feature) => feature.name);

            return { enabledFeatures };
        }
        default:
            return state;
    }
}

// actions
export const fetchFeatureToggles = (): FetchFeatureTogglesAction => ({
    type: FETCH_FEATURE_TOGGLES,
});

export const setFeatureToggles = (featureToggleResponse: FeatureToggleResponse): SetFeatureTogglesAction => ({
    type: SET_FEATURE_TOGGLES,
    payload: featureToggleResponse,
});

export const useFeatureToggles = (): FeatureTogglesState => useSelector((state: AppState) => state.featureToggles);
