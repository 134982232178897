import { useSelector } from 'react-redux';
import { createAction, createReducer } from '@reduxjs/toolkit';

import selectors from './selectors';

export const SET_DASHBOARD_DETAIL_TILE_DATE_FILTER = 'poms/dashboardDetailTileDateFilter';

export type DashboardDetailTileDateFilterPreset = '7' | '30' | '90' | '365';

export type DashboardDataType = 'creator' | 'videos' | 'billableViews' | 'compensableViews' | 'collectedBeautyPoints' | 'paidOutBeautyPoints' | 'collectedCash' | 'paidOutCash';

export type DashboardDetailTileDateFilterCustomRange = {
    from: string;
    to: string;
};

export type DashboardDetailTileDateFilterState = {
    preset: DashboardDetailTileDateFilterPreset | null;
    customRange: DashboardDetailTileDateFilterCustomRange | null;
};

export type SetDashboardDetailTileDateFilterAction = ReturnType<typeof setDashboardDetailTileDateFilterPreset>;

export const defaultState: DashboardDetailTileDateFilterState = {
    preset: '7',
    customRange: null,
};

export const setDashboardDetailTileDateFilterPreset = createAction(
    SET_DASHBOARD_DETAIL_TILE_DATE_FILTER,
    (preset: DashboardDetailTileDateFilterPreset, dataType: DashboardDataType, creatorId?: string | null) => ({
        payload: {
            preset,
            customRange: null,
        },
        meta: {
            dataType,
            creatorId,
        },
    })
);

export const setDashboardDetailTileDateFilterCustomRange = createAction(
    SET_DASHBOARD_DETAIL_TILE_DATE_FILTER,
    (customRange: DashboardDetailTileDateFilterCustomRange, dataType: DashboardDataType, creatorId?: string | null) => ({
        payload: {
            customRange,
            preset: null,
        },
        meta: {
            dataType,
            creatorId,
        },
    })
);

export const resetDashboardDetailTileDateFilter = createAction(
    SET_DASHBOARD_DETAIL_TILE_DATE_FILTER,
    (dataType: DashboardDataType, creatorId?: string | null) => ({
        payload: defaultState,
        meta: {
            dataType,
            creatorId,
        },
    })
);

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setDashboardDetailTileDateFilterPreset, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});

export const useDashboardDetailTileDateFilter = (): DashboardDetailTileDateFilterState => useSelector(selectors.root);
