import { useSelector } from 'react-redux';
import { Action } from 'redux';

import { CreatorFilter, CreatorFilterOptions, CreatorSorting, CreatorsPerPage } from '~api/model/creator';

import selectors from './selectors';

// type declaration
export const SET_CREATORS_FILTER_OPTION = 'poms/creator-filter/SET';

export type CreatorFilterState = CreatorFilterOptions;

export type CreatorSource = 'creatorOverview' | 'payoutCycleRun' | 'brandVideoAssignment' | 'no-operation' | 'unknown';

export interface SetFilterAction extends Action<typeof SET_CREATORS_FILTER_OPTION> {
    payload: {
        filter: Partial<CreatorFilter>;
        page: 0;
    };
    meta: {
        source: CreatorSource;
        id?: string | null;
    };
}

export interface ResetFilterAction extends Action<typeof SET_CREATORS_FILTER_OPTION> {
    payload: {
        filter: null;
        page: 0;
    };
    meta: {
        source: CreatorSource;
        id?: string | null;
    };
}

export interface SetSortingAction extends Action<typeof SET_CREATORS_FILTER_OPTION> {
    payload: {
        sort: CreatorSorting;
        page: 0;
    };
    meta: {
        source: CreatorSource;
        id?: string | null;
    };
}

export interface ResetSortingAction extends Action<typeof SET_CREATORS_FILTER_OPTION> {
    payload: {
        sort: null;
        page: 0;
    };
    meta: {
        source: CreatorSource;
        id?: string | null;
    };
}

export interface SetPageAction extends Action<typeof SET_CREATORS_FILTER_OPTION> {
    payload: {
        page: number;
    };
    meta: {
        source: CreatorSource;
        id?: string | null;
    };
}

export interface ResetPageAction extends Action<typeof SET_CREATORS_FILTER_OPTION> {
    payload: {
        page: 0;
    };
    meta: {
        source: CreatorSource;
        id?: string | null;
    };
}

export interface SetCreatorsPerPageAction extends Action<typeof SET_CREATORS_FILTER_OPTION> {
    payload: {
        resultsPerPage: CreatorsPerPage;
        page: 0;
    };
    meta: {
        source: CreatorSource;
        id?: string | null;
    };
}

export interface ResetCreatorsPerPageAction extends Action<typeof SET_CREATORS_FILTER_OPTION> {
    payload: {
        resultsPerPage: 10;
        page: 0;
    };
    meta: {
        source: CreatorSource;
        id?: string | null;
    };
}

export interface ResetAllAction extends Action<typeof SET_CREATORS_FILTER_OPTION> {
    payload: CreatorFilterState;
    meta: {
        source: CreatorSource;
        id?: string | null;
    };
}

export type CreatorFilterAction = SetFilterAction
| ResetFilterAction
| SetSortingAction
| ResetSortingAction
| SetPageAction
| ResetPageAction
| SetCreatorsPerPageAction
| ResetCreatorsPerPageAction
| ResetAllAction;

// implementation
const defaultState: CreatorFilterState = {
    filter: null,
    sort: null,
    page: 0,
    resultsPerPage: 10,
};

export default function creatorFilter (state: CreatorFilterState = defaultState, action: CreatorFilterAction): CreatorFilterState {
    switch (action.type) {
        case SET_CREATORS_FILTER_OPTION:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

// actions
export const setFilterForCreator = (filter: Partial<CreatorFilter>, source: CreatorSource, id?: string | null): SetFilterAction => ({
    type: SET_CREATORS_FILTER_OPTION,
    payload: {
        filter,
        page: 0,
    },
    meta: {
        source,
        id
    }
});

export const resetFilterForCreator = (source: CreatorSource, id?: string | null): ResetFilterAction => ({
    type: SET_CREATORS_FILTER_OPTION,
    payload: {
        filter: null,
        page: 0,
    },
    meta: {
        source,
        id
    }
});

export const setCreatorSorting = (sort: CreatorSorting, source: CreatorSource, id?: string | null): SetSortingAction => ({
    type: SET_CREATORS_FILTER_OPTION,
    payload: {
        sort,
        page: 0,
    },
    meta: {
        source,
        id
    }
});

export const resetCreatorSorting = (source: CreatorSource, id?: string | null): ResetSortingAction => ({
    type: SET_CREATORS_FILTER_OPTION,
    payload: {
        sort: null,
        page: 0,
    },
    meta: {
        source,
        id
    }
});

export const setCreatorsPage = (page: number, source: CreatorSource, id?: string | null): SetPageAction => ({
    type: SET_CREATORS_FILTER_OPTION,
    payload: {
        page,
    },
    meta: {
        source,
        id
    }
});

export const resetCreatorsPage = (source: CreatorSource, id?: string | null): SetPageAction => ({
    type: SET_CREATORS_FILTER_OPTION,
    payload: {
        page: 0,
    },
    meta: {
        source,
        id
    }
});

export const setCreatorsPerPage = (resultsPerPage: CreatorsPerPage, source: CreatorSource, id?: string | null): SetCreatorsPerPageAction => ({
    type: SET_CREATORS_FILTER_OPTION,
    payload: {
        resultsPerPage,
        page: 0,
    },
    meta: {
        source,
        id
    }
});

export const resetCreatorsPerPage = (source: CreatorSource, id?: string | null): ResetCreatorsPerPageAction => ({
    type: SET_CREATORS_FILTER_OPTION,
    payload: {
        resultsPerPage: 10,
        page: 0,
    },
    meta: {
        source,
        id
    }
});

export const resetAllCreatorFilters = (source: CreatorSource, id?: string | null): ResetAllAction => ({
    type: SET_CREATORS_FILTER_OPTION,
    payload: defaultState,
    meta: {
        source,
        id
    }
});

// selectors
export const useCreatorFilter = (): Partial<CreatorFilter> => useSelector(selectors.filter);
export const useCreatorSorting = (): CreatorSorting => useSelector(selectors.sorting);
export const useCreatorTablePage = (): number => useSelector(selectors.page);
export const useCreatorsPerPage = (): number => useSelector(selectors.resultsPerPage);
