import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLeading } from 'redux-saga/effects';

import api from '~api/creators';
import { CreatorVideosOverview } from '~api/model/creator';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREATOR_VIDEOS_OVERVIEW,
    FetchCreatorVideosOverviewAction,
    setCreatorVideosOverview,
    setCreatorVideosOverviewError,
    setCreatorVideosOverviewForbidden,
} from '~modules/creatorVideosOverview/creatorVideosOverview';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorVideosOverview (action: FetchCreatorVideosOverviewAction): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const creatorId = action.payload;

    try {
        const { data }: AxiosResponse<CreatorVideosOverview> = yield call(api.fetchCreatorContentOverview, creatorId, accessToken);

        yield put(setCreatorVideosOverview(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorVideosOverviewForbidden());
        } else {
            yield put(setCreatorVideosOverviewError());
        }
    }
}

export default function * watchFetchCreatorVideosOverview () : Generator<ForkEffect> {
    yield takeLeading(FETCH_CREATOR_VIDEOS_OVERVIEW, fetchCreatorVideosOverview);
}
