import { useEffect, useState } from 'react';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { determineLocaleForDateFns, setDateFnLocale } from '~helper/_common';
import { isBrowser } from '~helper/_environment';

import rootReducer from '~modules/index';

import rootSaga from '~sagas/index';

const createReduxStore = (): Promise<Store> => {
    const composeEnhancers = (isBrowser() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    const sagaMiddleware = createSagaMiddleware();

    const reduxStore = createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(sagaMiddleware)));

    sagaMiddleware.run(rootSaga);

    // set global moment config
    if (isBrowser()) {
        return new Promise((resolve) => {
            const locale = determineLocaleForDateFns(window.navigator.language);

            import(
                /* webpackMode: "lazy", webpackChunkName: "df-[index]", webpackExclude: /_lib/ */
                `date-fns/locale/${locale}/index.js`
            )
                .then(setDateFnLocale)
                .finally(() => resolve(reduxStore));
        });
    }

    return Promise.resolve(reduxStore);
};

export const useStore = (): Store | null => {
    const [ store, setStore ] = useState<Store | null>(null);

    useEffect(() => {
        createReduxStore().then(setStore);
    }, []);

    return store;
};
