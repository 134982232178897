import { useSelector } from 'react-redux';
import { reducer, UserState } from 'redux-oidc';

import selectors from './selectors';

export default reducer;

export const useOIDC = (): UserState => useSelector(selectors.root);

export const useAccessToken = (): string => useSelector(selectors.accessToken);
