import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorVideosFilter, CreatorVideosOverview, CreatorVideosPaginationOptions, CreatorVideosPerPage, CreatorVideosSorting } from '~api/model/creator';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

// type declaration
export const FETCH_CREATOR_VIDEOS_OVERVIEW = 'poms/creatorVideosOverview/FETCH';
export const SET_CREATOR_VIDEOS_OVERVIEW = 'poms/creatorVideosOverview/SET';
export const SET_CREATOR_VIDEOS_PAGINATION = 'poms/creatorVideosPagination/SET';

export type CreatorVideoPagination = {
    shows: CreatorVideosPaginationOptions;
    clips: CreatorVideosPaginationOptions;
};

export type CreatorVideoOverviewState = {
    videosOverview: CreatorVideosOverview | null;
    fetchState: FetchState;
    pagination: CreatorVideoPagination;
};

export type CreatorVideosPaginationType = keyof CreatorVideoOverviewState['pagination'];

// actions
export const fetchCreatorVideosOverview = createAction<string>(FETCH_CREATOR_VIDEOS_OVERVIEW);

export const setCreatorVideosOverview = createAction(
    SET_CREATOR_VIDEOS_OVERVIEW,
    (videosOverview: CreatorVideosOverview | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            videosOverview,
            fetchState,
        }
    }));

export type FetchCreatorVideosOverviewAction = ReturnType<typeof fetchCreatorVideosOverview>;

export type SetCreatorVideosOverviewAction = ReturnType<typeof setCreatorVideosOverview>;

export const setCreatorVideosOverviewForbidden = (): SetCreatorVideosOverviewAction =>
    setCreatorVideosOverview(null, 'FORBIDDEN');

export const setCreatorVideosOverviewError = (): SetCreatorVideosOverviewAction =>
    setCreatorVideosOverview(null, 'ERROR');

export const resetCreatorVideosOverview = (): SetCreatorVideosOverviewAction =>
    setCreatorVideosOverview(null, 'INIT');

type SetCreatorVideosPagination = {
    paginationType: CreatorVideosPaginationType;
    pagination: Partial<CreatorVideosPaginationOptions>;
}

export const setCreatorVideosPagination = createAction<SetCreatorVideosPagination>(SET_CREATOR_VIDEOS_PAGINATION);

export type SetCreatorVideosPaginationAction = ReturnType<typeof setCreatorVideosPagination>;

export const setCreatorVideosFilter = (paginationType: CreatorVideosPaginationType, filter: Partial<CreatorVideosFilter>): SetCreatorVideosPaginationAction =>
    setCreatorVideosPagination({ paginationType, pagination: { filter, page: 0 } });

export const setCreatorVideosPage = (paginationType: CreatorVideosPaginationType, page: number): SetCreatorVideosPaginationAction =>
    setCreatorVideosPagination({ paginationType, pagination: { page } });

export const setCreatorVideosResultsPerPage = (paginationType: CreatorVideosPaginationType, resultsPerPage: CreatorVideosPerPage): SetCreatorVideosPaginationAction =>
    setCreatorVideosPagination({ paginationType, pagination: { resultsPerPage } });

export const setCreatorVideosSorting = (paginationType: CreatorVideosPaginationType, sort: CreatorVideosSorting): SetCreatorVideosPaginationAction =>
    setCreatorVideosPagination({ paginationType, pagination: { sort } });

export const resetCreatorVideosSorting = (paginationType: CreatorVideosPaginationType): SetCreatorVideosPaginationAction =>
    setCreatorVideosPagination({ paginationType, pagination: { sort: null } });

// reducer
const defaultPagination: CreatorVideosPaginationOptions = {
    filter: null,
    sort: { by: 'creationTime', direction: 'DESC' },
    resultsPerPage: 10,
    page: 0,
};

const defaultState: CreatorVideoOverviewState = {
    videosOverview: null,
    fetchState: 'INIT',
    pagination: {
        shows: { ...defaultPagination },
        clips: { ...defaultPagination },
    }
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreatorVideosOverview, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCreatorVideosOverview, (state, { payload }) => ({
            ...state,
            ...payload,
        }))
        .addCase(setCreatorVideosPagination, (state, { payload }) => {
            const { paginationType, pagination } = payload;

            state.pagination[paginationType] = {
                ...state.pagination[paginationType],
                ...pagination,
            };
        });
});
