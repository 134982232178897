import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CreatorChangesState } from './creatorChanges';

const root = (state: AppState): CreatorChangesState => state.creatorChanges;

export const useCreatorChanges = (): CreatorChangesState => useSelector(root);

export default {
    root,
};
