import { CircularProgress } from '@mui/material';

import { FullscreenNotification } from '~components/templates/FullscreenNotification';

export const LoadingPage: React.FC = () => <FullscreenNotification
    data-test="app.loadingNotification"
    icon={
        <CircularProgress
            size={ 100 }
            thickness={ 1 }
            disableShrink
            data-test="app.loadingNotification.spinner" />
    } />;
