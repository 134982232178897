import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CompensationOverviewState } from './compensationOverview';

const root = (state: AppState): CompensationOverviewState => state.compensationOverview;

export const useCompensationOverview = (): CompensationOverviewState => useSelector(root);

export default {
    root,
};
