import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CreditNotesWithProcessingErrorState } from './creditNotesWithProcessingError';

const root = (state: AppState): CreditNotesWithProcessingErrorState => state.creditNotesWithProcessingError;

export const useCreditNotesWithProcessingError = (): CreditNotesWithProcessingErrorState => useSelector(root);

export default {
    root,
};
