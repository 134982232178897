import { createAction, createReducer } from '@reduxjs/toolkit';

import { ViewsDashboardTileDetails } from '~api/model/dashboardAggregations';

// type declaration
export const FETCH_COMPENSABLE_VIEWS_DASHBOARD_DETAILS = 'poms/compensableViewsDashboardDetails/FETCH';
export const SET_COMPENSABLE_VIEWS_DASHBOARD_DETAILS = 'poms/compensableViewsDashboardDetails/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CompensableViewsDashboardDetailsState = {
    dashboardDetails: ViewsDashboardTileDetails | null;
    fetchState: FetchState;
};

// actions
export const fetchCompensableViewsDashboardDetails = createAction(
    FETCH_COMPENSABLE_VIEWS_DASHBOARD_DETAILS,
    (creatorId?: string | null) => ({
        payload: null,
        meta: {
            dataType: 'compensableViews',
            creatorId,
        }
    }));

export const setCompensableViewsDashboardDetails = createAction(
    SET_COMPENSABLE_VIEWS_DASHBOARD_DETAILS,
    (dashboardDetails: ViewsDashboardTileDetails | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            dashboardDetails,
            fetchState,
        }
    }));

export type FetchCompensableViewsDashboardDetailsAction = ReturnType<typeof fetchCompensableViewsDashboardDetails>;

export type SetCompensableViewsDashboardDetailsAction = ReturnType<typeof setCompensableViewsDashboardDetails>;

export const setCompensableViewsDashboardDetailsForbidden = (): SetCompensableViewsDashboardDetailsAction =>
    setCompensableViewsDashboardDetails(null, 'FORBIDDEN');

export const setCompensableViewsDashboardDetailsError = (): SetCompensableViewsDashboardDetailsAction =>
    setCompensableViewsDashboardDetails(null, 'ERROR');

export const resetCompensableViewsDashboardDetails = (): SetCompensableViewsDashboardDetailsAction =>
    setCompensableViewsDashboardDetails(null, 'INIT');

// reducer
const defaultState: CompensableViewsDashboardDetailsState = {
    dashboardDetails: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchCompensableViewsDashboardDetails, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCompensableViewsDashboardDetails, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
