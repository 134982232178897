import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';
import { PayoutCycleRunsProcessStepCalculatedState } from '~modules/payoutCycleRunsProcessStepsCalculated/payoutCycleRunsProcessStepsCalculated';

const root = (state: AppState): PayoutCycleRunsProcessStepCalculatedState => state.payoutCycleRunsProcessStepsCalculated;

export const usePayoutCycleRunsProcessStepsCalculated = (): PayoutCycleRunsProcessStepCalculatedState => useSelector(root);

export default {
    root,
};
