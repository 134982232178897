import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';
import { PayoutCycleRunsProcessStepPublishedState } from '~modules/payoutCycleRunsProcessStepsPublished/payoutCycleRunsProcessStepsPublished';

const root = (state: AppState): PayoutCycleRunsProcessStepPublishedState => state.payoutCycleRunsProcessStepsPublished;

export const usePayoutCycleRunsProcessStepsPublished = (): PayoutCycleRunsProcessStepPublishedState => useSelector(root);

export default {
    root,
};
