import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import { PayoutCycleOverview } from '~api/model/payout-cycle';
import api from '~api/payout-cycle';

import { HttpStatus } from '~constants/http';

import { selectors as oidcSelectors } from '~modules/oidc';
import { FETCH_PAYOUT_CYCLES, setPayoutCycles, setPayoutCyclesError, setPayoutCyclesForbidden } from '~modules/payoutCycles';

export function * fetchPayoutCycles (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<PayoutCycleOverview> = yield call(api.fetchPayoutCycles, accessToken);

        yield put(setPayoutCycles(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPayoutCyclesForbidden());
        } else {
            yield put(setPayoutCyclesError());
        }
    }
}

export default function * watchFetchPayoutCycles (): Generator<ForkEffect> {
    yield takeLatest(FETCH_PAYOUT_CYCLES, fetchPayoutCycles);
}
