import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CreatorCoreInformationState } from './creatorCoreInformation';

const root = (state: AppState): CreatorCoreInformationState => state.creatorCoreInformation;

export const useCreatorCoreInformation = (): CreatorCoreInformationState => useSelector(root);

export default {
    root,
};
