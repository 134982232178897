import { createAction, createReducer } from '@reduxjs/toolkit';

import { BrandVideosFilter, BrandVideosFilterOptions, BrandVideosPerPage, BrandVideosSorting } from '~api/model/brand-video';

export const SET_BRAND_VIDEOS_FILTER = 'poms/brandVideosFilter/SET_FILTER';
export const SET_BRAND_VIDEOS_SORTING = 'poms/brandVideosFilter/SET_SORTING';
export const SET_BRAND_VIDEOS_PAGE = 'poms/brandVideosFilter/SET_PAGE';
export const SET_BRAND_VIDEOS_RESULTS_PER_PAGE = 'poms/brandVideosFilter/SET_RESULTS_PER_PAGE';

export type BrandVideosFilterState = BrandVideosFilterOptions;

export const setBrandVideosFilter = createAction<Partial<BrandVideosFilter>>(SET_BRAND_VIDEOS_FILTER);

export const setBrandVideosSorting = createAction<BrandVideosSorting>(SET_BRAND_VIDEOS_SORTING);

export type SetBrandVideosSorting = ReturnType<typeof setBrandVideosSorting>;

export const resetBrandVideosSorting = (): SetBrandVideosSorting => setBrandVideosSorting(null);

export const setBrandVideosPage = createAction<number>(SET_BRAND_VIDEOS_PAGE);

export const setBrandVideosPerPage = createAction<BrandVideosPerPage>(SET_BRAND_VIDEOS_RESULTS_PER_PAGE);

const defaultState: BrandVideosFilterState = {
    filter: null,
    sort: null,
    page: 0,
    resultsPerPage: 10,
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setBrandVideosFilter, (state, { payload }) => {
            state.filter = payload;
        })
        .addCase(setBrandVideosSorting, (state, { payload }) => {
            state.sort = payload;
        })
        .addCase(setBrandVideosPage, (state, { payload }) => {
            state.page = payload;
        })
        .addCase(setBrandVideosPerPage, (state, { payload }) => {
            state.resultsPerPage = payload;
        });
});
