import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { AppState } from '~modules/index';

import { DeleteCompensationDialogState } from './deleteCompensationDialog';

const root = (state: AppState): DeleteCompensationDialogState => state.deleteCompensationDialog;
const compensationToDelete = createSelector(root, state => state.compensationToDelete);

export const useDeleteCompensationDialog = (): DeleteCompensationDialogState => useSelector(root);

export default {
    root,
    compensationToDelete
};
