import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { ScepCreatorEventsState } from './scepCreatorEvents';

const root = (state: AppState): ScepCreatorEventsState => state.scepCreatorEvents;

export const useScepCreatorEvents = (): ScepCreatorEventsState => useSelector(root);

export default {
    root,
};
