import { AxiosError, AxiosResponse } from 'axios';

import { ErrorCode, ErrorCodeId } from '~api/model/errorCode';

import { HttpStatus } from '~constants/http';

import { message } from './_common';

export type RequestErrors = {
    messages: string[];
    codes: ErrorCodeId[];
};

export const processAxiosError = (error: AxiosError): RequestErrors => {
    const response = error.response as AxiosResponse<{ errorCodes?: ErrorCode[]; message?: string }>;
    let codes: ErrorCodeId[] = [];
    let messages: string[] = [];

    if (response?.status === HttpStatus.Forbidden) {
        messages = [ message('api.forbidden') ];
    } else if (response?.status === HttpStatus.Unauthorized) {
        messages = [ message('api.unauthorized') ];
    } else if (response?.data?.errorCodes?.length) {
        const errorCodes = response.data.errorCodes as ErrorCode[];

        codes = errorCodes.map(({ code }) => code);
        messages = errorCodes.map(({ code, attributes = [] }) => message(`api.errorCode.${code}`, ...attributes));
    } else if (response?.data?.message) {
        messages = [ response?.data?.message ];
    } else {
        messages = [ message('api.error') ];
    }

    return {
        codes,
        messages,
    };
};
