import { useSelector } from 'react-redux';

import { CreatorVideoEventsState } from '~modules/creatorVideoEvents/creatorVideoEvents';
import { AppState } from '~modules/index';

const root = (state: AppState): CreatorVideoEventsState => state.creatorVideoEvents;

export const useCreatorVideoEvents = (): CreatorVideoEventsState => useSelector(root);

export default {
    root,
};
