import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { BrandVideosFilterState } from './brandVideosFilter';

const root = (state: AppState): BrandVideosFilterState => state.brandVideosFilter;

export const useBrandVideosFilter = (): BrandVideosFilterState => useSelector(root);

export default {
    root,
};
