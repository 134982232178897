import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons, setActiveButton } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { resetMessages, setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';
import { fetchPayoutCycleRunsProcessStepsCalculated } from '~modules/payoutCycleRunsProcessStepsCalculated';

export const RECALCULATE_PAYOUT_CYCLE_RUN = 'poms/payout-cycle-run/recalculate';

export const recalculatePayoutCycleRun = createAction<string>(RECALCULATE_PAYOUT_CYCLE_RUN);

export function * recalculatePayoutCycleRunSaga (action: ReturnType<typeof recalculatePayoutCycleRun>): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(resetMessages('administrationRecalculatePayoutRun'));
        yield put(setActiveButton('recalculatePayout'));

        yield call(api.recalculatePayoutCycleRun, action.payload, accessToken);
        yield put(setMessageAtPosition(message('administration.recalculate.recalculate.success'), 'administrationRecalculatePayoutRun', 'success'));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('administration.recalculate.recalculate.forbidden'), 'administrationRecalculatePayoutRun', 'error'));
        } else {
            yield put(setMessageAtPosition(message('administration.recalculate.recalculate.error'), 'administrationRecalculatePayoutRun', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchPayoutCycleRunsProcessStepsCalculated());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchRecalculatePayoutCycleRun (): Generator<ForkEffect> {
    yield takeLatest(RECALCULATE_PAYOUT_CYCLE_RUN, recalculatePayoutCycleRunSaga);
}
