import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import { FeatureToggleResponse } from '~api/model/unleash';
import api from '~api/unleash';

import { FETCH_FEATURE_TOGGLES, setFeatureToggles } from '~modules/featureToggles';

export function * fetchFeatureToggles (): Generator<StrictEffect, void, any> {
    try {
        const { data }: AxiosResponse<FeatureToggleResponse> = yield call(api.fetchFeatureToggles);

        yield put(setFeatureToggles(data));
    } catch (error: any) {
        yield put(setFeatureToggles({ features: [] }));
    }
}

export default function * watchFeatureToggles (): Generator<ForkEffect> {
    yield takeLatest(FETCH_FEATURE_TOGGLES, fetchFeatureToggles);
}
