import { useSelector } from 'react-redux';
import { Action } from 'redux';

import { PayoutCycleRunsOverview } from '~api/model/payout-cycle';

import selectors from './selectors';

export const FETCH_PAYOUT_CYCLE_RUNS = 'poms/payout-cycle-runs/FETCH';
export const SET_PAYOUT_CYCLES_RUNS = 'poms/payout-cycle-runs/SET';

export type FetchState = 'FETCHING' | 'SUCCESS' | 'NOT_FOUND' | 'ERROR' | 'FORBIDDEN';

export type PayoutCycleRunsForPayoutCycleState = {
    data: PayoutCycleRunsOverview | null;
    fetchState: FetchState;
}

export type PayoutCycleRunsState = Record<string, PayoutCycleRunsForPayoutCycleState>

export interface FetchPayoutCycleRuns extends Action<typeof FETCH_PAYOUT_CYCLE_RUNS> {
    meta: {
        payoutCycleId: string;
    };
}

export interface SetPayoutCycleRuns extends Action<typeof SET_PAYOUT_CYCLES_RUNS> {
    payload: PayoutCycleRunsForPayoutCycleState;
    meta: {
        payoutCycleId: string;
    };
}

export type PayoutCycleRunsAction = FetchPayoutCycleRuns | SetPayoutCycleRuns;

// implementation
const defaultState: PayoutCycleRunsState = {};

export default function payoutCycleRuns (state: PayoutCycleRunsState = defaultState, action: PayoutCycleRunsAction): PayoutCycleRunsState {
    switch (action.type) {
        case FETCH_PAYOUT_CYCLE_RUNS:
            return {
                ...state,
                [action.meta.payoutCycleId]: {
                    ...state[action.meta.payoutCycleId],
                    fetchState: 'FETCHING',
                },
            };
        case SET_PAYOUT_CYCLES_RUNS:
            return {
                ...state,
                [action.meta.payoutCycleId]: action.payload,
            };
        default:
            return state;
    }
}

// actions
export const fetchPayoutCycleRuns = (payoutCycleId: string): FetchPayoutCycleRuns => ({
    type: FETCH_PAYOUT_CYCLE_RUNS,
    meta: {
        payoutCycleId,
    }
});

export const setPayoutCycleRuns = (payoutCycleId: string, payoutCycleRun: PayoutCycleRunsOverview): SetPayoutCycleRuns => ({
    type: SET_PAYOUT_CYCLES_RUNS,
    payload: {
        data: payoutCycleRun,
        fetchState: 'SUCCESS',
    },
    meta: {
        payoutCycleId,
    }
});

export const setPayoutCycleRunsForbidden = (payoutCycleId: string): SetPayoutCycleRuns => ({
    type: SET_PAYOUT_CYCLES_RUNS,
    payload: {
        data: null,
        fetchState: 'FORBIDDEN',
    },
    meta: {
        payoutCycleId,
    }
});

export const setPayoutCycleRunsError = (payoutCycleId: string): SetPayoutCycleRuns => ({
    type: SET_PAYOUT_CYCLES_RUNS,
    payload: {
        data: null,
        fetchState: 'ERROR',
    },
    meta: {
        payoutCycleId,
    }
});

export const usePayoutCycleRuns = (payoutCycleId: string): PayoutCycleRunsForPayoutCycleState | null => useSelector(selectors.root)[payoutCycleId] || null;
