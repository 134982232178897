import { createAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/payout-cycle';

import { HttpStatus } from '~constants/http';

import { setCreatorPayoutOverviewError, setCreatorPayoutOverviewForbidden } from '~modules/creatorPayoutOverview';
import { selectors as oidcSelectors } from '~modules/oidc';
import { setPayoutCycleRunDetailsError, setPayoutCycleRunDetailsForbidden } from '~modules/payoutCycleRunDetails';

export const SHOW_CREDIT_NOTE_DOCUMENT_DRAFT = 'poms/creditNoteDocumentDraft/SHOW_CREDIT_NOTE_DOCUMENT_DRAFT';

export const showCreditNoteDocumentDraft = createAction<{creatorId: string; payoutCycleRunId: string; source: 'payoutInformation' | 'payoutCycleRun'}>(SHOW_CREDIT_NOTE_DOCUMENT_DRAFT);

export function * showCreditNoteDocumentDraftSaga (action: ReturnType<typeof showCreditNoteDocumentDraft>): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const { creatorId, payoutCycleRunId, source } = action.payload;

    try {
        const { data }: AxiosResponse<Blob> = yield call(api.showCreditNoteDocumentDraft, payoutCycleRunId, creatorId, accessToken);

        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'credit-note-document-draft.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error: any) {
        if (source === 'payoutCycleRun') {
            if (error.response?.status === HttpStatus.Forbidden) {
                yield put(setPayoutCycleRunDetailsForbidden());
            } else {
                yield put(setPayoutCycleRunDetailsError());
            }
        } else if (source === 'payoutInformation') {
            if (error.response?.status === HttpStatus.Forbidden) {
                yield put(setCreatorPayoutOverviewForbidden());
            } else {
                yield put(setCreatorPayoutOverviewError());
            }
        }
    }
}

export default function * watchShowCreditNoteDocumentDraft (): Generator<ForkEffect> {
    yield takeLatest(SHOW_CREDIT_NOTE_DOCUMENT_DRAFT, showCreditNoteDocumentDraftSaga);
}
