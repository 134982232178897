import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { PayoutCycleRunsOverview } from '~api/model/payout-cycle';
import api from '~api/payout-cycle';

import { HttpStatus } from '~constants/http';

import { selectors as oidcSelectors } from '~modules/oidc';
import { FETCH_PAYOUT_CYCLE_RUNS, FetchPayoutCycleRuns, setPayoutCycleRuns, setPayoutCycleRunsError, setPayoutCycleRunsForbidden } from '~modules/payoutCycleRuns';

export function * fetchPayoutCycleRuns (action: FetchPayoutCycleRuns): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const payoutCycleId = action.meta.payoutCycleId;

    try {
        const { data }: AxiosResponse<PayoutCycleRunsOverview> = yield call(api.fetchPayoutCycleRunsOverview, payoutCycleId, accessToken);

        yield put(setPayoutCycleRuns(payoutCycleId, data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPayoutCycleRunsForbidden(payoutCycleId));
        } else {
            yield put(setPayoutCycleRunsError(payoutCycleId));
        }
    }
}

export default function * watchFetchPayoutCycleRuns (): Generator<ForkEffect> {
    yield takeEvery(FETCH_PAYOUT_CYCLE_RUNS, fetchPayoutCycleRuns);
}
