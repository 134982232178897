// type declaration
import { createAction, createReducer } from '@reduxjs/toolkit';

import { BrandVideo } from '~api/model/brand-video';

export const FETCH_BRAND_VIDEOS = 'poms/brandVideos/FETCH';
export const SET_BRAND_VIDEOS = 'poms/brandVideos/SET';
export const SET_BRAND_VIDEOS_TAB = 'poms/brandVideos/SET_TAB';
export const HIDE_BRAND_VIDEO = 'poms/brandVideos/HIDE';
export const SHOW_BRAND_VIDEO = 'poms/brandVideos/SHOW';
export const UPDATE_BRAND_VIDEO = 'poms/brandVideos/UPDATE';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type BrandVideosTab = 'OVERVIEW' | 'HIDDEN';

export type BrandVideosState = {
    brandVideos: Array<BrandVideo> | null;
    fetchState: FetchState;
    currentTab: BrandVideosTab;
};

// actions
export const fetchBrandVideos = createAction(FETCH_BRAND_VIDEOS);

export const setBrandVideos = createAction(
    SET_BRAND_VIDEOS,
    (brandVideos: Array<BrandVideo> | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            brandVideos,
            fetchState,
        },
    }));

export const setBrandVideosForbidden = (): ReturnType<typeof setBrandVideos> =>
    setBrandVideos(null, 'FORBIDDEN');

export const setBrandVideosError = (): ReturnType<typeof setBrandVideos> =>
    setBrandVideos(null, 'ERROR');

export const setBrandVideosTab = createAction<BrandVideosTab>(SET_BRAND_VIDEOS_TAB);

export const hideBrandVideo = createAction<BrandVideo>(HIDE_BRAND_VIDEO);

export type HideBrandVideo = ReturnType<typeof hideBrandVideo>;

export const showBrandVideo = createAction<BrandVideo>(SHOW_BRAND_VIDEO);

export type ShowBrandVideo = ReturnType < typeof showBrandVideo >;

export const updateBrandVideo = createAction<BrandVideo>(UPDATE_BRAND_VIDEO);

// reducer
const defaultState: BrandVideosState = {
    brandVideos: null,
    fetchState: 'INIT',
    currentTab: 'OVERVIEW',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchBrandVideos, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setBrandVideos, (state, { payload }) => ({
            ...state,
            ...payload,
        }))
        .addCase(setBrandVideosTab, (state, { payload }) => {
            state.currentTab = payload;
        })
        .addCase(updateBrandVideo, (state, { payload }) => {
            if (state.brandVideos) {
                const idx = state.brandVideos.findIndex(brandVideo => brandVideo.id === payload.id);

                if (idx >= 0) {
                    state.brandVideos[idx] = payload;
                }
            }
        });
});
