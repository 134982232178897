import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { BrandVideosState } from './brandVideos';

const root = (state: AppState): BrandVideosState => state.brandVideos;

export const useBrandVideos = (): BrandVideosState => useSelector(root);

export default {
    root,
};
