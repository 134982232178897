import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorsLevelChangeOverview } from '~api/model/creator';

// type declaration
export const FETCH_CREATORS_LEVEL_CHANGE_OVERVIEW = 'poms/creatorsLevelChangeOverviewTable/FETCH';
export const SET_CREATORS_LEVEL_CHANGE_OVERVIEW = 'poms/creatorsLevelChangeOverviewTable/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorsLevelChangeOverviewState = {
    levelChangeOverviews: CreatorsLevelChangeOverview[] | null;
    fetchState: FetchState;
};

// actions
export const fetchCreatorsLevelChangeOverview = createAction(FETCH_CREATORS_LEVEL_CHANGE_OVERVIEW);

export const setCreatorsLevelChangeOverview = createAction(
    SET_CREATORS_LEVEL_CHANGE_OVERVIEW,
    (levelChangeOverviews: CreatorsLevelChangeOverview[] | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            levelChangeOverviews,
            fetchState,
        }
    }));

export type FetchCreatorsLevelChangeOverviewAction = ReturnType<typeof fetchCreatorsLevelChangeOverview>;

export type SetCreatorsLevelChangeOverviewAction = ReturnType<typeof setCreatorsLevelChangeOverview>;

export const setCreatorsLevelChangeOverviewForbidden = (): SetCreatorsLevelChangeOverviewAction =>
    setCreatorsLevelChangeOverview(null, 'FORBIDDEN');

export const setCreatorsLevelChangeOverviewError = (): SetCreatorsLevelChangeOverviewAction =>
    setCreatorsLevelChangeOverview(null, 'ERROR');

export const resetCreatorsLevelChangeOverview = (): SetCreatorsLevelChangeOverviewAction =>
    setCreatorsLevelChangeOverview(null, 'INIT');

// reducer
const defaultState: CreatorsLevelChangeOverviewState = {
    levelChangeOverviews: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreatorsLevelChangeOverview, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCreatorsLevelChangeOverview, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
