import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CompensableViewsDashboardDetailsState } from './compensableViewsDashboardDetails';

const root = (state: AppState): CompensableViewsDashboardDetailsState => state.compensableViewsDashboardDetails;

export const useCompensableViewsDashboardDetails = (): CompensableViewsDashboardDetailsState => useSelector(root);

export default {
    root,
};
