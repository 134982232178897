import axios, { AxiosPromise } from 'axios';

import { addAuthorizationHeader, endpoint } from '~api/_helper';
import { CreatePayoutCorrectionForm, CreatorPayoutCorrection } from '~api/model/payout-correction';

import { Endpoints } from '~constants/endpoints';

const createPayoutCorrection = (payoutCorrection: CreatePayoutCorrectionForm, accessToken: string): AxiosPromise<void> => {
    return axios.post(Endpoints.CREATE_PAYOUT_CORRECTION, payoutCorrection, addAuthorizationHeader(accessToken));
};

const fetchCreatorPayoutCorrections = (creatorId: string, accessToken: string):AxiosPromise<CreatorPayoutCorrection[]> => {
    return axios.get(endpoint(Endpoints.CREATOR_PAYOUT_CORRECTIONS, creatorId), addAuthorizationHeader(accessToken));
};

export default {
    createPayoutCorrection,
    fetchCreatorPayoutCorrections,
};
