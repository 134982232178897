import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CreatorPayoutCorrectionsState } from './creatorPayoutCorrections';

const root = (state: AppState): CreatorPayoutCorrectionsState => state.creatorPayoutCorrections;

export const useCreatorPayoutCorrections = (): CreatorPayoutCorrectionsState => useSelector(root);

export default {
    root,
};
