import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/brand-video';

import { processAxiosError } from '~helper/_processAxiosError';

import { resetAllActiveButtons, setActiveButton } from '~modules/activeButton';
import { hideBrandVideoCreatorSelectionDialog } from '~modules/brandVideoCreatorSelectionDialog';
import { fetchBrandVideos } from '~modules/brandVideos';
import { ASSIGN_BRAND_VIDEO_VIEWS_TO_CREATORS, assignBrandVideoViewsToCreators, hideBrandVideoViewAssignment } from '~modules/brandVideoViewAssignmentDialog';
import { Message, setMessages } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * brandVideoViewAssignmentSaga (action: ReturnType<typeof assignBrandVideoViewsToCreators>): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(setActiveButton('brandVideoViewAssignmentDialog'));

        yield call(api.assignBrandVideoViews, action.payload, accessToken);

        yield put(hideBrandVideoViewAssignment());
        yield put(hideBrandVideoCreatorSelectionDialog());
        yield put(fetchBrandVideos());
    } catch (error: any) {
        const { messages } = processAxiosError(error);
        const errorMessages: Message[] = messages.map((text) => ({
            text,
            severity: 'error',
            position: 'brandVideoViewAssignmentDialog',
        }));

        yield put(setMessages(errorMessages));
    } finally {
        yield put(resetAllActiveButtons());
    }
}

export default function * watchBrandVideoViewAssignment (): Generator<ForkEffect> {
    yield takeLatest(ASSIGN_BRAND_VIDEO_VIEWS_TO_CREATORS, brandVideoViewAssignmentSaga);
}
