import axios, { AxiosPromise } from 'axios';

import { Endpoints } from '~constants/endpoints';

import { Compensation, CompensationOverview } from './model/compensation';
import { addAuthorizationHeader } from './_helper';

const fetchCompensation = (accessToken: string): AxiosPromise<CompensationOverview> => {
    return axios.get(Endpoints.COMPENSATION_OVERVIEW, addAuthorizationHeader(accessToken));
};

const changeCompensation = (compensation: Compensation, accessToken: string): AxiosPromise<CompensationOverview> => {
    return axios.put(`${Endpoints.COMPENSATION_RULE_SET}/${compensation.id}`, compensation, addAuthorizationHeader(accessToken));
};

const createCompensation = (compensation: Compensation, accessToken: string): AxiosPromise<CompensationOverview> => {
    return axios.post(Endpoints.COMPENSATION_RULE_SET, compensation, addAuthorizationHeader(accessToken));
};

const deleteCompensation = (compensation: Compensation, accessToken: string): AxiosPromise<CompensationOverview> => {
    return axios.delete(`${Endpoints.COMPENSATION_RULE_SET}/${compensation.id}`, addAuthorizationHeader(accessToken));
};

export default {
    changeCompensation,
    createCompensation,
    deleteCompensation,
    fetchCompensation,
};
