import { createAction, createReducer } from '@reduxjs/toolkit';

import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';

// type declaration
export const FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_RECALCULATABLE = 'poms/payout-cycle-runs-process-steps-recalculatable/FETCH';
export const SET_PAYOUT_CYCLE_RUN_PROCESS_STEPS_RECALCULATABLE = 'poms/payout-cycle-runs-process-steps-recalculatable/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type PayoutCycleRunsProcessStepRecalculatableState = {
    processSteps: PayoutCycleRunProcessSteps | null;
    fetchState: FetchState;
}

// actions
export const fetchPayoutCycleRunsProcessStepsRecalculatable = createAction(FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_RECALCULATABLE);

export const setPayoutCycleRunsProcessStepsRecalculatable = createAction(
    SET_PAYOUT_CYCLE_RUN_PROCESS_STEPS_RECALCULATABLE,
    (processSteps: PayoutCycleRunProcessSteps | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            processSteps,
            fetchState,
        },
    }),
);

export const setPayoutCycleRunsProcessStepsRecalculatableForbidden = (): ReturnType<typeof setPayoutCycleRunsProcessStepsRecalculatable> =>
    setPayoutCycleRunsProcessStepsRecalculatable(null, 'FORBIDDEN');

export const setPayoutCycleRunsProcessStepsRecalculatableError = (): ReturnType<typeof setPayoutCycleRunsProcessStepsRecalculatable> =>
    setPayoutCycleRunsProcessStepsRecalculatable(null, 'ERROR');

// reducer
const defaultState: PayoutCycleRunsProcessStepRecalculatableState = {
    processSteps: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchPayoutCycleRunsProcessStepsRecalculatable, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setPayoutCycleRunsProcessStepsRecalculatable, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
