import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CollectedBeautyPointsDashboardDetailsState } from './collectedBeautyPointsDashboardDetails';

const root = (state: AppState): CollectedBeautyPointsDashboardDetailsState => state.collectedBeautyPointsDashboardDetails;

export const useCollectedBeautyPointsDashboardDetails = (): CollectedBeautyPointsDashboardDetailsState => useSelector(root);

export default {
    root,
};
