import { createAction, createReducer } from '@reduxjs/toolkit';

import { Compensation } from '~api/model/compensation';

// type declarations
export const SET_COMPENSATION_DIALOG_OPEN = 'poms/compensationDialog/SET_COMPENSATION_DIALOG_OPEN';
export const SET_EDIT_COMPENSATION = 'poms/compensationDialog/SET_EDIT_COMPENSATION';
export const SUBMIT_COMPENSATION = 'poms/compensationDialog/SUBMIT_COMPENSATION';
export const SET_SUBMIT_IN_PROGRESS = 'poms/compensationDialog/SET_SUBMIT_IN_PROGRESS';

export type EditCompensationDialogState = {
    open: boolean;
    compensation: Compensation | null;
    isSubmitInProgress: boolean;
};

// actions
export const setCompensationDialogOpen = createAction<boolean>(SET_COMPENSATION_DIALOG_OPEN);

export const setEditCompensation = createAction<Compensation | null>(SET_EDIT_COMPENSATION);

export const submitCompensation = createAction<Compensation>(SUBMIT_COMPENSATION);

export const setSubmitInProgress = createAction<boolean>(SET_SUBMIT_IN_PROGRESS);

// reducer
const defaultState: EditCompensationDialogState = {
    open: false,
    compensation: null,
    isSubmitInProgress: false,
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setCompensationDialogOpen, (state, { payload }) => ({
            ...state,
            open: payload,
            isSubmitInProgress: false,
        }))
        .addCase(setEditCompensation, (state, { payload }) => ({
            ...state,
            open: true,
            compensation: payload,
            isSubmitInProgress: false,
        }))
        .addCase(submitCompensation, (state) => ({
            ...state,
            isSubmitInProgress: true,
        }))
        .addCase(setSubmitInProgress, (state, { payload }) => ({
            ...state,
            isSubmitInProgress: payload,
        }));
});
