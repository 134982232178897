import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/compensation';
import { CompensationOverview } from '~api/model/compensation';

import { processAxiosError } from '~helper/_processAxiosError';

import { resetAllActiveButtons } from '~modules/activeButton';
import { setCompensationOverview } from '~modules/compensationOverview';
import { setCompensationDialogOpen, setSubmitInProgress, SUBMIT_COMPENSATION, submitCompensation, } from '~modules/editCompensationDialog';
import { Message, setMessages } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * createUpdateCompensation (
    action: ReturnType<typeof submitCompensation>,
): Generator<StrictEffect, void, any> {
    try {
        const accessToken = yield select(oidcSelectors.accessToken);
        const compensation = action.payload;

        const request = compensation.id ? api.changeCompensation : api.createCompensation;
        const { data }: AxiosResponse<CompensationOverview> = yield call(request, compensation, accessToken);

        yield put(setCompensationOverview(data));
        yield put(setCompensationDialogOpen(false));
    } catch (error: any) {
        const { messages } = processAxiosError(error);
        const compensationDialogMessages: Message[] = messages.map((text) => ({
            text,
            severity: 'error',
            position: 'editCompensationDialog',
        }));

        yield put(setMessages(compensationDialogMessages));
    } finally {
        yield put(setSubmitInProgress(false));
        yield put(resetAllActiveButtons());
    }
}

export default function * watchCreateUpdateCompensation (): Generator<ForkEffect> {
    yield takeLatest(SUBMIT_COMPENSATION, createUpdateCompensation);
}
