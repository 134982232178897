import { useSelector } from 'react-redux';
import { Action } from 'redux';

import { PayoutCycleRun } from '~api/model/payout-cycle';

import selectors from './selectors';

export const FETCH_ESTIMATED_PAYOUT_CYCLE_RUNS = 'poms/estimated-payout-cycle-runs/FETCH';
export const SET_ESTIMATED_PAYOUT_CYCLES_RUNS = 'poms/estimated-payout-cycle-runs/SET';

export type FetchState = 'FETCHING' | 'SUCCESS' | 'NOT_FOUND' | 'ERROR' | 'FORBIDDEN';

export type EstimatedPayoutCycleRunsForPayoutCycleState = {
    data: PayoutCycleRun | null;
    fetchState: FetchState;
}

export type EstimatedPayoutCycleRunsState = Record<string, EstimatedPayoutCycleRunsForPayoutCycleState>

export interface FetchEstimatedPayoutCycleRuns extends Action<typeof FETCH_ESTIMATED_PAYOUT_CYCLE_RUNS> {
    meta: {
        payoutCycleId: string;
    };
}

export interface SetEstimatedPayoutCycleRuns extends Action<typeof SET_ESTIMATED_PAYOUT_CYCLES_RUNS> {
    payload: EstimatedPayoutCycleRunsForPayoutCycleState;
    meta: {
        payoutCycleId: string;
    };
}

export type EstimatedPayoutCycleRunsAction = FetchEstimatedPayoutCycleRuns | SetEstimatedPayoutCycleRuns;

// implementation
const defaultState: EstimatedPayoutCycleRunsState = {};

export default function estimatedPayoutCycleRuns (state: EstimatedPayoutCycleRunsState = defaultState, action: EstimatedPayoutCycleRunsAction): EstimatedPayoutCycleRunsState {
    switch (action.type) {
        case FETCH_ESTIMATED_PAYOUT_CYCLE_RUNS:
            return {
                ...state,
                [action.meta.payoutCycleId]: {
                    ...state[action.meta.payoutCycleId],
                    fetchState: 'FETCHING',
                },
            };
        case SET_ESTIMATED_PAYOUT_CYCLES_RUNS:
            return {
                ...state,
                [action.meta.payoutCycleId]: action.payload,
            };
        default:
            return state;
    }
}

// actions
export const fetchEstimatedPayoutCycleRuns = (payoutCycleId: string): FetchEstimatedPayoutCycleRuns => ({
    type: FETCH_ESTIMATED_PAYOUT_CYCLE_RUNS,
    meta: {
        payoutCycleId,
    }
});

export const setEstimatedPayoutCycleRuns = (payoutCycleId: string, payoutCycleRun: PayoutCycleRun): SetEstimatedPayoutCycleRuns => ({
    type: SET_ESTIMATED_PAYOUT_CYCLES_RUNS,
    payload: {
        data: payoutCycleRun,
        fetchState: 'SUCCESS',
    },
    meta: {
        payoutCycleId,
    }
});

export const setEstimatedPayoutCycleRunsForbidden = (payoutCycleId: string): SetEstimatedPayoutCycleRuns => ({
    type: SET_ESTIMATED_PAYOUT_CYCLES_RUNS,
    payload: {
        data: null,
        fetchState: 'FORBIDDEN',
    },
    meta: {
        payoutCycleId,
    }
});

export const setEstimatedPayoutCycleRunsError = (payoutCycleId: string): SetEstimatedPayoutCycleRuns => ({
    type: SET_ESTIMATED_PAYOUT_CYCLES_RUNS,
    payload: {
        data: null,
        fetchState: 'ERROR',
    },
    meta: {
        payoutCycleId,
    }
});

export const useEstimatedPayoutCycleRun = (payoutCycleId: string): EstimatedPayoutCycleRunsForPayoutCycleState | null => useSelector(selectors.root)[payoutCycleId] || null;
