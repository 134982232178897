import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';
import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';

import { HttpStatus } from '~constants/http';

import { selectors as oidcSelectors } from '~modules/oidc';
import {
    FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PAYOUTS_CREATED,
    setPayoutCycleRunsProcessStepsPayoutsCreated,
    setPayoutCycleRunsProcessStepsPayoutsCreatedError,
    setPayoutCycleRunsProcessStepsPayoutsCreatedForbidden
} from '~modules/payoutCycleRunsProcessStepsPayoutsCreated';

export function * fetchPayoutCycleRunsProcessStepsPayoutsCreated (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<PayoutCycleRunProcessSteps> = yield call(api.fetchPayoutCycleRunsPayoutsCreated, accessToken);

        yield put(setPayoutCycleRunsProcessStepsPayoutsCreated(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPayoutCycleRunsProcessStepsPayoutsCreatedForbidden());
        } else {
            yield put(setPayoutCycleRunsProcessStepsPayoutsCreatedError());
        }
    }
}

export default function * watchFetchPayoutCycleRunsProcessStepsPayoutsCreated (): Generator<ForkEffect> {
    yield takeLatest(FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PAYOUTS_CREATED, fetchPayoutCycleRunsProcessStepsPayoutsCreated);
}
