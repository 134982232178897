import { createAction, createReducer } from '@reduxjs/toolkit';

import { BrandVideo, CreatorAssignment } from '~api/model/brand-video';

export const SHOW_BRAND_VIDEO_CREATOR_SELECTION_DIALOG = 'poms/brandVideoAssignmentDialog/SHOW_DIALOG';
export const HIDE_BRAND_VIDEO_CREATOR_SELECTION_DIALOG = 'poms/brandVideoAssignmentDialog/HIDE_DIALOG';
export const SET_CREATOR_FILTER = 'poms/brandVideoAssignmentDialog/SET_CREATOR_FILTER';
export const TOGGLE_CREATOR = 'poms/brandVideoAssignmentDialog/TOGGLE_CREATOR';

export type BrandVideoCreatorSelectionDialogState = {
    brandVideo: BrandVideo | null;
    creatorFilter: string;
};

export const showBrandVideoCreatorSelectionDialog = createAction<BrandVideo>(SHOW_BRAND_VIDEO_CREATOR_SELECTION_DIALOG);
export const hideBrandVideoCreatorSelectionDialog = createAction(HIDE_BRAND_VIDEO_CREATOR_SELECTION_DIALOG);
export const setCreatorFilter = createAction<string>(SET_CREATOR_FILTER);
export const toggleCreator = createAction<CreatorAssignment>(TOGGLE_CREATOR);

export const defaultState: BrandVideoCreatorSelectionDialogState = {
    brandVideo: null,
    creatorFilter: '',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(showBrandVideoCreatorSelectionDialog, (state, { payload: brandVideo }) => {
            state.brandVideo = {
                ...brandVideo,
                assignedCreators: brandVideo.assignedCreators.map(creator => ({ ...creator }))
            };
        })
        .addCase(hideBrandVideoCreatorSelectionDialog, () => defaultState)
        .addCase(setCreatorFilter, (state, { payload }) => {
            state.creatorFilter = payload;
        })
        .addCase(toggleCreator, (state, { payload }) => {
            if (state.brandVideo) {
                const index = state.brandVideo.assignedCreators.findIndex(creator => creator.creatorId === payload.creatorId);

                if (index !== -1) {
                    state.brandVideo.assignedCreators.splice(index, 1);
                } else {
                    state.brandVideo.assignedCreators.push(payload);
                }
            }
        });
});
