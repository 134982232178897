import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { resetMessages, setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export const RECALCULATE_CREATOR_LEVELS = 'poms/creator-levels/RECALCULATE';

export const recalculateCreatorLevels = createAction(RECALCULATE_CREATOR_LEVELS);

export function * recalculateCreatorLevelsSaga (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(resetMessages('administrationCreatorLevel'));

        yield call(api.recalculateCreatorLevels, accessToken);

        yield put(setMessageAtPosition(message('administration.creatorLevel.recalculate.success'), 'administrationCreatorLevel', 'success'));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('administration.creatorLevel.recalculate.forbidden'), 'administrationCreatorLevel', 'error'));
        } else {
            yield put(setMessageAtPosition(message('administration.creatorLevel.recalculate.error'), 'administrationCreatorLevel', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchRecalculateCreatorLevels (): Generator<ForkEffect> {
    yield takeLatest(RECALCULATE_CREATOR_LEVELS, recalculateCreatorLevelsSaga);
}
