import { useMemo } from 'react';
import getConfig from 'next/config';
import Oidc, { Log, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { Store } from 'redux';
import { createUserManager, loadUser } from 'redux-oidc';

import { Environment } from '~constants/environments';
import { Routes } from '~constants/routes';

import { isServer } from '~helper/_environment';
import { getHostNameUrl } from '~helper/_url';

export const createOprUserManager = (store?: Store | null): UserManager | null => {
    if (isServer()) {
        return null;
    }

    if (process.env.NODE_ENV === Environment.DEV) {
        Oidc.Log.logger = console;
        Oidc.Log.level = Log.DEBUG;
    }

    const { publicRuntimeConfig } = getConfig();
    const userManagerConfig: UserManagerSettings = {
        client_id: publicRuntimeConfig.azureAdClientId,
        redirect_uri: `${getHostNameUrl()}${Routes.OIDC_AUTH}`,
        response_type: 'code',
        scope: 'openid offline_access email',
        authority: publicRuntimeConfig.azureAdAuthority,
        silent_redirect_uri: `${getHostNameUrl()}${Routes.OIDC_RENEW}`,
        automaticSilentRenew: true,
        filterProtocolClaims: true,
        loadUserInfo: false,
        post_logout_redirect_uri: `${getHostNameUrl()}${Routes.OIDC_LOGGED_OUT}`,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        extraQueryParams: {
            resource: publicRuntimeConfig.azureAdResource,
        }
    };

    const userManager = createUserManager(userManagerConfig);

    if (store) {
        loadUser(store, userManager);
    }

    return userManager;
};

export const useUserManager = (store?: Store | null): UserManager | null => {
    return useMemo(() => createOprUserManager(store), [ store, isServer() ]);
};
