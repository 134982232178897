import { createAction, createReducer } from '@reduxjs/toolkit';

import { DashboardPayoutSummary } from '~api/model/dashboardAggregations';
import { PayoutCycle, PayoutCycleRun } from '~api/model/payout-cycle';

// type declarations
const actionType = (name: string) => `poms/dashboardPayoutSummary/${name}`;

export const SET_FETCH_STATE = actionType('SET_FETCH_STATE');
export const FETCH_DASHBOARD_PAYOUT_SUMMARY = actionType('FETCH_DASHBOARD_PAYOUT_SUMMARY');
export const SET_DASHBOARD_PAYOUT_SUMMARY = actionType('SET_DASHBOARD_PAYOUT_SUMMARY');

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type DashboardPayoutSummaryState = {
    nextPayoutCycle: PayoutCycle | null;
    upcomingPayoutCycles: PayoutCycle[];
    nextPayoutCycleRun: PayoutCycleRun | null;
    isEstimation: boolean;
    fetchState: FetchState;
}

// actions

export const setFetchState = createAction<FetchState>(SET_FETCH_STATE);

export const fetchDashboardPayoutSummary = createAction(FETCH_DASHBOARD_PAYOUT_SUMMARY);

export const setDashboardPayoutSummary = createAction<DashboardPayoutSummary>(SET_DASHBOARD_PAYOUT_SUMMARY);

// reducer
const defaultState: DashboardPayoutSummaryState = {
    nextPayoutCycle: null,
    upcomingPayoutCycles: [],
    nextPayoutCycleRun: null,
    isEstimation: false,
    fetchState: 'INIT',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setFetchState, (state, { payload }) => ({
            ...state,
            fetchState: payload,
        }))
        .addCase(fetchDashboardPayoutSummary, (state) => {
            state.fetchState = 'FETCHING';
        })
        .addCase(setDashboardPayoutSummary, (state, { payload }) => ({
            ...state,
            ...payload,
            fetchState: 'SUCCESS',
        }));
});
