import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/payout-correction';

import { processAxiosError } from '~helper/_processAxiosError';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import {
    CREATE_PAYOUT_CORRECTION,
    createPayoutCorrection as createPayoutCorrectionAction,
    setCreatePayoutCorrectionDialogOpen,
    setSubmitInProgress,
} from '~modules/createPayoutCorrectionDialog';
import { Message, resetMessages, setMessages } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * createPayoutCorrection (
    action: ReturnType<typeof createPayoutCorrectionAction>,
): Generator<StrictEffect, void, any> {
    try {
        const accessToken = yield select(oidcSelectors.accessToken);
        const payoutCorrection = action.payload;

        yield call(api.createPayoutCorrection, payoutCorrection, accessToken);

        yield put(setCreatePayoutCorrectionDialogOpen(false));
        yield put(resetMessages());
    } catch (error: any) {
        const { messages } = processAxiosError(error);
        const createPayoutCorrectionDialogMessages: Message[] = messages.map((text) => ({
            text,
            severity: 'error',
            position: 'createPayoutCorrection',
        }));

        yield put(setMessages(createPayoutCorrectionDialogMessages));
    } finally {
        yield put(fetchAdministrativeActions());
        yield put(setSubmitInProgress(false));
        yield put(resetAllActiveButtons());
    }
}

export default function * watchCreatePayoutCorrection (): Generator<ForkEffect> {
    yield takeLatest(CREATE_PAYOUT_CORRECTION, createPayoutCorrection);
}
