import { AxiosResponse } from 'axios';
import { formatISO } from 'date-fns';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';
import { ScepCreatorEvents } from '~api/model/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetMessages, setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';
import { FETCH_SCEP_CREATOR_EVENTS, FetchScepCreatorEvents, resetScepCreatorEvents, setScepCreatorEvents, setScepCreatorEventsError, setScepCreatorEventsForbidden } from '~modules/scepCreatorEvents';

export function * fetchScepCreatorEvents (action: FetchScepCreatorEvents): Generator<StrictEffect, void, any> {
    if (!action.payload.cdcId && !action.payload.date) {
        yield put(setMessageAtPosition(message('administration.scepLookup.error.fieldsAreNotFilled'), 'administrationScepLookup'));
        yield put(resetScepCreatorEvents());

        return;
    }

    const maybeDateString = action.payload.date ? formatISO(action.payload.date, { representation: 'date' }) : undefined;
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<ScepCreatorEvents> = yield call(api.fetchScepEvents, accessToken, action.payload.cdcId, maybeDateString);

        yield put(setScepCreatorEvents(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setScepCreatorEventsForbidden());
        } else {
            yield put(setScepCreatorEventsError());
        }
    } finally {
        yield put(resetMessages());
    }
}

export default function * watchFetchScepCreatorEvents (): Generator<ForkEffect> {
    yield takeLatest(FETCH_SCEP_CREATOR_EVENTS, fetchScepCreatorEvents);
}
