import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorsDashboardTileDetails } from '~api/model/dashboardAggregations';

// type declaration
export const FETCH_CREATOR_DASHBOARD_DETAILS = 'poms/creatorDashboardDetails/FETCH';
export const SET_CREATOR_DASHBOARD_DETAILS = 'poms/creatorDashboardDetails/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorDashboardDetailsState = {
    dashboardDetails: CreatorsDashboardTileDetails | null;
    fetchState: FetchState;
};

// actions
export const fetchCreatorDashboardDetails = createAction(FETCH_CREATOR_DASHBOARD_DETAILS);

export const setCreatorDashboardDetails = createAction(
    SET_CREATOR_DASHBOARD_DETAILS,
    (dashboardDetails: CreatorsDashboardTileDetails | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            dashboardDetails,
            fetchState,
        }
    }));

export type FetchCreatorDashboardDetailsAction = ReturnType<typeof fetchCreatorDashboardDetails>;

export type SetCreatorDashboardDetailsAction = ReturnType<typeof setCreatorDashboardDetails>;

export const setCreatorDashboardDetailsForbidden = (): SetCreatorDashboardDetailsAction =>
    setCreatorDashboardDetails(null, 'FORBIDDEN');

export const setCreatorDashboardDetailsError = (): SetCreatorDashboardDetailsAction =>
    setCreatorDashboardDetails(null, 'ERROR');

export const resetCreatorDashboardDetails = (): SetCreatorDashboardDetailsAction =>
    setCreatorDashboardDetails(null, 'INIT');

// reducer
const defaultState: CreatorDashboardDetailsState = {
    dashboardDetails: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreatorDashboardDetails, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCreatorDashboardDetails, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
