import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CreatorEstimatedPayoutState } from './creatorEstimatedPayout';

const root = (state: AppState): CreatorEstimatedPayoutState => state.creatorEstimatedPayout;

export const useCreatorEstimatedPayout = (): CreatorEstimatedPayoutState => useSelector(root);

export default {
    root,
};
