import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/dashboard-aggregations';
import { BeautyPointsDashboardTileDetails } from '~api/model/dashboardAggregations';

import { HttpStatus } from '~constants/http';

import { getRangeFromFilter } from '~helper/range-filter';

import {
    selectors as dashboardDetailTileDateFilterSelectors,
    SET_DASHBOARD_DETAIL_TILE_DATE_FILTER,
    SetDashboardDetailTileDateFilterAction,
} from '~modules/dashboardDetailTileDateFilter';
import { selectors as oidcSelectors } from '~modules/oidc';
import {
    FETCH_PAID_OUT_BEAUTY_POINTS_DASHBOARD_DETAILS,
    FetchPaidOutBeautyPointsDashboardDetailsAction,
    setPaidOutBeautyPointsDashboardDetails,
    setPaidOutBeautyPointsDashboardDetailsError,
    setPaidOutBeautyPointsDashboardDetailsForbidden,
} from '~modules/paidOutBeautyPointsDashboardDetails';

export function * fetchPaidOutBeautyPointsDashboardDetails (action: FetchPaidOutBeautyPointsDashboardDetailsAction | SetDashboardDetailTileDateFilterAction): Generator<StrictEffect, void, any> {
    const { dataType, creatorId } = action.meta;

    if (action.type === SET_DASHBOARD_DETAIL_TILE_DATE_FILTER && dataType !== 'paidOutBeautyPoints') {
        return;
    }

    const accessToken = yield select(oidcSelectors.accessToken);
    const rangeFilter = yield select(dashboardDetailTileDateFilterSelectors.root);
    const range = getRangeFromFilter(rangeFilter);

    try {
        const { data }: AxiosResponse<BeautyPointsDashboardTileDetails> = creatorId
            ? yield call(api.fetchPaidOutBeautyPointsCreatorDashboardDetails, creatorId, range.from, range.to, accessToken)
            : yield call(api.fetchPaidOutBeautyPointsDashboardDetails, range.from, range.to, accessToken);

        yield put(setPaidOutBeautyPointsDashboardDetails(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPaidOutBeautyPointsDashboardDetailsForbidden());
        } else {
            yield put(setPaidOutBeautyPointsDashboardDetailsError());
        }
    }
}

export default function * watchFetchContentDashboardDetails (): Generator<ForkEffect> {
    yield takeLatest(FETCH_PAID_OUT_BEAUTY_POINTS_DASHBOARD_DETAILS, fetchPaidOutBeautyPointsDashboardDetails);
    yield takeLatest(SET_DASHBOARD_DETAIL_TILE_DATE_FILTER, fetchPaidOutBeautyPointsDashboardDetails);
}
