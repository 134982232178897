import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/dashboard-aggregations';
import { DashboardAggregations } from '~api/model/dashboardAggregations';

import { HttpStatus } from '~constants/http';

import { FETCH_DASHBOARD_AGGREGATIONS, setDashboardAggregations, setDashboardAggregationsError, setDashboardAggregationsForbidden } from '~modules/dashboardAggregations/dashboardAggregations';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchDashboardAggregations (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<DashboardAggregations> = yield call(api.fetchDashboardAggregations, accessToken);

        yield put(setDashboardAggregations(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setDashboardAggregationsForbidden());
        } else {
            yield put(setDashboardAggregationsError());
        }
    }
}

export default function * watchFetchDashboardAggregations (): Generator<ForkEffect> {
    yield takeLatest(FETCH_DASHBOARD_AGGREGATIONS, fetchDashboardAggregations);
}
