import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/payout-cycle';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchCreatorPayoutOverview } from '~modules/creatorPayoutOverview';
import { setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';
import { fetchPayoutCycleRunCreators } from '~modules/payoutCycleRunCreators';
import { fetchPayoutCycleRunDetails } from '~modules/payoutCycleRunDetails';
import { CONFIRM_PAYOUT_STATUS_CHANGE, resetPayoutStatusChange, selectors } from '~modules/payoutStatusChangeConfirmDialog';

export function * confirmPayoutStatusChangeSaga (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const { payoutStatus, payout } = yield select(selectors.root);

    try {
        switch (payoutStatus) {
            case 'stop':
                yield call(api.stopPayout, payout.payoutCycleRunId, payout.creatorId, accessToken);
                break;
            case 'onHold':
                yield call(api.setPayoutOnHold, payout.payoutCycleRunId, payout.creatorId, accessToken);
                break;
            case 'resume':
                yield call(api.resumePayout, payout.payoutCycleRunId, payout.creatorId, accessToken);
                break;
        }
        yield put(resetPayoutStatusChange());
        yield put(fetchPayoutCycleRunDetails(payout.payoutCycleRunId));
        yield put(fetchPayoutCycleRunCreators(payout.payoutCycleRunId));
        yield put(fetchCreatorPayoutOverview(payout.creatorId));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('payout.statusChange.forbidden'), 'payoutStatusChangeConfirmDialog', 'error'));
        } else if (error.response?.status === HttpStatus.Conflict) {
            yield put(
                setMessageAtPosition(
                    message(
                        'payout.statusChange.conflict',
                        payout.nickname || message('payout.statusChange.noName')
                    ),
                    'payoutStatusChangeConfirmDialog',
                    'error'
                )
            );
        } else {
            yield put(setMessageAtPosition(message('payout.statusChange.error'), 'payoutStatusChangeConfirmDialog', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
    }
}

export default function * watchChangePayoutStatus (): Generator<ForkEffect> {
    yield takeLatest(CONFIRM_PAYOUT_STATUS_CHANGE, confirmPayoutStatusChangeSaga);
}
