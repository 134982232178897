import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons, setActiveButton } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { resetMessages, setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export const RECALCULATE_DASHBOARD_KPI = 'poms/dashboard-kpi/RECALCULATE';

export const recalculateDashboardKPI = createAction<Date>(RECALCULATE_DASHBOARD_KPI);

export function * recalculateDashboardKPISaga (action: ReturnType<typeof recalculateDashboardKPI>): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(resetMessages('administrationDashboardKPI'));
        yield put(setActiveButton('recalculateDashboardKPI'));

        yield call(api.recalculateDashboardKPI, action.payload, accessToken);

        yield put(setMessageAtPosition(message('administration.dashboardKpi.recalculate.success'), 'administrationDashboardKPI', 'success'));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('administration.dashboardKpi.recalculate.forbidden'), 'administrationDashboardKPI', 'error'));
        } else {
            yield put(setMessageAtPosition(message('administration.dashboardKpi.recalculate.error'), 'administrationDashboardKPI', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchRecalculateDashboardKPI (): Generator<ForkEffect> {
    yield takeLatest(RECALCULATE_DASHBOARD_KPI, recalculateDashboardKPISaga);
}
