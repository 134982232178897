import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/compensation';
import { CompensationOverview } from '~api/model/compensation';

import { processAxiosError } from '~helper/_processAxiosError';

import { resetAllActiveButtons } from '~modules/activeButton';
import { setCompensationOverview } from '~modules/compensationOverview';
import { DELETE_COMPENSATION, selectors, setCompensationToDelete } from '~modules/deleteCompensationDialog';
import { Message, setMessages } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * deleteCompensation (): Generator<StrictEffect, void, any> {
    try {
        const accessToken = yield select(oidcSelectors.accessToken);
        const compensationToDelete = yield select(selectors.compensationToDelete);

        const { data }: AxiosResponse<CompensationOverview> = yield call(api.deleteCompensation, compensationToDelete, accessToken);

        yield put(setCompensationOverview(data));
        yield put(setCompensationToDelete(null));
    } catch (error: any) {
        const { messages } = processAxiosError(error);
        const deleteCompensationDialogMessages: Message[] = messages.map((text) => ({
            text,
            severity: 'error',
            position: 'deleteCompensationDialog'
        }));

        yield put(setMessages(deleteCompensationDialogMessages));
    } finally {
        yield put(resetAllActiveButtons());
    }
}

export default function * watchDeleteCompensation (): Generator<ForkEffect> {
    yield takeLatest(DELETE_COMPENSATION, deleteCompensation);
}
