import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { PayoutStatusChangeConfirmDialogState } from './payoutStatusChangeConfirmDialog';

const root = (state: AppState): PayoutStatusChangeConfirmDialogState => state.payoutStatusChangeConfirmDialog;

export const usePayoutStatusChangeConfirmDialog = (): PayoutStatusChangeConfirmDialogState => useSelector(root);

export default {
    root,
};
