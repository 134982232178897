import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import api from '~api/administration';
import { VideoEvent } from '~api/model/videoEvent';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { resetMessages, setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export const PREFILL_VIDEO_EVENT = 'poms/test-data/PREFILL_VIDEO_EVENT';
export const prefillVideoEvent = createAction<VideoEvent>(PREFILL_VIDEO_EVENT);

export function * submitVideoEventSaga (
    action: ReturnType<typeof prefillVideoEvent>,
): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(resetMessages('administrationVideoEvents'));

        yield call(api.prefillVideoEvent, action.payload, accessToken);

        yield put(
            setMessageAtPosition(
                message('administration.videoEvent.prefill.success'),
                'administrationVideoEvents',
                'success',
            ),
        );
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(
                setMessageAtPosition(
                    message('administration.videoEvent.prefill.forbidden'),
                    'administrationVideoEvents',
                    'error',
                ),
            );
        } else {
            yield put(
                setMessageAtPosition(
                    message('administration.videoEvent.prefill.error'),
                    'administrationVideoEvents',
                    'error',
                ),
            );
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchSubmitVideoEvent (): Generator<ForkEffect> {
    yield takeEvery(PREFILL_VIDEO_EVENT, submitVideoEventSaga);
}
