import { createAction, createReducer } from '@reduxjs/toolkit';

import { BrandVideo } from '~api/model/brand-video';

export const SHOW_VIEW_ASSIGNMENT = 'poms/brandVideoViewAssignmentDialog/SHOW_VIEW_ASSIGNMENT';
export const EDIT_VIEW_ASSIGNMENT = 'poms/brandVideoViewAssignmentDialog/EDIT_VIEW_ASSIGNMENT';
export const HIDE_VIEW_ASSIGNMENT = 'poms/brandVideoViewAssignmentDialog/HIDE_VIEW_ASSIGNMENT';
export const SET_VIEWS_FOR_CREATOR = 'poms/brandVideoViewAssignmentDialog/SET_VIEWS_FOR_CREATOR';
export const SET_VIEWS_FOR_ALL_CREATORS = 'poms/brandVideoViewAssignmentDialog/SET_VIEWS_FOR_ALL_CREATORS';
export const ASSIGN_BRAND_VIDEO_VIEWS_TO_CREATORS = 'poms/brandVideoViewAssignmentDialog/ASSIGN_BRAND_VIDEO_VIEWS_TO_CREATORS';

export type BrandVideoViewAssignmentDialogState = {
    brandVideo: BrandVideo | null;
    disabled: boolean;
}

export const showBrandVideoViewAssignment = createAction<BrandVideo>(SHOW_VIEW_ASSIGNMENT);
export const editBrandVideoViewAssignment = createAction<BrandVideo>(EDIT_VIEW_ASSIGNMENT);
export const hideBrandVideoViewAssignment = createAction(HIDE_VIEW_ASSIGNMENT);
export const setBrandVideoViewsForCreator = createAction(SET_VIEWS_FOR_CREATOR, (liveViewDistribution: number, creatorId: string) => ({
    payload: {
        liveViewDistribution,
        creatorId,
    },
}));
export const setBrandVideoViewsForAllCreators = createAction<number[]>(SET_VIEWS_FOR_ALL_CREATORS);
export const assignBrandVideoViewsToCreators = createAction<BrandVideo>(ASSIGN_BRAND_VIDEO_VIEWS_TO_CREATORS);

const defaultState: BrandVideoViewAssignmentDialogState = {
    brandVideo: null,
    disabled: false,
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(showBrandVideoViewAssignment, (state, { payload }) => {
            state.brandVideo = payload;
            state.disabled = true;
        })
        .addCase(editBrandVideoViewAssignment, (state, { payload }) => {
            const liveViewDistribution = Math.floor(payload.video.liveViews / payload.assignedCreators.length);

            state.brandVideo = {
                ...payload,
                assignedCreators: payload.assignedCreators.map(creator => ({ ...creator, liveViewDistribution }))
            };
            state.disabled = false;
        })
        .addCase(hideBrandVideoViewAssignment, (state) => {
            state.brandVideo = null;
        })
        .addCase(setBrandVideoViewsForCreator, (state, { payload }) => {
            if (state.brandVideo) {
                for (const creator of state.brandVideo.assignedCreators) {
                    if (creator.creatorId === payload.creatorId) {
                        creator.liveViewDistribution = payload.liveViewDistribution;
                    }
                }
            }
        })
        .addCase(setBrandVideoViewsForAllCreators, (state, { payload }) => {
            if (state.brandVideo && payload.length === state.brandVideo.assignedCreators.length) {
                for (let i = 0; i < state.brandVideo.assignedCreators.length; i++) {
                    state.brandVideo.assignedCreators[i].liveViewDistribution = payload[i];
                }
            }
        });
});
