import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/creators';
import { CreatorsLevelChangeOverview } from '~api/model/creator';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREATORS_LEVEL_CHANGE_OVERVIEW,
    setCreatorsLevelChangeOverview,
    setCreatorsLevelChangeOverviewError,
    setCreatorsLevelChangeOverviewForbidden
} from '~modules/creatorsLevelChangeOverview';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorsLevelChangeOverview (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<CreatorsLevelChangeOverview[]> = yield call(api.fetchCreatorsLevelChangeOverview, accessToken);

        yield put(setCreatorsLevelChangeOverview(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorsLevelChangeOverviewForbidden());
        } else {
            yield put(setCreatorsLevelChangeOverviewError());
        }
    }
}

export default function * watchFetchCreatorsLevelChangeOverview (): Generator<ForkEffect> {
    yield takeLatest(FETCH_CREATORS_LEVEL_CHANGE_OVERVIEW, fetchCreatorsLevelChangeOverview);
}
