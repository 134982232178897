import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CreatorPayoutOverviewState } from './creatorPayoutOverview';

const root = (state: AppState): CreatorPayoutOverviewState => state.creatorPayoutOverview;

export const useCreatorPayoutOverview = (): CreatorPayoutOverviewState => useSelector(root);

export default {
    root,
};
