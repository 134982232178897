import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { resetMessages, setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export const REMOVE_ALL_TEST_CREATORS_AND_VIDEOS_ACTION = 'poms/test-video-events/REMOVE_ALL';

export const removeAllTestCreatorsAndVideos = createAction(REMOVE_ALL_TEST_CREATORS_AND_VIDEOS_ACTION);

export function * removeAllTestCreatorsAndVideosSaga (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(resetMessages('administrationVideoEvents'));

        yield call(api.removeAllTestCreatorsAndVideos, accessToken);

        yield put(
            setMessageAtPosition(
                message('administration.videoEvent.remove.success'),
                'administrationVideoEvents',
                'success',
            ),
        );
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(
                setMessageAtPosition(
                    message('administration.videoEvent.remove.forbidden'),
                    'administrationVideoEvents',
                    'error',
                ),
            );
        } else {
            yield put(
                setMessageAtPosition(
                    message('administration.videoEvent.remove.error'),
                    'administrationVideoEvents',
                    'error',
                ),
            );
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchRemoveAllTestCreatorsAndVideos (): Generator<ForkEffect> {
    yield takeLatest(REMOVE_ALL_TEST_CREATORS_AND_VIDEOS_ACTION, removeAllTestCreatorsAndVideosSaga);
}
