import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorBehaviourBeautyPoints } from '~api/model/creator';

export const FETCH_CREATOR_BEHAVIOUR_BEAUTY_POINTS = 'poms/creatorBehaviourBeautyPoints/FETCH';
export const SET_CREATOR_BEHAVIOUR_BEAUTY_POINTS = 'poms/creatorBehaviourBeautyPoints/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorBehaviourBeautyPointsState = {
    overview: CreatorBehaviourBeautyPoints[] | null;
    fetchState: FetchState;
};

export const fetchCreatorBehaviourBeautyPoints = createAction<string>(FETCH_CREATOR_BEHAVIOUR_BEAUTY_POINTS);

export const setCreatorBehaviourBeautyPoints = createAction(
    SET_CREATOR_BEHAVIOUR_BEAUTY_POINTS,
    (overview: CreatorBehaviourBeautyPoints[] | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            overview,
            fetchState
        }
    })
);

export type FetchCreatorBehaviourBeautyPoints = ReturnType<typeof fetchCreatorBehaviourBeautyPoints>;

export type SetCreatorBehaviourBeautyPoints = ReturnType<typeof setCreatorBehaviourBeautyPoints>;

export const setCreatorBehaviourBeautyPointsForbidden = (): SetCreatorBehaviourBeautyPoints =>
    setCreatorBehaviourBeautyPoints(null, 'FORBIDDEN');

export const setCreatorBehaviourBeautyPointsError = (): SetCreatorBehaviourBeautyPoints =>
    setCreatorBehaviourBeautyPoints(null, 'ERROR');

export const resetCreatorBehaviourBeautyPoints = (): SetCreatorBehaviourBeautyPoints =>
    setCreatorBehaviourBeautyPoints(null, 'INIT');

const defaultState: CreatorBehaviourBeautyPointsState = {
    overview: null,
    fetchState: 'INIT'
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreatorBehaviourBeautyPoints, (state) => ({
            ...state,
            fetchState: 'FETCHING'
        }))
        .addCase(setCreatorBehaviourBeautyPoints, (state, { payload }) => ({
            ...state,
            ...payload
        }));
});
