import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatorPayoutOverview } from '~api/model/creator';

// type declaration
export const FETCH_CREATOR_PAYOUT_OVERVIEW = 'poms/creatorPayoutOverview/FETCH';
export const SET_CREATOR_PAYOUT_OVERVIEW = 'poms/creatorPayoutOverview/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorPayoutOverviewState = {
    overview: CreatorPayoutOverview | null;
    fetchState: FetchState;
};

// actions
export const fetchCreatorPayoutOverview = createAction<string>(FETCH_CREATOR_PAYOUT_OVERVIEW);

export const setCreatorPayoutOverview = createAction(
    SET_CREATOR_PAYOUT_OVERVIEW,
    (overview: CreatorPayoutOverview | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            overview,
            fetchState,
        }
    }));

export type FetchCreatorPayoutOverviewAction = ReturnType<typeof fetchCreatorPayoutOverview>;

export type SetCreatorPayoutOverviewAction = ReturnType<typeof setCreatorPayoutOverview>;

export const setCreatorPayoutOverviewForbidden = (): SetCreatorPayoutOverviewAction =>
    setCreatorPayoutOverview(null, 'FORBIDDEN');

export const setCreatorPayoutOverviewError = (): SetCreatorPayoutOverviewAction =>
    setCreatorPayoutOverview(null, 'ERROR');

export const resetCreatorPayoutOverview = (): SetCreatorPayoutOverviewAction =>
    setCreatorPayoutOverview(null, 'INIT');

// reducer
const defaultState: CreatorPayoutOverviewState = {
    overview: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreatorPayoutOverview, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCreatorPayoutOverview, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
