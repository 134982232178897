import { createAction, createReducer } from '@reduxjs/toolkit';

import { AdministrativeActions } from '~api/model/administrativeActions';

// type declaration
export const FETCH_ADMINISTRATIVE_ACTIONS = 'poms/administrativeActions/FETCH';
export const SET_ADMINISTRATIVE_ACTIONS = 'poms/administrativeActions/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type administrativeActionsState = {
    administrativeActions: AdministrativeActions | null;
    fetchState: FetchState;
};

// actions
export const fetchAdministrativeActions = createAction(FETCH_ADMINISTRATIVE_ACTIONS);

export const setAdministrativeActions = createAction(
    SET_ADMINISTRATIVE_ACTIONS,
    (administrativeActions: AdministrativeActions | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            administrativeActions,
            fetchState,
        },
    }),
);

export const setAdministrativeActionsForbidden = (): ReturnType<typeof setAdministrativeActions> =>
    setAdministrativeActions(null, 'FORBIDDEN');

export const setAdministrativeActionsError = (): ReturnType<typeof setAdministrativeActions> =>
    setAdministrativeActions(null, 'ERROR');

// reducer
const defaultState: administrativeActionsState = {
    administrativeActions: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchAdministrativeActions, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setAdministrativeActions, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
