import { createAction, createReducer } from '@reduxjs/toolkit';

import { CashDashboardTileDetails } from '~api/model/dashboardAggregations';

// type declaration
export const FETCH_COLLECTED_CASH_DASHBOARD_DETAILS = 'poms/collectedCashDashboardDetails/FETCH';
export const SET_COLLECTED_CASH_DASHBOARD_DETAILS = 'poms/collectedCashDashboardDetails/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CollectedCashDashboardDetailsState = {
    dashboardDetails: CashDashboardTileDetails | null;
    fetchState: FetchState;
};

// actions
export const fetchCollectedCashDashboardDetails = createAction(
    FETCH_COLLECTED_CASH_DASHBOARD_DETAILS,
    (creatorId?: string | null) => ({
        payload: null,
        meta: {
            dataType: 'collectedCash',
            creatorId,
        }
    }));

export const setCollectedCashDashboardDetails = createAction(
    SET_COLLECTED_CASH_DASHBOARD_DETAILS,
    (dashboardDetails: CashDashboardTileDetails | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            dashboardDetails,
            fetchState,
        }
    }));

export type FetchCollectedCashDashboardDetailsAction = ReturnType<typeof fetchCollectedCashDashboardDetails>;

export type SetCollectedCashDashboardDetailsAction = ReturnType<typeof setCollectedCashDashboardDetails>;

export const setCollectedCashDashboardDetailsForbidden = (): SetCollectedCashDashboardDetailsAction =>
    setCollectedCashDashboardDetails(null, 'FORBIDDEN');

export const setCollectedCashDashboardDetailsError = (): SetCollectedCashDashboardDetailsAction =>
    setCollectedCashDashboardDetails(null, 'ERROR');

export const resetCollectedCashDashboardDetails = (): SetCollectedCashDashboardDetailsAction =>
    setCollectedCashDashboardDetails(null, 'INIT');

// reducer
const defaultState: CollectedCashDashboardDetailsState = {
    dashboardDetails: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCollectedCashDashboardDetails, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCollectedCashDashboardDetails, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
