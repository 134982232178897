import { createSelector } from 'reselect';

import { AppState } from '~modules/index';
import { PayoutCycleState } from '~modules/payoutCycles/payoutCycles';

const root = (state: AppState): PayoutCycleState => state.payoutCycles;
const data = createSelector(root, (root) => root.data);

export default {
    root,
    data,
};
