import { createAction, createReducer } from '@reduxjs/toolkit';

import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';

// type declaration
export const FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PAYOUTS_CREATED = 'poms/payout-cycle-runs-process-steps-payouts-created/FETCH';
export const SET_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PAYOUTS_CREATED = 'poms/payout-cycle-runs-process-steps-steps-payouts-created/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type PayoutCycleRunsProcessStepPayoutsCreatedState = {
    processSteps: PayoutCycleRunProcessSteps | null;
    fetchState: FetchState;
}

// actions
export const fetchPayoutCycleRunsProcessStepsPayoutsCreated = createAction(FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PAYOUTS_CREATED);

export const setPayoutCycleRunsProcessStepsPayoutsCreated = createAction(
    SET_PAYOUT_CYCLE_RUN_PROCESS_STEPS_PAYOUTS_CREATED,
    (processSteps: PayoutCycleRunProcessSteps | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            processSteps,
            fetchState,
        },
    }),
);

export const setPayoutCycleRunsProcessStepsPayoutsCreatedForbidden = (): ReturnType<typeof setPayoutCycleRunsProcessStepsPayoutsCreated> =>
    setPayoutCycleRunsProcessStepsPayoutsCreated(null, 'FORBIDDEN');

export const setPayoutCycleRunsProcessStepsPayoutsCreatedError = (): ReturnType<typeof setPayoutCycleRunsProcessStepsPayoutsCreated> =>
    setPayoutCycleRunsProcessStepsPayoutsCreated(null, 'ERROR');

// reducer
const defaultState: PayoutCycleRunsProcessStepPayoutsCreatedState = {
    processSteps: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchPayoutCycleRunsProcessStepsPayoutsCreated, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setPayoutCycleRunsProcessStepsPayoutsCreated, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
