import { useDispatch } from 'react-redux';

import { useInitialLoad } from '~hooks/useInitialLoad';

import { fetchFeatureToggles } from '~modules/featureToggles';

export type FeatureTogglesProviderProps = {
    children: React.ReactNode;
};

export const FeatureTogglesProvider: React.FC<FeatureTogglesProviderProps> = ({ children }) => {
    const dispatch = useDispatch();

    useInitialLoad(() => {
        dispatch(fetchFeatureToggles());
    });

    return <>{ children }</>;
};
