import { createAction, createReducer } from '@reduxjs/toolkit';

import { ScepCreatorEvents } from '~api/model/administration';

export const FETCH_SCEP_CREATOR_EVENTS = 'poms/scepCreatorEvents/FETCH';
export const SET_SCEP_CREATOR_EVENTS = 'poms/scepCreatorEvents/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type ScepCreatorEventsState = {
    events: ScepCreatorEvents | null;
    fetchState: FetchState;
    searchParameter: { cdcId?: string; date?: number };
}

export const fetchScepCreatorEvents = createAction(
    FETCH_SCEP_CREATOR_EVENTS,
    (cdcId?: string, date?: number) => ({
        payload: {
            cdcId,
            date,
        }
    }));

export const setScepCreatorEvents = createAction(
    SET_SCEP_CREATOR_EVENTS,
    (events: ScepCreatorEvents | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            events,
            fetchState
        }
    })
);

export type FetchScepCreatorEvents = ReturnType<typeof fetchScepCreatorEvents>;

export type SetScepCreatorEvents = ReturnType<typeof setScepCreatorEvents>;

export const setScepCreatorEventsForbidden = (): SetScepCreatorEvents =>
    setScepCreatorEvents(null, 'FORBIDDEN');

export const setScepCreatorEventsError = (): SetScepCreatorEvents =>
    setScepCreatorEvents(null, 'ERROR');

export const resetScepCreatorEvents = (): SetScepCreatorEvents =>
    setScepCreatorEvents(null, 'INIT');

const defaultState: ScepCreatorEventsState = {
    events: null,
    fetchState: 'INIT',
    searchParameter: { cdcId: undefined, date: undefined }
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchScepCreatorEvents, (state, { payload }) => ({
            ...state,
            searchParameter: payload,
            fetchState: 'FETCHING'
        }))
        .addCase(setScepCreatorEvents, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
