import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { CreatorVideosPaginationOptions, FilterableCreatorVideosTableFields, Video } from '~api/model/creator';

import { AppState } from '~modules/index';

import { CreatorVideoOverviewState, CreatorVideosPaginationType, FetchState } from './creatorVideosOverview';

export const root = (state: AppState): CreatorVideoOverviewState => state.creatorVideosOverview;

const videosOverview = createSelector(root, (root) => root.videosOverview);
const pagination = createSelector(root, (root) => root.pagination);

export const filteredAndSortedCreatorContent = createSelector(
    videosOverview,
    pagination,
    (contentOverview, pagination) => (Object.keys(pagination) as CreatorVideosPaginationType[]).reduce((prev, current) => {
        const { filter, sort } = pagination[current];

        return ({
            ...prev,
            [current]: (contentOverview?.[`${current}Overview`] ?? [])
                .filter((content) => {
                    if (!filter) {
                        return true;
                    }

                    return !Object.entries(filter).some(([ filterKey, filterValue ]) => {
                        const filterKeyToUse = filterKey as keyof FilterableCreatorVideosTableFields;

                        if (!content[filterKeyToUse]) {
                            return true;
                        }

                        const element = content[filterKeyToUse] as string;

                        return element?.toLowerCase().indexOf(filterValue?.toLowerCase() || '') < 0 || false;
                    });
                })
                .sort((creatorA, creatorB) => {
                    if (!sort) {
                        return 0;
                    }

                    if (creatorA[sort.by] === null && creatorB[sort.by] === null) {
                        return 0;
                    }
                    if (creatorA[sort.by] === null) {
                        return sort.direction === 'DESC' ? -1 : 1;
                    }
                    if (creatorB[sort.by] === null) {
                        return sort.direction === 'DESC' ? 1 : -1;
                    }

                    // @ts-ignore
                    if (creatorA[sort.by] < creatorB[sort.by]) {
                        return sort.direction === 'DESC' ? 1 : -1;
                    }

                    // @ts-ignore
                    if (creatorA[sort.by] > creatorB[sort.by]) {
                        return sort.direction === 'DESC' ? -1 : 1;
                    }

                    return 0;
                }),
        });
    }, {}) as Record<CreatorVideosPaginationType, Video[]>
);

export const paginatedFilteredAndSortedCreatorContent = createSelector(
    filteredAndSortedCreatorContent,
    pagination, (content, pagination) => (Object.keys(pagination) as CreatorVideosPaginationType[]).reduce((prev, current) => {
        const { page, resultsPerPage } = pagination[current];

        return { ...prev, [current]: content[current].slice(page * resultsPerPage, ((page + 1) * resultsPerPage)) };
    }, {}) as Record<CreatorVideosPaginationType, Video[]>
);

export const useCreatorContentOverview = (): CreatorVideoOverviewState => useSelector(root);
export const useCreatorContentFetchState = (): FetchState => useSelector(root).fetchState;
export const useCreatorContentPagination = (contentType: CreatorVideosPaginationType): CreatorVideosPaginationOptions => useSelector(pagination)[contentType];
export const useFilteredAndSortedCreatorContent = (contentType: CreatorVideosPaginationType): Video[] => useSelector(filteredAndSortedCreatorContent)[contentType];
export const usePaginatedFilteredAndSortedCreatorContent = (contentType: CreatorVideosPaginationType): Video[] => useSelector(paginatedFilteredAndSortedCreatorContent)[contentType];

export default {
    root,
};
