import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreatePayoutCorrectionForm } from '~api/model/payout-correction';

// type declarations
export const SET_CREATE_PAYOUT_CORRECTION_DIALOG_OPEN = 'poms/createPayoutCorrection/SET_OPEN';
export const CREATE_PAYOUT_CORRECTION = 'poms/createPayoutCorrection/SUBMIT_COMPENSATION';
export const SET_SUBMIT_IN_PROGRESS = 'poms/createPayoutCorrection/SET_SUBMIT_IN_PROGRESS';

export type CreatePayoutCorrectionDialogState = {
    open: boolean;
    isSubmitInProgress: boolean;
};

// actions
export const setCreatePayoutCorrectionDialogOpen = createAction<boolean>(SET_CREATE_PAYOUT_CORRECTION_DIALOG_OPEN);

export const createPayoutCorrection = createAction<CreatePayoutCorrectionForm>(CREATE_PAYOUT_CORRECTION);

export const setSubmitInProgress = createAction<boolean>(SET_SUBMIT_IN_PROGRESS);

// reducer
const defaultState: CreatePayoutCorrectionDialogState = {
    open: false,
    isSubmitInProgress: false,
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setCreatePayoutCorrectionDialogOpen, (state, { payload }) => ({
            ...state,
            open: payload,
            isSubmitInProgress: false,
        }))
        .addCase(createPayoutCorrection, (state) => ({
            ...state,
            isSubmitInProgress: true,
        }))
        .addCase(setSubmitInProgress, (state, { payload }) => ({
            ...state,
            isSubmitInProgress: payload,
        }));
});
