export enum Endpoints {
    ASSIGN_BRAND_VIDEO_VIEWS = '/api/backend/brand-videos/{0}/assign-creators',
    HIDE_BRAND_VIDEO = '/api/backend/brand-videos/{0}/hide',
    SHOW_BRAND_VIDEO = '/api/backend/brand-videos/{0}/show',
    BRAND_VIDEOS_OVERVIEW = '/api/backend/brand-videos',
    COMPENSATION_OVERVIEW = '/api/backend/compensation/overview',
    COMPENSATION_RULE_SET = '/api/backend/compensation/rule-set',
    CREATOR_OF_PAYOUT_CYCLE = '/api/backend/payout-cycles/run/{0}/creators',
    CREATORS = '/api/backend/creators',
    CREATOR_CORE_INFORMATION = '/api/backend/creator/{0}',
    CREATOR_DASHBOARD_AGGREGATIONS = '/api/backend/creator/{0}/dashboard',
    VIDEOS_CREATOR_DASHBOARD_DETAILS = '/api/backend/creator/{0}/dashboard/details/videos',
    BILLABLE_VIEWS_CREATOR_DASHBOARD_DETAILS = '/api/backend/creator/{0}/dashboard/details/billable-views',
    COMPENSABLE_VIEWS_CREATOR_DASHBOARD_DETAILS = '/api/backend/creator/{0}/dashboard/details/compensable-views',
    COLLECTED_BEAUTY_POINTS_CREATOR_DASHBOARD_DETAILS = '/api/backend/creator/{0}/dashboard/details/collected-beauty-points',
    COLLECTED_CASH_CREATOR_DASHBOARD_DETAILS = '/api/backend/creator/{0}/dashboard/details/collected-cash',
    PAID_OUT_BEAUTY_POINTS_CREATOR_DASHBOARD_DETAILS = '/api/backend/creator/{0}/dashboard/details/paid-out-beauty-points',
    PAID_OUT_CASH_CREATOR_DASHBOARD_DETAILS = '/api/backend/creator/{0}/dashboard/details/paid-out-cash',
    CREATE_PAYOUT_CORRECTION = '/api/backend/payout-corrections',
    CREATOR_CONTENT_OVERVIEW = '/api/backend/creator/{0}/content-overview',
    CREATOR_BEHAVIOUR_BEAUTY_POINTS = '/api/backend/creator/{0}/behaviour-points',
    CREATOR_CHANGES = '/api/backend/creator/{0}/changes',
    CREATOR_PAYOUT_CORRECTIONS = '/api/backend/payout-corrections/for-creator/{0}',
    CREATORS_LEVEL_CHANGE_OVERVIEW = '/api/backend/creators/level-change-overview',
    CREATOR_PAYOUT_OVERVIEW = '/api/backend/creator/{0}/payouts',
    CREATOR_ESTIMATED_PAYOUT_INFORMATION = '/api/backend/creator/{0}/payout/estimation',
    CREATOR_UPDATE_LEVEL = '/api/backend/creator/{0}/level',
    CREATOR_UPDATE_PAYOUT_METHOD = '/api/backend/creator/{0}/payout-method',
    CREDIT_NOTES_WITH_PROCESSING_ERROR = '/api/backend/credit-notes/with-processing-error',
    CREATORS_DASHBOARD_DETAILS = '/api/backend/dashboard-aggregations/details/creator',
    VIDEOS_DASHBOARD_DETAILS = '/api/backend/dashboard-aggregations/details/videos',
    DASHBOARD_AGGREGATIONS = '/api/backend/dashboard-aggregations',
    DASHBOARD_PAYOUT_SUMMARY = '/api/backend/dashboard-payout-summary',
    FEATURE_TOGGLES = '/feature-toggles/api/client/features',
    BILLABLE_VIEWS_DASHBOARD_DETAILS = '/api/backend/dashboard-aggregations/details/billable-views',
    COMPENSABLE_VIEWS_DASHBOARD_DETAILS = '/api/backend/dashboard-aggregations/details/compensable-views',
    COLLECTED_BEAUTY_POINTS_DASHBOARD_DETAILS = '/api/backend/dashboard-aggregations/details/collected-beauty-points',
    COLLECTED_CASH_DASHBOARD_DETAILS = '/api/backend/dashboard-aggregations/details/collected-cash',
    PAID_OUT_BEAUTY_POINTS_DASHBOARD_DETAILS = '/api/backend/dashboard-aggregations/details/paid-out-beauty-points',
    PAID_OUT_CASH_DASHBOARD_DETAILS = '/api/backend/dashboard-aggregations/details/paid-out-cash',
    INIT_TEST_DATA = '/api/backend/test-data/init-test-data',
    PAYOUT_CYCLE_RUN_DETAILS = '/api/backend/payout-cycles/run/{0}/details',
    PAYOUT_CYCLE_RUN_ESTIMATION = '/api/backend/payout-cycles/{0}/estimate-payout',
    PAYOUT_CYCLE_RUNS_OVERVIEW = '/api/backend/payout-cycles/{0}/payout-cycle-runs',
    PAYOUT_CYCLE_RUNS_CALCULATED = '/api/backend/admin/payout-cycle-process-steps/calculated',
    PAYOUT_CYCLE_RUNS_MARKED_FOR_CALCULATION = '/api/backend/admin/payout-cycle-process-steps/marked-for-calculation',
    PAYOUT_CYCLE_RUNS_PAYOUTS_CREATED = '/api/backend/admin/payout-cycle-process-steps/payouts-created',
    PAYOUT_CYCLE_RUNS_PUBLISHED = '/api/backend/admin/payout-cycle-process-steps/published',
    PAYOUT_CYCLE_RUNS_RECALCULATABLE = '/api/backend/admin/payout-cycle-process-steps/recalculatable',
    PAYOUT_CYCLES = '/api/backend/payout-cycles',
    RECALCULATE_CREATOR_LEVELS = '/api/backend/admin/recalculate-creator-levels',
    RECALCULATE_DASHBOARD_KPI = '/api/backend/admin/recalculate-dashboard-kpi',
    ADMINISTRATIVE_ACTIONS = '/api/backend/admin/administrative-actions',
    RESUME_PAYOUT = '/api/backend/payout-cycles/run/{0}/forCreator/{1}/resume',
    STOP_PAYOUT = '/api/backend/payout-cycles/run/{0}/forCreator/{1}/stop',
    SET_PAYOUT_ON_HOLD = '/api/backend/payout-cycles/run/{0}/forCreator/{1}/hold',
    RESUME_PAYOUT_CYCLE_RUN = '/api/backend/payout-cycles/run/{0}/resume',
    SCEP_LOOKUP = '/api/backend/admin/creator-video-events',
    SEND_TEST_MAILS = '/api/backend/test-mail/send-mails',
    SET_PAYOUT_CYCLE_RUN_ON_HOLD = '/api/backend/payout-cycles/run/{0}/hold',
    START_TEST_PAYOUT = '/api/backend/test-data/start-test-payout',
    SHOW_CREDIT_NOTE_DOCUMENTATION_DRAFT = '/api/backend/payout-cycles/run/{0}/forCreator/{1}/credit-note-document-draft',
    TEST_VIDEO_EVENTS = '/api/backend/test-video-events',
    CREATOR_VIDEO_EVENTS = '/api/backend/test-video-events/{0}',
    CALCULATE_PAYOUT_DATE = '/api/backend/admin/calculate-next-payout-date',
    GENERATE_PAYOUTS_FOR_PAYOUT_CYCLE_RUN = '/api/backend/admin/generate-payouts/{0}',
    GENERATE_PAYOUT_RUN_FOR_PAYOUT_CYCLE = '/api/backend/admin/generate-payout-run/{0}',
    PUBLISH_CALCULATED_PAYOUT_CYCLE_RUN = '/api/backend/admin/publish-calculated-payout-cycle-run/{0}',
    REVERSE_PUBLISHED_PAYOUT_CYCLE_RUN = '/api/backend/admin/reverse-published-payout-cycle-run/{0}',
    RECALCULATE_PAYOUT_CYCLE_RUN = '/api/backend/admin/recalculate-payout-cycle-run/{0}',
}
