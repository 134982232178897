import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { BrandVideoViewAssignmentDialogState } from './brandVideoViewAssignmentDialog';

const root = (state: AppState): BrandVideoViewAssignmentDialogState => state.brandVideoViewAssignmentDialog;

export const useBrandVideoViewAssignmentDialog = (): BrandVideoViewAssignmentDialogState => useSelector(root);

export default {
    root,
};
