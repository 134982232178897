import { createAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons } from '~modules/activeButton';
import { fetchAdministrativeActions } from '~modules/administrativeActions';
import { resetMessages, setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';

export const INITIALIZE_TEST_DATA_ACTION = 'poms/test-data/INITIALIZE';

export const initializeTestData = createAction(INITIALIZE_TEST_DATA_ACTION);

export function * initializeTestDataSaga (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        yield put(resetMessages('administrationTestData'));

        yield call(api.initTestData, accessToken);

        yield put(setMessageAtPosition(message('administration.testData.initTestData.success'), 'administrationTestData', 'success'));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('administration.testData.initTestData.forbidden'), 'administrationTestData', 'error'));
        } else {
            yield put(setMessageAtPosition(message('administration.testData.initTestData.error'), 'administrationTestData', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
        yield put(fetchAdministrativeActions());
    }
}

export default function * watchInitializeTestData (): Generator<ForkEffect> {
    yield takeLatest(INITIALIZE_TEST_DATA_ACTION, initializeTestDataSaga);
}
