import { createAction, createReducer } from '@reduxjs/toolkit';

import { VideosDashboardTileDetails } from '~api/model/dashboardAggregations';

// type declaration
export const FETCH_VIDEOS_DASHBOARD_DETAILS = 'poms/videosDashboardDetails/FETCH';
export const SET_VIDEOS_DASHBOARD_DETAILS = 'poms/videosDashboardDetails/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type VideosDashboardDetailsState = {
    dashboardDetails: VideosDashboardTileDetails | null;
    fetchState: FetchState;
};

// actions
export const fetchVideosDashboardDetails = createAction(
    FETCH_VIDEOS_DASHBOARD_DETAILS,
    (creatorId?: string | null) => ({
        payload: null,
        meta: {
            dataType: 'videos',
            creatorId,
        },
    })
);

export const setVideosDashboardDetails = createAction(
    SET_VIDEOS_DASHBOARD_DETAILS,
    (dashboardDetails: VideosDashboardTileDetails | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            dashboardDetails,
            fetchState,
        }
    }));

export type FetchVideosDashboardDetailsAction = ReturnType<typeof fetchVideosDashboardDetails>;

export type SetVideosDashboardDetailsAction = ReturnType<typeof setVideosDashboardDetails>;

export const setVideosDashboardDetailsForbidden = (): SetVideosDashboardDetailsAction =>
    setVideosDashboardDetails(null, 'FORBIDDEN');

export const setVideosDashboardDetailsError = (): SetVideosDashboardDetailsAction =>
    setVideosDashboardDetails(null, 'ERROR');

export const resetVideosDashboardDetails = (): SetVideosDashboardDetailsAction =>
    setVideosDashboardDetails(null, 'INIT');

// reducer
const defaultState: VideosDashboardDetailsState = {
    dashboardDetails: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchVideosDashboardDetails, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setVideosDashboardDetails, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
