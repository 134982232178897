import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { BrandVideoCreatorSelectionDialogState } from './brandVideoCreatorSelectionDialog';

const root = (state: AppState): BrandVideoCreatorSelectionDialogState => state.brandVideoCreatorSelectionDialog;

export const useBrandVideoCreatorSelectionDialog = (): BrandVideoCreatorSelectionDialogState => useSelector(root);

export default {
    root,
};
