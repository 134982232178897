import { createAction, createReducer } from '@reduxjs/toolkit';

import { VideoEvent } from '~api/model/videoEvent';

// type declarations
export const FETCH_CREATOR_VIDEO_EVENTS = 'poms/videoEvents/FETCH';
export const SET_CREATOR_VIDEO_EVENTS = 'poms/videoEvents/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreatorVideoEventsState = {
    creatorVideoEvents: VideoEvent[] | null;
    fetchState: FetchState;
};

// actions
export const fetchCreatorVideoEvents = createAction<string>(FETCH_CREATOR_VIDEO_EVENTS);
export const setCreatorVideoEvents = createAction(
    SET_CREATOR_VIDEO_EVENTS,
    (creatorVideoEvents: VideoEvent[] | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: { creatorVideoEvents, fetchState },
    }),
);

export const setCreatorVideoEventsForbidden = (): ReturnType<typeof setCreatorVideoEvents> =>
    setCreatorVideoEvents(null, 'FORBIDDEN');

export const setCreatorVideoEventsError = (): ReturnType<typeof setCreatorVideoEvents> =>
    setCreatorVideoEvents(null, 'ERROR');

// reducer
const defaultState: CreatorVideoEventsState = {
    creatorVideoEvents: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchCreatorVideoEvents, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCreatorVideoEvents, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
