import { Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';
import deLocale from 'date-fns/locale/de';
import type { AppContext, AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { OidcProvider } from 'redux-oidc';

import { getFormattedEnvironment, message } from '~helper/_common';
import { useUserManager } from '~helper/_openid-user-manager';

import { LoadingPage } from '~components/pages/Loading';
import { FeatureTogglesProvider } from '~components/utils/FeatureTogglesProvider';

import { useStore } from '~store/store';

import theme from '~styles/theme';
import '~styles/globals.scss';

const PayoutManagementServiceHeader = () => (
    <Head>
        <title>{ message('title', getFormattedEnvironment()) }</title>
        <link rel="icon" type="image/png" href="https://www.douglas.de/public/favicon-196x196.png" sizes="196x196" />
    </Head>
);

const LoadingApp = () => (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={ theme }>
            <PayoutManagementServiceHeader />
            <LoadingPage />
        </ThemeProvider>
    </StyledEngineProvider>
);

function PayoutManagementServiceApp ({ Component, pageProps }: AppProps): JSX.Element {
    const store = useStore();
    const userManager = useUserManager(store);

    if (!userManager || !store) {
        return <LoadingApp />;
    }

    return (
        <Sentry.ErrorBoundary>
            <PayoutManagementServiceHeader />
            <Provider store={ store }>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={ theme }>
                        { /* @ts-ignore */ }
                        <OidcProvider userManager={ userManager } store={ store }>
                            <FeatureTogglesProvider>
                                <LocalizationProvider
                                    adapterLocale={ deLocale }
                                    dateAdapter={ AdapterDateFns }
                                    utils={ DateFnsUtils }>
                                    <Component { ...pageProps } />
                                </LocalizationProvider>
                            </FeatureTogglesProvider>
                        </OidcProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Provider>
        </Sentry.ErrorBoundary>
    );
}

// Capture errors that happen during a page's getInitialProps.
// This will work on both client and server sides.
PayoutManagementServiceApp.getInitialProps = async ({ Component, ctx }: AppContext) => {
    if (!Component.getInitialProps) {
        return {};
    }

    const pageProps = await Component.getInitialProps(ctx);

    return { pageProps };
};

export default dynamic(() => Promise.resolve(PayoutManagementServiceApp), {
    loading: LoadingApp,
    ssr: false,
});
