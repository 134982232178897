import { createAction, createReducer } from '@reduxjs/toolkit';

import { ViewsDashboardTileDetails } from '~api/model/dashboardAggregations';

// type declaration
export const FETCH_BILLABLE_VIEWS_DASHBOARD_DETAILS = 'poms/billableViewsDashboardDetails/FETCH';
export const SET_BILLABLE_VIEWS_DASHBOARD_DETAILS = 'poms/billableViewsDashboardDetails/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type BillableViewsDashboardDetailsState = {
    dashboardDetails: ViewsDashboardTileDetails | null;
    fetchState: FetchState;
};

// actions
export const fetchBillableViewsDashboardDetails = createAction(
    FETCH_BILLABLE_VIEWS_DASHBOARD_DETAILS,
    (creatorId?: string | null) => ({
        payload: null,
        meta: {
            dataType: 'billableViews',
            creatorId,
        }
    }));

export const setBillableViewsDashboardDetails = createAction(
    SET_BILLABLE_VIEWS_DASHBOARD_DETAILS,
    (dashboardDetails: ViewsDashboardTileDetails | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            dashboardDetails,
            fetchState,
        }
    }));

export type FetchBillableViewsDashboardDetailsAction = ReturnType<typeof fetchBillableViewsDashboardDetails>;

export type SetBillableViewsDashboardDetailsAction = ReturnType<typeof setBillableViewsDashboardDetails>;

export const setBillableViewsDashboardDetailsForbidden = (): SetBillableViewsDashboardDetailsAction =>
    setBillableViewsDashboardDetails(null, 'FORBIDDEN');

export const setBillableViewsDashboardDetailsError = (): SetBillableViewsDashboardDetailsAction =>
    setBillableViewsDashboardDetails(null, 'ERROR');

export const resetBillableViewsDashboardDetails = (): SetBillableViewsDashboardDetailsAction =>
    setBillableViewsDashboardDetails(null, 'INIT');

// reducer
const defaultState: BillableViewsDashboardDetailsState = {
    dashboardDetails: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(fetchBillableViewsDashboardDetails, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setBillableViewsDashboardDetails, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
