import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/dashboard-aggregations';
import { ViewsDashboardTileDetails } from '~api/model/dashboardAggregations';

import { HttpStatus } from '~constants/http';

import { getRangeFromFilter } from '~helper/range-filter';

import {
    FETCH_BILLABLE_VIEWS_DASHBOARD_DETAILS,
    FetchBillableViewsDashboardDetailsAction,
    setBillableViewsDashboardDetails,
    setBillableViewsDashboardDetailsError,
    setBillableViewsDashboardDetailsForbidden,
} from '~modules/billableViewsDashboardDetails';
import {
    selectors as dashboardDetailTileDateFilterSelectors,
    SET_DASHBOARD_DETAIL_TILE_DATE_FILTER,
    SetDashboardDetailTileDateFilterAction,
} from '~modules/dashboardDetailTileDateFilter';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchBillableViewsDashboardDetails (action: FetchBillableViewsDashboardDetailsAction | SetDashboardDetailTileDateFilterAction): Generator<StrictEffect, void, any> {
    const { dataType, creatorId } = action.meta;

    if (action.type === SET_DASHBOARD_DETAIL_TILE_DATE_FILTER && dataType !== 'billableViews') {
        return;
    }

    const accessToken = yield select(oidcSelectors.accessToken);
    const rangeFilter = yield select(dashboardDetailTileDateFilterSelectors.root);
    const range = getRangeFromFilter(rangeFilter);

    try {
        const { data }: AxiosResponse<ViewsDashboardTileDetails> = creatorId
            ? yield call(api.fetchBillableViewsCreatorDashboardDetails, creatorId, range.from, range.to, accessToken)
            : yield call(api.fetchBillableViewsDashboardDetails, range.from, range.to, accessToken);

        yield put(setBillableViewsDashboardDetails(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setBillableViewsDashboardDetailsForbidden());
        } else {
            yield put(setBillableViewsDashboardDetailsError());
        }
    }
}

export default function * watchFetchViewsDashboardDetails (): Generator<ForkEffect> {
    yield takeLatest(FETCH_BILLABLE_VIEWS_DASHBOARD_DETAILS, fetchBillableViewsDashboardDetails);
    yield takeLatest(SET_DASHBOARD_DETAIL_TILE_DATE_FILTER, fetchBillableViewsDashboardDetails);
}
