import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { CreatorsLevelChangeOverviewState } from './creatorsLevelChangeOverview';

const root = (state: AppState): CreatorsLevelChangeOverviewState => state.creatorsLevelChangeOverview;

export const useCreatorsLevelChangeOverview = (): CreatorsLevelChangeOverviewState => useSelector(root);

export default {
    root,
};
