import { useSelector } from 'react-redux';

import { ActiveButtonStateType } from '~modules/activeButton/activeButton';
import { AppState } from '~modules/index';

const root = (state: AppState): ActiveButtonStateType => state.activeButton;

export const useActiveButton = (): ActiveButtonStateType => useSelector(root);

export default {
    root,
};
