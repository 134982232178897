import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/dashboard-aggregations';
import { CreatorDashboardAggregations } from '~api/model/dashboardAggregations';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREATOR_DASHBOARD_AGGREGATIONS,
    FetchCreatorDashboardAggregationsAction,
    setCreatorDashboardAggregations,
    setCreatorDashboardAggregationsError,
    setCreatorDashboardAggregationsForbidden,
} from '~modules/creatorDashboardAggregations';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorDashboardAggregations (action: FetchCreatorDashboardAggregationsAction): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<CreatorDashboardAggregations> = yield call(api.fetchCreatorDashboardAggregations, action.payload, accessToken);

        yield put(setCreatorDashboardAggregations(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorDashboardAggregationsForbidden());
        } else {
            yield put(setCreatorDashboardAggregationsError());
        }
    }
}

export default function * watchFetchCreatorDashboardAggregations (): Generator<ForkEffect> {
    yield takeLatest(FETCH_CREATOR_DASHBOARD_AGGREGATIONS, fetchCreatorDashboardAggregations);
}
