import { AxiosRequestConfig } from 'axios';

import { Endpoints } from '~constants/endpoints';

export const addAuthorizationHeader = (accessToken: string, config?: AxiosRequestConfig): AxiosRequestConfig => ({
    ...config,
    headers: {
        ...config?.headers,
        Authorization: `Bearer ${accessToken}`,
    },
});

export const endpoint = (endpoint: Endpoints, ...placeholders: string[]): string => {
    const placeholderArray: string[] = placeholders || [];

    let endpointUrl: string = endpoint;

    placeholderArray.forEach((element: string, index: number) => {
        endpointUrl = endpointUrl.replace(`{${index}}`, element);
    });

    return endpointUrl;
};
