import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';
import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';

import { HttpStatus } from '~constants/http';

import { selectors as oidcSelectors } from '~modules/oidc';
import {
    FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_CALCULATED,
    setPayoutCycleRunsProcessStepsCalculated,
    setPayoutCycleRunsProcessStepsCalculatedError,
    setPayoutCycleRunsProcessStepsCalculatedForbidden
} from '~modules/payoutCycleRunsProcessStepsCalculated';

export function * fetchPayoutCycleRunsProcessStepsCalculated (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<PayoutCycleRunProcessSteps> = yield call(api.fetchPayoutCycleRunsCalculated, accessToken);

        yield put(setPayoutCycleRunsProcessStepsCalculated(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPayoutCycleRunsProcessStepsCalculatedForbidden());
        } else {
            yield put(setPayoutCycleRunsProcessStepsCalculatedError());
        }
    }
}

export default function * watchFetchPayoutCycleRunsProcessStepsCalculated (): Generator<ForkEffect> {
    yield takeLatest(FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_CALCULATED, fetchPayoutCycleRunsProcessStepsCalculated);
}
