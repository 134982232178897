// type declaration
import { useSelector } from 'react-redux';
import { Action } from 'redux';

import { PayoutCycleOverview } from '~api/model/payout-cycle';

import selectors from './selectors';

export const FETCH_PAYOUT_CYCLES = 'poms/payout-cycles/FETCH';
export const SET_PAYOUT_CYCLES = 'poms/payout-cycles/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'NOT_FOUND' | 'ERROR' | 'FORBIDDEN';

export type PayoutCycleState = {
    data: PayoutCycleOverview | null;
    fetchState: FetchState;
}

export type FetchPayoutCycles = Action<typeof FETCH_PAYOUT_CYCLES>;

export interface SetPayoutCycles extends Action<typeof SET_PAYOUT_CYCLES> {
    payload: PayoutCycleState;
}

export type PayoutCyclesAction = FetchPayoutCycles | SetPayoutCycles;

// implementation
const defaultState: PayoutCycleState = {
    data: null,
    fetchState: 'INIT'
};

export default function payoutCycles (state: PayoutCycleState = defaultState, action: PayoutCyclesAction): PayoutCycleState {
    switch (action.type) {
        case FETCH_PAYOUT_CYCLES:
            return {
                ...state,
                fetchState: 'FETCHING'
            };
        case SET_PAYOUT_CYCLES:
            return action.payload;
        default:
            return state;
    }
}

// actions
export const fetchPayoutCycles = (): FetchPayoutCycles => ({
    type: FETCH_PAYOUT_CYCLES,
});

export const setPayoutCycles = (payoutCycles: PayoutCycleOverview): SetPayoutCycles => ({
    type: SET_PAYOUT_CYCLES,
    payload: {
        data: payoutCycles,
        fetchState: 'SUCCESS',
    },
});

export const setPayoutCyclesForbidden = (): SetPayoutCycles => ({
    type: SET_PAYOUT_CYCLES,
    payload: {
        data: null,
        fetchState: 'FORBIDDEN',
    },
});

export const setPayoutCyclesError = (): SetPayoutCycles => ({
    type: SET_PAYOUT_CYCLES,
    payload: {
        data: null,
        fetchState: 'ERROR',
    },
});

export const usePayoutCycles = (): PayoutCycleState => useSelector(selectors.root);
export const usePayoutCyclesData = (): PayoutCycleOverview | null => useSelector(selectors.data);
