import { createAction, createReducer } from '@reduxjs/toolkit';

import { PayoutCycleRunCreator } from '~api/model/payout-cycle';

// type declarations
export const SET_PAYOUT_STATUS_CHANGE = 'poms/payoutStatusChangeConfirmDialog/SET_PAYOUT_STATUS_CHANGE';
export const RESET_PAYOUT_STATUS_CHANGE = 'poms/payoutStatusChangeConfirmDialog/RESET_PAYOUT_STATUS_CHANGE';
export const CONFIRM_PAYOUT_STATUS_CHANGE = 'poms/payoutStatusChangeConfirmDialog/CONFIRM_PAYOUT_STATUS_CHANGE';

export type PayoutStatusChange = 'stop' | 'onHold' | 'resume' | null;
export type PayoutStatusChangeConfirmDialogState = {
    payout: PayoutCycleRunCreator | null;
    payoutStatus: PayoutStatusChange;
};

// actions
export const setPayoutStatusChange = createAction(
    SET_PAYOUT_STATUS_CHANGE,
    (payout: PayoutCycleRunCreator, payoutStatus: PayoutStatusChange) => ({
        payload: { payout, payoutStatus },
    }));
export const resetPayoutStatusChange = createAction(RESET_PAYOUT_STATUS_CHANGE);
export const confirmPayoutStatusChange = createAction(CONFIRM_PAYOUT_STATUS_CHANGE);

// reducer
const defaultState: PayoutStatusChangeConfirmDialogState = {
    payout: null,
    payoutStatus: null,
};

export default createReducer(defaultState, (builder) => {
    builder
        .addCase(setPayoutStatusChange, (state, { payload }) => ({
            ...state,
            ...payload,
        }))
        .addCase(resetPayoutStatusChange, (state) => {
            state.payout = null;
            state.payoutStatus = null;
        });
});
