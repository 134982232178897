import * as Sentry from '@sentry/nextjs';
import { User } from 'oidc-client';
import { Action, USER_FOUND } from 'redux-oidc';
import { ForkEffect, select, StrictEffect, takeLatest } from 'redux-saga/effects';

export function * loginSaga (action: Action<User | Error>): Generator<StrictEffect, void, any> {
    const user = action.payload as User;

    // Register user for sentry
    Sentry.setUser({
        email: user?.profile?.unique_name
    });

    yield select();
}

export default function * watchLogin (): Generator<ForkEffect> {
    yield takeLatest(USER_FOUND, loginSaga);
}
