import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { PaidOutBeautyPointsDashboardDetailsState } from './paidOutBeautyPointsDashboardDetails';

const root = (state: AppState): PaidOutBeautyPointsDashboardDetailsState => state.paidOutBeautyPointsDashboardDetails;

export const usePaidOutBeautyPointsDashboardDetails = (): PaidOutBeautyPointsDashboardDetailsState => useSelector(root);

export default {
    root,
};
