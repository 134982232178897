import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/administration';
import { PayoutCycleRunProcessSteps } from '~api/model/payoutCycleRunProcessStep';

import { HttpStatus } from '~constants/http';

import { selectors as oidcSelectors } from '~modules/oidc';
import {
    FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_MARKED_FOR_CALCULATION,
    setPayoutCycleRunsProcessStepsMarkedForCalculation,
    setPayoutCycleRunsProcessStepsMarkedForCalculationError,
    setPayoutCycleRunsProcessStepsMarkedForCalculationForbidden
} from '~modules/payoutCycleRunsProcessStepsMarkedForCalculation';

export function * fetchPayoutCycleRunsProcessStepsMarkedForCalculation (): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);

    try {
        const { data }: AxiosResponse<PayoutCycleRunProcessSteps> = yield call(api.fetchPayoutCycleRunsMarkedForCalculation, accessToken);

        yield put(setPayoutCycleRunsProcessStepsMarkedForCalculation(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPayoutCycleRunsProcessStepsMarkedForCalculationForbidden());
        } else {
            yield put(setPayoutCycleRunsProcessStepsMarkedForCalculationError());
        }
    }
}

export default function * watchFetchPayoutCycleRunsProcessStepsMarkedForCalculation (): Generator<ForkEffect> {
    yield takeLatest(FETCH_PAYOUT_CYCLE_RUN_PROCESS_STEPS_MARKED_FOR_CALCULATION, fetchPayoutCycleRunsProcessStepsMarkedForCalculation);
}
