import { useSelector } from 'react-redux';

import { AppState } from '~modules/index';

import { PaidOutCashDashboardDetailsState } from './paidOutCashDashboardDetails';

const root = (state: AppState): PaidOutCashDashboardDetailsState => state.paidOutCashDashboardDetails;

export const usePaidOutCashDashboardDetails = (): PaidOutCashDashboardDetailsState => useSelector(root);

export default {
    root,
};
