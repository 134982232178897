import { format, subDays } from 'date-fns';

import { DashboardDetailTileDateFilterCustomRange, DashboardDetailTileDateFilterState } from '~modules/dashboardDetailTileDateFilter';

export const getRangeFromFilter = (rangeFilter: DashboardDetailTileDateFilterState): DashboardDetailTileDateFilterCustomRange => {
    if (rangeFilter.customRange) {
        return rangeFilter.customRange;
    }

    return {
        from: format(subDays(new Date(), parseInt(rangeFilter.preset || '0') || 0), 'yyyy-MM-dd'),
        to: format(new Date(), 'yyyy-MM-dd'),
    };
};
