import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import { CreatorFilterOptions } from '~api/model/creator';
import { PayoutCycleRunCreatorOverview } from '~api/model/payout-cycle';
import api from '~api/payout-cycle';

import { HttpStatus } from '~constants/http';

import { CreatorFilterAction, selectors as creatorFilterSelectors, SET_CREATORS_FILTER_OPTION } from '~modules/creatorFilter';
import { selectors as oidcSelectors } from '~modules/oidc';
import { FETCH_PAYOUT_CYCLE_RUN_CREATORS, FetchPayoutCycleRunCreators, setPayoutCycleRunCreators, setPayoutCycleRunCreatorsError, setPayoutCycleRunCreatorsForbidden } from '~modules/payoutCycleRunCreators';

export function * fetchCreatorsForPayoutCycleRun (action: CreatorFilterAction | FetchPayoutCycleRunCreators): Generator<StrictEffect, void, any> {
    const isFilterChangeAction = action.type === SET_CREATORS_FILTER_OPTION;

    if (isFilterChangeAction && action.meta.source !== 'payoutCycleRun') {
        return;
    }

    try {
        const accessToken = yield select(oidcSelectors.accessToken);
        const creatorFilter = yield select(creatorFilterSelectors.root);
        const filterToUse: CreatorFilterOptions = isFilterChangeAction ? { ...creatorFilter, ...action.payload } : creatorFilter;
        const payoutCycleId: string = isFilterChangeAction ? action.meta.id || '' : (action as FetchPayoutCycleRunCreators).payload;

        const { data }: AxiosResponse<PayoutCycleRunCreatorOverview> = yield call(api.fetchCreatorsOfPayoutCycleRun, payoutCycleId, filterToUse, accessToken);

        yield put(setPayoutCycleRunCreators(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setPayoutCycleRunCreatorsForbidden());
        } else {
            yield put(setPayoutCycleRunCreatorsError());
        }
    }
}

export default function * watchFetchCreatorsForPayoutCycleRun (): Generator<ForkEffect> {
    yield takeLatest(FETCH_PAYOUT_CYCLE_RUN_CREATORS, fetchCreatorsForPayoutCycleRun);
}
