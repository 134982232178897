import { createAction, createReducer } from '@reduxjs/toolkit';

import { CreditNote } from '~api/model/creditNote';

// type declaration
export const FETCH_CREDIT_NOTES_WITH_PROCESSING_ERROR = 'poms/creditNotesWithProcessingErrorTable/FETCH';
export const SET_CREDIT_NOTES_WITH_PROCESSING_ERROR = 'poms/creditNotesWithProcessingErrorTable/SET';

export type FetchState = 'INIT' | 'FETCHING' | 'SUCCESS' | 'ERROR' | 'FORBIDDEN';

export type CreditNotesWithProcessingErrorState = {
    creditNotesWithProcessingError: CreditNote[] | null;
    fetchState: FetchState;
};

// actions
export const fetchCreditNotesWithProcessingError = createAction(FETCH_CREDIT_NOTES_WITH_PROCESSING_ERROR);

export const setCreditNotesWithProcessingError = createAction(
    SET_CREDIT_NOTES_WITH_PROCESSING_ERROR,
    (creditNotesWithProcessingError: CreditNote[] | null, fetchState: FetchState = 'SUCCESS') => ({
        payload: {
            creditNotesWithProcessingError,
            fetchState,
        }
    }));

export type SetCreditNotesWithProcessingErrorAction = ReturnType<typeof setCreditNotesWithProcessingError>;

export const setCreditNotesWithProcessingErrorForbidden = (): SetCreditNotesWithProcessingErrorAction =>
    setCreditNotesWithProcessingError(null, 'FORBIDDEN');

export const setCreditNotesWithProcessingErrorError = (): SetCreditNotesWithProcessingErrorAction =>
    setCreditNotesWithProcessingError(null, 'ERROR');

export const resetCreditNotesWithProcessingError = (): SetCreditNotesWithProcessingErrorAction =>
    setCreditNotesWithProcessingError(null, 'INIT');

// reducer
const defaultState: CreditNotesWithProcessingErrorState = {
    creditNotesWithProcessingError: null,
    fetchState: 'INIT',
};

export default createReducer(defaultState, builder => {
    builder
        .addCase(fetchCreditNotesWithProcessingError, (state) => ({
            ...state,
            fetchState: 'FETCHING',
        }))
        .addCase(setCreditNotesWithProcessingError, (state, { payload }) => ({
            ...state,
            ...payload,
        }));
});
