import { AxiosResponse } from 'axios';
import { call, ForkEffect, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { CreatorPayoutCorrection } from '~api/model/payout-correction';
import api from '~api/payout-correction';

import { HttpStatus } from '~constants/http';

import {
    FETCH_CREATOR_PAYOUT_CORRECTIONS,
    FetchCreatorPayoutCorrections,
    setCreatorPayoutCorrections,
    setCreatorPayoutCorrectionsError,
    setCreatorPayoutCorrectionsForbidden,
} from '~modules/creatorPayoutCorrections';
import { selectors as oidcSelectors } from '~modules/oidc';

export function * fetchCreatorPayoutCorrections (action: FetchCreatorPayoutCorrections): Generator<StrictEffect, void, any> {
    const accessToken = yield select(oidcSelectors.accessToken);
    const creatorId = action.payload;

    try {
        const { data }: AxiosResponse<CreatorPayoutCorrection[]> = yield call(api.fetchCreatorPayoutCorrections, creatorId, accessToken);

        yield put(setCreatorPayoutCorrections(data));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setCreatorPayoutCorrectionsForbidden());
        } else {
            yield put(setCreatorPayoutCorrectionsError());
        }
    }
}

export default function * watchFetchCreatorPayoutCorrections (): Generator<ForkEffect> {
    yield takeEvery(FETCH_CREATOR_PAYOUT_CORRECTIONS, fetchCreatorPayoutCorrections);
}
