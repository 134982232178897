import { call, ForkEffect, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';

import api from '~api/payout-cycle';

import { HttpStatus } from '~constants/http';

import { message } from '~helper/_common';

import { resetAllActiveButtons } from '~modules/activeButton';
import { setMessageAtPosition } from '~modules/messages';
import { selectors as oidcSelectors } from '~modules/oidc';
import { fetchPayoutCycleRunCreators } from '~modules/payoutCycleRunCreators';
import { fetchPayoutCycleRunDetails } from '~modules/payoutCycleRunDetails';
import { PAYOUT_CYCLE_RUN_ON_HOLD_OR_RESUME, setPayoutCycleRunOnHoldOrResume, setPayoutCycleRunToBeOnHoldOrResume } from '~modules/setPayoutCycleRunOnHoldOrResumeDialog';

export function * changePayoutCycleRunStatus (action: ReturnType<typeof setPayoutCycleRunOnHoldOrResume>): Generator<StrictEffect, void, any> {
    try {
        const payoutCycleRun = action.payload.overview;
        const accessToken = yield select(oidcSelectors.accessToken);

        yield call(payoutCycleRun.blocked ? api.resumePayoutCycleRun : api.setPayoutCycleRunOnHold, payoutCycleRun.payoutCycleRunId as string, accessToken);

        yield put(setPayoutCycleRunToBeOnHoldOrResume(null));
        yield put(fetchPayoutCycleRunDetails(payoutCycleRun.payoutCycleRunId as string));
        yield put(fetchPayoutCycleRunCreators(payoutCycleRun.payoutCycleRunId as string));
    } catch (error: any) {
        if (error.response?.status === HttpStatus.Forbidden) {
            yield put(setMessageAtPosition(message('payoutCycleRun.statusChange.forbidden'), 'setPayoutCycleRunOnHoldOrResume', 'error'));
        } else if (error.response?.status === HttpStatus.Conflict) {
            yield put(setMessageAtPosition(message('payoutCycleRun.statusChange.conflict'), 'setPayoutCycleRunOnHoldOrResume', 'error'));
        } else {
            yield put(setMessageAtPosition(message('payoutCycleRun.statusChange.error'), 'setPayoutCycleRunOnHoldOrResume', 'error'));
        }
    } finally {
        yield put(resetAllActiveButtons());
    }
}

export default function * watchChangePayoutCycleRunStatus (): Generator<ForkEffect> {
    yield takeLatest(PAYOUT_CYCLE_RUN_ON_HOLD_OR_RESUME, changePayoutCycleRunStatus);
}
