import { addMinutes, format, isPast, isSameDay } from 'date-fns';
import getConfig from 'next/config';

import { Environment } from '~constants/environments';

import locales from '~public/locales/en.json';

export type MessageKey = keyof typeof locales;

type LocalesJSON = {
    [prop in MessageKey]: string;
};

export const message = (requestedKey: MessageKey, ...placeholders: string[]): string => {
    const placeholderArray: string[] = placeholders || [];

    if (locales === undefined) {
        console.error('No locales found at document');

        return requestedKey;
    }

    const typedLocales: LocalesJSON = locales;
    const expectedLocale: string = typedLocales[requestedKey];

    if (expectedLocale) {
        let localeText: string = expectedLocale;

        placeholderArray.forEach((element: string, index: number) => {
            localeText = localeText.replace(`{${index}}`, element);
        });

        return localeText;
    }

    if (process.env.NODE_ENV !== Environment.TEST) {
        console.error('Requested Locale Key not found: ', requestedKey);
    }

    return requestedKey;
};

export const isMessageKey = (requestedKey: string): requestedKey is MessageKey => {
    if (!locales) {
        return false;
    }

    const typedLocales: LocalesJSON = locales;

    return Boolean(typedLocales[requestedKey as MessageKey]);
};

export const getFormattedEnvironment = (): string => {
    const { publicRuntimeConfig } = getConfig();

    return message(`environment.${publicRuntimeConfig.environment.toLowerCase()}` as MessageKey);
};

export const formatNumber = (number: number, options?: Intl.NumberFormatOptions): string => {
    return new Intl.NumberFormat(window.navigator.language, options).format(number);
};

export const formatCurrency = (number: number): string => (number || number === 0) ? formatNumber(number, { style: 'currency', currency: 'EUR' }) : '-';

let dateFnLocale: Locale | undefined;

export const setDateFnLocale = (locale: Locale): void => {
    dateFnLocale = locale;
};

export const getDateFnLocale = (): Locale | undefined => {
    return dateFnLocale;
};

export const formatDate = (dateToFormat: string | null): string => {
    if (!dateToFormat) {
        return message('date.infinite');
    }

    const date = new Date(dateToFormat);

    return format(addMinutes(date, date.getTimezoneOffset()), 'P', { locale: dateFnLocale });
};

export const determineLocaleForDateFns = (navigatorLanguage: string): string => {
    const locale = navigatorLanguage.toLocaleLowerCase();
    const [ language, region ] = locale.split('-');

    if (region === undefined || language === region) {
        return language;
    }

    return navigatorLanguage;
};

export const isDateInThePastOrToday = (dateToCheck: string): boolean => {
    return isPast(new Date(dateToCheck)) || isSameDay(new Date(dateToCheck), new Date());
};

export const stringToColor = (str: string): string => {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;

        color += ('00' + value.toString(16)).substr(-2);
    }

    return color;
};
