export const Environment = ({
    DEV: 'development',
    TEST: 'test',
    PRODUCTION: 'production',
});

export const systemEnvironments = [
    'local',
    'dev',
    'stg',
    'prd',
    'test',
] as const;

export type SystemEnvironment = typeof systemEnvironments[number];
